import React, { useState } from "react";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Tabs from "../../common/Tabs";
import Title from "../../common/Typography/title";
import { getUsers } from "../../../store/user";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";
import styles from "./index.module.css";

const DentalCareRequestDetails = ({ details }) => {
  const users = useSelector(getUsers);
  const user = users?.data ? users.data.user_data : {};

  const dentalDetails = users?.serviceDetails ? users.serviceDetails : {};

  const [showMore, setShowMore] = useState(false);
  const services = showMore
    ? dentalDetails.sub_services
    : dentalDetails.sub_services
    ? dentalDetails.sub_services.slice(0, 6)
    : [];

  if (!details) return null;

  return (
    <Paper className="mb-5">
      <Tabs
        activeTab={"Request Details"}
        tabData={["Request Details", "", "", "", ""]}
        tabIndex={0}
        onTabChange={(index) => console.log(index)}
      />

      <div className={styles["tab-panel-body"]}>
        <TabPanel value={0} index={0}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>User Name</th>
                <th>Email Address</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details?.id}</td>
                <td>{`${user?.first_name}  ${user?.last_name}`}</td>
                <td style={{ textTransform: "none" }}>{user?.email}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Phone Number</th>
                <th>Comment</th>
                <th>Appointment Status</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{user?.phone_number}</td>
                <td>
                  {dentalDetails?.comment ? details?.comment : "No Comment"}
                </td>
                <td>{details?.status}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Doctor's Name</th>
                <th>Hospital Address</th>
                <th>Appointment Date</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {dentalDetails?.appointment?.doctor_name
                    ? dentalDetails?.appointment?.doctor_name
                    : "Not Provided"}
                </td>
                <td>{dentalDetails?.appointment?.hospital_address}</td>
                <td>
                  {getFormatedDate(
                    dentalDetails?.appointment?.appointment_date
                  )}
                </td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Other Sevices</th>
                <th>Hospital Location</th>
              </tr>
            </thead>

            <tbody>
              <tr className={styles["table-row"]}>
                <td className={styles["benefits-container"]}>
                  {services.map((service) => (
                    <Title key={service}>{service}</Title>
                  ))}
                  {!showMore && services && (
                    <button
                      className={styles["show-more-btn"]}
                      onClick={() => setShowMore(true)}
                    >
                      {dentalDetails?.sub_services?.length - 7 > 0 &&
                        `+${dentalDetails?.sub_services?.length - 7} More`}
                    </button>
                  )}
                </td>
                <td>{dentalDetails?.appointment?.hospital_location}</td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
      </div>
    </Paper>
  );
};

export default DentalCareRequestDetails;
