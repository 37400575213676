import React from "react";
import { Link } from "react-router-dom";
import { homePageStyle } from "../../styles/homeStyle";
import SideBar from "../../sidebar-navbar";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";

const Card = ({
  cardLogo,
  cardTitle,
  cardBody,
  cardButtonOrLink,
  cardBorder,
}) => {
  const classes = homePageStyle();
  return (
    <div>
      <div className={classes.logo}>
        <img src={cardLogo} alt="logo" />
      </div>

      <div className={clsx(classes.cardBody, cardBorder && classes.cardBorder)}>
        {" "}
        <h3 className={classes.cardTitle}>{cardTitle}</h3>
        <p className={classes.cardBodyText}>{cardBody}</p>
        <button className={classes.linkBtn}>
          {" "}
          <Link to={cardButtonOrLink} className={classes.linkStyle}>
            Proceed
            <ChevronRightIcon color="disabled" fontSize="small" />
          </Link>
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  const classes = homePageStyle();
  return (
    <div className={classes.root}>
      <SideBar />
      <p>hello</p>
      <div className={classes.homeContainer}>
        <p className={classes.homeHeader}>
          Select a category to proceed to your account
        </p>
        <div className={classes.mainContainer}>
          <div className={classes.innerContainer}>
            <Card
              cardLogo="/assets/images/hmo.png"
              cardTitle="I want to explore available  HMO plans options"
              cardBody="View your HMO status and benefits, make requests and  lots more."
              cardButtonOrLink="/hmo/dashboard"
            />
            <Card
              cardLogo="/assets/images/pharma.png"
              cardTitle="Wellness on the go"
              cardBody="Check your vitals to ensure you are as healthy as you could be."
              cardBorder
              cardButtonOrLink="/wellness-on-the-go"
            />
            <Card
              cardLogo="/assets/images/doctor.png"
              cardTitle="I would like to see doctor’s appointment"
              cardBody="View access to all appointments that have been booked by users"
              cardButtonOrLink="/"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
