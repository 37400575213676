import React from "react";
import Avatar from "../Avatar";
import Paper from "../Paper";
import SubTitle from "../Typography/subTitle";
import Title from "../Typography/title";
import styles from "../index.module.css";
import { getEnrollees } from "../../../store/enrollee";
import { useSelector } from "react-redux";
import {
  getFormatedDate,
  getIntialsFromString,
} from "../../../utils/constants";

const Panel = React.memo(({ user }) => {
  const enrollees = useSelector(getEnrollees);
  const enrollee = user
    ? user
    : enrollees.data.enrollee_details
    ? enrollees?.data?.enrollee_details
    : {};
  return (
    <Paper className={styles["paper-container"]}>
      <div className={styles.row}>
        <Avatar>
          {enrollee.image_color ? (
            <div
              style={{
                backgroundColor: enrollee.image_color,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                color: "white",
                fontWeight: "bold",
              }}>
              {getIntialsFromString(
                enrollee.name || `${enrollee.first_name} ${enrollee.last_name}`
              )}
            </div>
          ) : (
            <img
              src={enrollee.passport_url}
              alt={enrollee.name || enrollee.first_name}
            />
          )}
        </Avatar>
        <div>
          <h2>
            {enrollee.name || `${enrollee.first_name} ${enrollee.last_name}`}
          </h2>
          <p>{enrollee.email}</p>
        </div>
      </div>
      <ul className={styles.rows}>
        <li className={styles.row}>
          <div>
            <SubTitle>Plan Type</SubTitle>
            <Title>{enrollee.plan_name || "Nill"}</Title>
          </div>
        </li>
        <li className={styles.row}>
          <div>
            <SubTitle>Date Created</SubTitle>
            <Title>{getFormatedDate(enrollee.date_created, "L")}</Title>
          </div>
        </li>
        <li className={styles.row}>
          <div>
            <SubTitle>Status</SubTitle>
            <Title>{enrollee.status || "pending"}</Title>
          </div>
        </li>
      </ul>
    </Paper>
  );
});

export default Panel;
