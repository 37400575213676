import React, { useState } from "react";
import { containerStyle } from "../../styles/styles";
import { TableStyles } from "../../styles/table";

import LocalBarIcon from "@material-ui/icons/LocalBar";
import ActiveBenefits from "../../uis/settings/benefit";
import { useHistory } from "react-router-dom";
import BenefitModal from "../../uis/modals/addBenefits/addBenefit";

const Benefits = () => {
  const classes = containerStyle();
  const tabClasses = TableStyles();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <BenefitModal
        title="Add Benefit"
        open={showModal}
        close={handleClose}
        btnText="Add Benefit"
      />
      <div
        style={{
          background: "#FFFFFF",
          padding: "1rem 1.5rem",
          display: "flex",
          alignItems: "center",
          borderBottom: "2px solid #E5E5E5",
          cursor: "pointer",
        }}
      >
        <img
          src="/assets/ion_arrow-back-circle.png"
          alt=""
          style={{ marginRight: "0.5rem" }}
          onClick={() => history.goBack()}
        />
        <p style={{ fontWeight: "700" }}>Back</p>
      </div>
      <div className={tabClasses.filterContainer}>
        <p>
          <LocalBarIcon /> Filter by:
        </p>
        <button
          className={tabClasses.addBtn}
          onClick={() => setShowModal(true)}
        >
          Add Benefit
        </button>
      </div>
      <div className={classes.main}>
        <div className={tabClasses.tableRoot}>
          <div className={tabClasses.tabPanelContainer}>
            <ActiveBenefits />
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
