import { makeStyles } from "@material-ui/core/styles";

export const homePageStyle = makeStyles(() => ({
    homeContainer: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    homeHeader: {
      color: "#333333",
      fontWeight: 700,
      textAlign: "center",
      fontSize: "1.35rem",
    },
    mainContainer: {
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 0px 12px #F2F4F9",
      borderRadius: "10px",
      padding: "2.5rem 0rem",
      marginTop: "1rem",
      width: "90%",
    },
    innerContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, auto)",
      padding: "2.5rem 2rem",
    },
    logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "1rem 0rem",
    },
    cardBody: {
      padding: "0rem 2.5rem",
    },
    cardBorder: {
      borderRight: "1px solid #E0E0E0",
      borderLeft: "1px solid #E0E0E0",
    },
    cardTitle: {
      height: "3rem",
      fontWeight: "bold",
      color: " #0F0B26",
    },
    cardBodyText: {
      height: "5rem",
      position: "relative",
    },
    linkBtn: {
      border: "1px solid #D3D3D3",
      borderRadius: "5px",
      padding: "0.25rem 0.5rem",
      width: "7rem",
    },
    linkStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));