import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  topText: {
    color: "#525252",
    textAlign: "center",
  },
  formSection: {
    flex: "none",
    order: 0,
    flexGrow: 0,
    // marginLeft: 30,
    marginTop: "1rem",
  },
  enroleeIdText: {
    color: "#5A5454",
  },
  enroleeInputId: {
    outline: "none",
    backgroundColor: "transparent",
    color: "#5A5454",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    padding: "0.5rem",
    marginTop: "0.2rem",
    width: "100%",
  },
  actionButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
  },
  proceedBtn: {
    color: "#FCFCFC",
    backgroundColor: "#E6072C",
    borderRadius: "5px",
    padding: "0.8rem 2rem",
    marginRight: 10,
  },
  proceedDeclineBtn: {
    color: "#FCFCFC",
    backgroundColor: "#E6072C",
    borderRadius: "5px",
    padding: "0.8rem 2rem",
    width: "100%",
    // marginRight: 10,
  },
  cancelBtn: {
    color: "#E6072C",
    backgroundColor: "#F5E9EB",
    borderRadius: "5px",
    padding: "0.8rem 2rem",
  },
}));

const ActionModalContent = ({
  approveTextTop,
  declineTextTop,
  enroleeIdLabel,
  formSection,
  onApproveSection,
  onApprove,
  onDecline,
  inputName,
  topLabel,
  formplaceholder,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  return (
    <div>
      <div>
        {" "}
        <p className={classes.topText}>{approveTextTop && approveTextTop}</p>
        <p className={classes.topText}>{declineTextTop && declineTextTop}</p>
      </div>

      {/* enter id section */}
      {formSection && (
        <div>
          {enroleeIdLabel && (
            <label className={classes.enroleeIdText}>{topLabel}</label>
          )}
          <form>
            <input
              type="text"
              name={inputName}
              placeholder={formplaceholder}
              value={value.toUpperCase()}
              onChange={({ target }) => setValue(target.value.toUpperCase())}
              className={classes.enroleeInputId}
              autoFocus
            />
          </form>
        </div>
      )}

      {onApproveSection && (
        <div className={classes.actionButton}>
          <button
            className={classes.proceedBtn}
            onClick={() => onApprove(value)}
          >
            Yes, Proceed
          </button>
          <button className={classes.cancelBtn} onClick={onDecline}>
            No, Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default ActionModalContent;
