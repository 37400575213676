import React, { useEffect } from "react";
import { containerStyle } from "../../styles/styles";
import { graphCardStyles } from "../../styles/graphcardstyle";
import GraphCard from "../../uis/graphscard";
import OverviewCard from "../../uis/graphscard/overviewCard";
import AppMenu from "../../menu";
import HighestPayingCategory from "../../graphs/highestPayingCategoryChart";
import TopRequests from "../../graphs/toprequest";
import NewRequestTable from "../../uis/enroleesTable/newRequestTable";
import DashboardLayout from "../../uis/dashboardLayout";
// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  fetchWellnessOnTheGoDashboardData,
  getDashboardData,
} from "../../../store/dashboard";
import { useDispatch, useSelector } from "react-redux";

const TopLeftDashboard = ({
  totalUsers,
  totalPendingAppointment,
  totalAppointment,
}) => {
  return (
    <>
      <OverviewCard title="Total Users" number={totalUsers} text="YTD" />
      <OverviewCard
        title="Total Pending Appointment"
        number={totalPendingAppointment}
        text="YTD"
      />
      <OverviewCard
        title="Total Appointments"
        number={totalAppointment}
        text="YTD"
      />
    </>
  );
};

const TopRightDashboard = ({ data }) => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Highest paying categories "
      graph={<HighestPayingCategory dataFigs={data} />}
      menuBtnStyle={classes.menuBtn1}
    />
  );
};

const MiddleLeftDashboard = () => {
  return <GraphCard title="Requests" graph={<NewRequestTable />} />;
};

const MiddleRightDashboard = () => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Top Requests"
      menu={[
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              This week <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "This Month": () => console.log(""),
            },
            {
              "This Year": () => console.log(""),
            },
            {
              "Custom Date": () => console.log(""),
            },
          ]}
        />,
      ]}
      graph={<TopRequests />}
      menuBtnStyle={classes.menuBtn2}
    />
  );
};

const WellnessOnTheGoDashBoard = () => {
  const classes = containerStyle();
  const dispatch = useDispatch();
  const dashboarddata = useSelector(getDashboardData);
  const dashboard = dashboarddata.dashboardData;

  useEffect(() => {
    const fetchDashboard = async () => {
      await dispatch(fetchWellnessOnTheGoDashboardData());
    };
    fetchDashboard();
  }, [dispatch]);

  return (
    <div className={classes.main}>
      <DashboardLayout
        topLeft={
          <TopLeftDashboard
            totalPendingAppointment={dashboard?.total_pending_appointments}
            totalUsers={dashboard?.total_users}
            totalAppointment={dashboard?.total_appointments}
          />
        }
        topRight={
          <TopRightDashboard
            data={dashboard?.highest_paid_appointments?.map(
              (d) => d?.total_amount_paid
            )}
          />
        }
        middleLeft={<MiddleLeftDashboard />}
        middleRight={<MiddleRightDashboard />}
      />
    </div>
  );
};

export default WellnessOnTheGoDashBoard;
