import { makeStyles } from "@material-ui/core/styles";

export const graphCardStyles = makeStyles(() => ({
  graphCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: "1.5rem",
  },
  graphCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1.5rem",
  },
  graphTitle: {
    color: "#525252",
    fontWeight: "800",
    fontSize: "1.125rem",
  },
  menuBtn1: {
    background: "#E6072C",
    borderRadius: "5px",
    color: "#fff",
    padding: "0.3rem 1rem",
  },
  menuBtn2: {
    background: "rgba(236, 236, 236, 0.47)",
    borderRadius: "5px",
    color: "#525252",
    padding: "0.3rem 1rem",
    marginLeft: 10,
  },

  graphContainer: {
    position: "relative",
    margin: "auto",
    height: "80%",
    width: "100%",
  },
}));

export const overviewCardStyles = makeStyles(() => ({
  root: {
    background: "#fff",
    padding: "0.8rem 1.5rem",
    boxShadow: "0px 0px 12px #F2F4F9",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  overviewTitle: {
    marginBottom: 5,
  },
  overviewTotal: {
    fontSize: "1.625rem",
  },
}));
