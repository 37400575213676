import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import { makeStyles, createStyles } from "@material-ui/core";
import { fetchEnrollees, getEnrollees } from "../../../store/enrollee";
import { useDispatch, useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";
import Loader from "../../common/Loader";

const useStyles = makeStyles(() =>
  createStyles({
    activeStatus: {
      backgroundColor: "green",
      padding: 4,
      borderRadius: "50%",
      marginRight: 10,
    },
    inactiveStatus: {
      backgroundColor: "red",
      padding: 4,
      borderRadius: "50%",
      marginRight: 10,
    },
  })
);

export default function ActiveEnrolees() {
  const history = useHistory();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const enrolleesData = useSelector(getEnrollees);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const { activetab } = useParams();

  useEffect(() => {
    const fetchActiveEnrollees = async () => {
      await dispatch(fetchEnrollees(pageLists, pageNumber));
    };
    fetchActiveEnrollees();
  }, [dispatch, pageNumber, pageLists]);

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchEnrollees(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchEnrollees(pageLists, pageNumber));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = enrolleesData.activeEnrolleesList.filter(
    (searched) => {
      if (search === " ") {
        return searched;
      } else if (
        searched.first_name
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.last_name
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.phone_number.includes(search && search.toLowerCase())
      ) {
        return searched;
      } else {
        return null;
      }
    }
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = enrolleesData.activeEnrolleesList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}
      >
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={enrolleesData.loading} />
      <TableItem
        userType={"Enrollee"}
        checkBoxes
        tabHeadLabel={[
          "Enrollee",
          "Email Address",
          "Phone Number",
          "Date Created",
          "Status",
        ]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={enrolleesData.activeEnrolleesList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={enrolleesData.currentPage}
        toPage={enrolleesData.toPage}
        totalPage={enrolleesData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= enrolleesData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              image
              imageUrl={item?.enrolee_photo}
              imgInitials={
                item?.first_name.slice(0, 1) + item?.last_name.slice(0, 1)
              }
              enroleeName={item.first_name + " " + item.last_name}
              enroleeId={item.enrollee_id}
              pat_status
              pat_status_icon={
                item.status === "active"
                  ? classes.activeStatus
                  : classes.inactiveStatus
              } 
              listItem={[
                item.email,
                item.phone_number,
                getFormatedDate(item.created_at, "YYYY/MMM/DD"),
                item.status,
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "View Enrolee": () =>
                        history.push(
                          `/hmo/dashboard/enrollees/view/enrollee-details/${item.id}`
                        ),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
