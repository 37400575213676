import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import styles from "./index.module.css";
import Box from "@material-ui/core/Box";
import { getFormatedDate } from "../../../utils/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "482px",
  height: "790px",
  bgcolor: "background.paper",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1);",
  p: 4,
};

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ViewAgentDetailsModal({
  open,
  close,
  appointment_date,
  doctor_name,
  patient_name,
  start_time,
  end_time,
  appointment_duration,
  status,
  reason_for_rescheduling,
  new_date,
  new_time,
  patient_email,
  doctor_email,
}) {
  if (!open) return null;

  return (
    <Modal
      aria-valueledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={close}>
      <Fade in={open}>
        <Box sx={style}>
          <div className={styles["details-title"]}>
            <h1>Appointment Date</h1>
            <div className={styles["underline"]} />
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Doctor Name
            </h2>
            <p className={styles["value"]}>{doctor_name}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Patient Name
            </h2>
            <p className={styles["value"]}>{patient_name}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Patient Email
            </h2>
            <p className={styles["value"]}>{patient_email}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Doctor Email
            </h2>
            <p className={styles["value"]}>{doctor_email}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Start Time
            </h2>
            <p className={styles["value"]}>{start_time}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              End Time
            </h2>
            <p className={styles["value"]}>{end_time}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Apointment Duration
            </h2>
            <p className={styles["value"]}>
              {getFormatedDate(appointment_date, "YYYY/MMM/DD")}
            </p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Appointment Status
            </h2>
            <p className={styles["value"]}>{status}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              Reason for Rescheduling
            </h2>
            <p className={styles["value"]}>{reason_for_rescheduling}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              New Date
            </h2>
            <p className={styles["value"]}>{new_date}</p>
          </div>
          <div className={styles["details-control"]}>
            <h2 className={styles["key"]} htmlFor="text">
              New Time
            </h2>
            <p className={styles["value"]}>{new_time}</p>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
