import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// import PropTypes from "prop-types";
import { getAdmin } from "../../store/auth";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const userData = useSelector(getAdmin);

  const isAuthRoute = (props) => {
    const token = localStorage.getItem("token");
    if (token && userData?.role_id === 2) {
      return <Component {...props} />;
    }
    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={(props) => isAuthRoute(props)} />;
};

// AuthenticatedRoute.propTypes = {
//   component: PropTypes.node.isRequired,
// };

export default AuthenticatedRoute;
