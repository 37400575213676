import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { containerStyle } from "../../styles/styles";
import { TableStyles } from "../../styles/table";
import VerticalTabs from "../../tabs";
import TabPanel from "../../common/TabPanel";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import ApprovedEnroleesAppointment from "../../uis/appointmentTables/approvedTable";
import PendingEnroleesAppointment from "../../uis/appointmentTables/pendingTable";
import DeclinedEnroleesAppointment from "../../uis/appointmentTables/declinedEnroleesTable";

const tabLabels = ["Approved", "Pending", "Declined"];

const Appointment = () => {
  const classes = containerStyle();
  const tabClasses = TableStyles();
  const history = useHistory();
  const { activetab } = useParams();

  const tabNameToIndex = {
    0: "approved",
    1: "pending",
    2: "declined",
  };

  const indexToTabName = {
    approved: 0,
    pending: 1,
    declined: 2,
  };

  const [tabIndex, setTabIndex] = useState(indexToTabName[activetab]);
  const onChangeTabIndex = (e, newValue) => {
    history.push(`/hmo/dashboard/appointment/${tabNameToIndex[newValue]}`);
    setTabIndex(newValue);
  };
  return (
    <>
      <div className={tabClasses.filterContainer}>
        <p>
          <LocalBarIcon /> Filter by:
        </p>
      </div>
      <div className={classes.main}>
        <div className={tabClasses.tableRoot}>
          <div className={tabClasses.tabsSection}>
            <VerticalTabs tabData={tabLabels} tabIndex={tabIndex} onTabChange={onChangeTabIndex} />
          </div>
          <div className={tabClasses.tabPanelContainer}>
            <TabPanel value={0} index={tabIndex}>
              <ApprovedEnroleesAppointment />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <PendingEnroleesAppointment />
            </TabPanel>
            <TabPanel value={2} index={tabIndex}>
              <DeclinedEnroleesAppointment />
            </TabPanel> 
          </div>
        </div>
      </div>
    </>
  );
};

export default Appointment;
