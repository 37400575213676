import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { containerStyle } from "../../styles/styles";
import { TableStyles } from "../../styles/table";
import VerticalTabs from "../../tabs";
import TabPanel from "../../common/TabPanel";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import ApprovedEnroleesCardRequest from "../../uis/cardRequestTables/approvedTable";
import DeclinedEnroleesCardRequest from "../../uis/cardRequestTables/declinedEnroleesTable";
import PendingEnroleesCardRequest from "../../uis/cardRequestTables/pendingTable";

const tabLabels = ["Approved", "Pending", "Declined"];

const CardRequests = () => {
  const classes = containerStyle();
  const tabClasses = TableStyles();
  const history = useHistory();
  const { activetab } = useParams();

  const tabNameToIndex = {
    0: "approved",
    1: "pending",
    2: "declined",
  };

  const indexToTabName = {
    approved: 0,
    pending: 1,
    declined: 2,
  };

  const [tabIndex, setTabIndex] = useState(indexToTabName[activetab]);
  const onChangeTabIndex = (e, newValue) => {
    history.push(`/hmo/dashboard/card-request/${tabNameToIndex[newValue]}`);
    setTabIndex(newValue);
  };
  return (
    <>
      <div className={tabClasses.filterContainer}>
        <p>
          <LocalBarIcon /> Filter by:
        </p>
      </div>
      <div className={classes.main}>
        <div className={tabClasses.tableRoot}>
          <div className={tabClasses.tabsSection}>
            <VerticalTabs
              tabData={tabLabels}
              tabIndex={tabIndex}
              onTabChange={onChangeTabIndex}
            />
          </div>
          <div className={tabClasses.tabPanelContainer}>
            <TabPanel value={0} index={tabIndex}>
              <ApprovedEnroleesCardRequest />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <PendingEnroleesCardRequest />
            </TabPanel>
            <TabPanel value={2} index={tabIndex}>
              <DeclinedEnroleesCardRequest />
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardRequests;
