import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import HorizontalTabs from "../../tabs/horizontalTabs";
import ActionModal from "../modals";
import ActionModalContent from "../modals/modalContent";
import {
  fetchEnrolleesPendingDrugFill,
  getEnrolleesDrugFill,
} from "../../../store/drugRefill";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { getFormatedDate } from "../../../utils/constants";
import { updateRequestStatusById } from "../../../store/enrollee";
import notification from "../../common/Alert";

const tabLabels = ["Principal Directory", "Dependent Directory"];

export default function PendingEnroleesDrugRefill() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const enrolleesData = useSelector(getEnrolleesDrugFill);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const { activetab } = useParams();

  const tabNameToIndex = {
    0: "principal-directory",
    1: "dependent-directory",
  };

  const indexToTabName = {
    "principal-directory": 0,
    "dependent-directory": 1,
  };

  const [tabIndex, setTabIndex] = useState(indexToTabName[activetab]);
  const onChangeTabIndex = (e, newValue) => {
    history.push(`/hmo/dashboard/drug-refill/${tabNameToIndex[newValue]}`);
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchEnrollees = async () => {
      dispatch(fetchEnrolleesPendingDrugFill(pageLists, pageNumber));
    };
    fetchEnrollees();
  }, [dispatch, pageNumber, pageLists]);

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchEnrolleesPendingDrugFill(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchEnrolleesPendingDrugFill(pageLists, pageNumber));
  };

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const accept_request = async (id) => {
    setModal(false);
    const acceptRequest = await dispatch(
      updateRequestStatusById(id, "approved", "drug-refills")
    );
    notification.notication("success", acceptRequest.payload.message, "", {
      position: "top-right",
    });
    await dispatch(fetchEnrolleesPendingDrugFill(10, 1));
  };

  const decline_request = async (id) => {
    setModal(false);
    const declineRequest = await dispatch(
      updateRequestStatusById(id, "declined", "drug-refills")
    );
    notification.notication("success", declineRequest.payload.message, "", {
      position: "top-right",
    });
    await dispatch(fetchEnrolleesPendingDrugFill(10, 1));
  };

  const handleOpenConfirmModal = (id, enrolleeName) => {
    setModal(true);
    setContent(
      <ActionModalContent
        approveTextTop={
          <p>
            Would you like to approve <b>{enrolleeName}</b> drug refill request?
          </p>
        }
        onApprove={() => accept_request(id)}
        onApproveSection
        onDecline={() => setModal(false)}
      />
    );
    setModalTitle("Confirm Drug Refill");
  };

  const handleOpenDeclineModal = (id, enrolleeName) => {
    setModal(true);
    setContent(
      <ActionModalContent
        declineTextTop={
          <p>
            You are about to decline <b>{enrolleeName}</b> drug refill request.
            Would you like to proceed?
          </p>
        }
        onApproveSection
        onDecline={() => setModal(false)}
        onApprove={() => decline_request(id)}
      />
    );
    setModalTitle("Decline Drug Refill");
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = enrolleesData.pendingDrugFill.filter(
    (searched) => {
      if (search === " ") {
        return searched;
      } else if (
        searched.first_name
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.last_name
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.phone_number.includes(search && search.toLowerCase())
      ) {
        return searched;
      } else {
        return null;
      }
    }
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = enrolleesData.pendingDrugFill.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}
      >
        <SearchOnly handleSearch={handleChange} searchWord={search} />
        <HorizontalTabs
          tabData={tabLabels}
          tabIndex={tabIndex}
          onTabChange={onChangeTabIndex}
        />
      </div>
      <Loader visible={enrolleesData.loading} />
      <TableItem
        userType="Enrollee"
        modal={
          <ActionModal
            content={content}
            modalTitle={modalTitle}
            open={modal}
            close={() => setModal(false)}
          />
        }
        checkBoxes
        tabHeadLabel={[
          "Enrollee",
          "Phone Number",
          "Medication Name",
          "Request Reason",
          "Date Requested",
        ]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={enrolleesData.pendingDrugFill.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={enrolleesData.currentPage}
        toPage={enrolleesData.toPage}
        totalPage={enrolleesData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= enrolleesData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              image
              imageUrl={item.enrolee_photo}
              imgInitials={
                item?.first_name.slice(0, 1) + item?.last_name.slice(0, 1)
              }
              enroleeName={item.first_name + " " + item.last_name}
              enroleeId={item.enrollee_id}
              listItem={[
                item.phone_number,
                item.drug_name,
                item.reason.slice(0, 10) + "...",
                getFormatedDate(item.created_at, "YYYY/MMM/DD"),
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "View More": () =>
                        history.push(
                          `/hmo/dashboard/enrollee/drug-refill/accept/${
                            item.enrollee_primary_key || item.id
                          }`
                        ),
                    },
                    {
                      "Confirm Request": () =>
                        handleOpenConfirmModal(
                          item.id,
                          item.first_name + " " + item.last_name
                        ),
                    },
                    {
                      "Decline Request": () =>
                        handleOpenDeclineModal(
                          item.id,
                          item.first_name + " " + item.last_name
                        ),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
