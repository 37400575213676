import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "service",
  initialState: {
    doctorList: [],
    patientList: [],
    telemedicineList: [],
    transactionList: [],
    loading: false,
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    data: {},
  },
  reducers: {
    fetchedDoctorSuccess: (doctor, action) => {
      doctor.loading = false;
      doctor.doctorList = action.payload?.user;
      doctor.total = action.payload.user?.total;
      doctor.currentPage = action.payload.user?.from;
      doctor.toPage = action.payload.user?.to;
      doctor.lastPage = action.payload.user?.last_page;
    },
    fetchedPatientSuccess: (patient, action) => {
      patient.loading = false;
      patient.patientList = action.payload.patients;
      patient.total = action.payload.patients?.total;
      patient.currentPage = action.payload.patients?.from;
      patient.toPage = action.payload.patients?.to;
      patient.lastPage = action.payload.patients?.last_page;
    },
    fetchedTelemedicineSuccess: (telemedicine, action) => {
      telemedicine.loading = false;
      telemedicine.telemedicineList = action.payload.Telemedicine;
      telemedicine.total = action.payload?.Telemedicine?.total;
      telemedicine.currentPage = action.payload?.Telemedicine?.from;
      telemedicine.toPage = action.payload?.Telemedicine?.to;
      telemedicine.lastPage = action.payload?.Telemedicine?.last_page;
    },
    fetchedTransactionSuccess: (transaction, action) => {
      transaction.loading = false;
      transaction.transactionList = action.payload?.Telemedicine;
      transaction.total = action.payload?.total;
      transaction.currentPage = action.payload?.from;
      transaction.toPage = action.payload?.to;
      transaction.lastPage = action.payload?.last_page;
    },
    fetchedTelemedicineStart: (telemedicine) => {
      telemedicine.loading = true;
    },
    fetchedTelemedicineFailed: (telemedicine) => {
      telemedicine.loading = false;
    },
    statusChangeTelemedicineSuccess: (telemedicine) => {
      telemedicine.loading = false;
    },
  },
});

const {
  fetchedDoctorSuccess,
  fetchedPatientSuccess,
  fetchedTelemedicineSuccess,
  fetchedTransactionSuccess,
  fetchedTelemedicineStart,
  fetchedTelemedicineFailed,
  statusChangeTelemedicineSuccess,
} = slice.actions;

export default slice.reducer;

const url = "/api";
let telemedicine = true;

export const fetchDoctor = () =>
  apiCallBegan({
    url: `${url}/doctor`,
    method: "GET",
    telemedicine,
    onSuccess: fetchedDoctorSuccess.type,
    onStart: fetchedTelemedicineStart.type,
    onError: fetchedTelemedicineFailed.type,
  });

export const fetchPatient = () =>
  apiCallBegan({
    url: `${url}/allpatient`,
    method: "GET",
    telemedicine,
    onSuccess: fetchedPatientSuccess.type,
    onStart: fetchedTelemedicineStart.type,
    onError: fetchedTelemedicineFailed.type,
  });

export const fetchTelemedicine = () =>
  apiCallBegan({
    url: `${url}/telemedicine`,
    method: "GET",
    telemedicine,
    onSuccess: fetchedTelemedicineSuccess.type,
    onStart: fetchedTelemedicineStart.type,
    onError: fetchedTelemedicineFailed.type,
  });

export const fetchTransaction = () =>
  apiCallBegan({
    url: `${url}/get_transaction`,
    method: "GET",
    telemedicine,
    onSuccess: fetchedTransactionSuccess.type,
    onStart: fetchedTelemedicineStart.type,
    onError: fetchedTelemedicineFailed.type,
  });

export const updateRequestStatusApprove = (id) =>
  apiCallBegan({
    url: `${url}/approvedoctor/${id}`,
    method: "POST",
    telemedicine,
    onSuccess: statusChangeTelemedicineSuccess.type,
    onStart: fetchedTelemedicineStart.type,
    onError: fetchedTelemedicineFailed.type,
  });

export const updateRequestStatusDecline = (id) =>
  apiCallBegan({
    url: `${url}/approvedoctor/${id}`,
    method: "POST",
    telemedicine,
    onSuccess: statusChangeTelemedicineSuccess.type,
    onStart: fetchedTelemedicineStart.type,
    onError: fetchedTelemedicineFailed.type,
  });
export const getTelemedicine = (state) => state.entities.telemedicine;
