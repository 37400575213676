import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "comprehensive",
  initialState: {
    activeHealthCareRequestList: [],
    pendingHealthCareList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedUserHealthCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.activeHealthCareRequestList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedPendingUserHealthCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.pendingHealthCareList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedUserHealthCareRequestStart: (user, action) => {
      user.loading = true;
    },
    fetchedUserHealthCareRequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

const {
  fetchedUserHealthCareRequestSuccess,
  fetchedPendingUserHealthCareRequestSuccess,
  fetchedUserHealthCareRequestStart,
  fetchedUserHealthCareRequestFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v2/admin/appointments/comprehensive";

export const fetchHealthCareUsersRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedUserHealthCareRequestSuccess.type,
    onStart: fetchedUserHealthCareRequestStart.type,
    onError: fetchedUserHealthCareRequestFailed.type,
  });

export const fetchHealthCareUserPendingRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingUserHealthCareRequestSuccess.type,
    onStart: fetchedUserHealthCareRequestStart.type,
    onError: fetchedUserHealthCareRequestFailed.type,
  });

export const getHealthCareUsers = (state) => state.entities.healthCareUsers;

export const getPendingHealthCareUser = (state) =>
  state.entities.healthCareUsers.pendingHealthCareList;

export const getPendingHealthUserCareById = (state) => (id) =>
  state.entities.healthCareUsers.pendingHealthCareList.find((e) => e.id === id);
