import React, { useState } from "react";
import { resetPasswordPageStyle } from "./resetStyle";
import SideBar from "../../sidebar-navbar";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword, getAuth } from "../../../store/auth";
import Loader from "../../common/Loader";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import notification from "../../common/Alert";

const NewPassword = () => {
  const classes = resetPasswordPageStyle();
  const dispatch = useDispatch();
  const [newPass, setNewPass] = useState({
    password: "",
    showPassword: false,
  });
  const admin = useSelector(getAuth);

  const handleChangePasswordVisibility = () => {
    setNewPass({ ...newPass, showPassword: !newPass.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setNewPass({ ...newPass, [prop]: event.target.value });
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const updatedPassword = await dispatch(updatePassword(newPass.password));
    if (updatedPassword.payload.status === true) {
      notification.notication("success", updatedPassword.payload.message, "", { position: "top-right" });
      setTimeout(() => {
        window.location.replace("/login");
      }, 5000);
    }
  };

  return (
    <div>
      <SideBar />
      <Loader visible={admin.loading} />
      <form className={classes.formContainer} onSubmit={handleUpdatePassword}>
        <p className={classes.resetP}>Reset your password</p>
        <p className={classes.resetText}>Provide your new password</p>
        <div className={classes.inputSection}>
          <label>New Password</label> <br />
          <label className={classes.formLabel}>
            <input
              type={newPass.showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={newPass.password}
              onChange={handlePasswordChange("password")}
              placeholder="***********"
              className={classes.formInput}
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
            />
            <IconButton onClick={handleChangePasswordVisibility}>
              {newPass.showPassword ? <VisibilityIcon color="disabled" /> : <VisibilityOffIcon color="disabled" />}
            </IconButton>
          </label>
        </div>
        <br />
        <button className={classes.submitform}>Save Changes</button>
      </form>
    </div>
  );
};

export default NewPassword;
