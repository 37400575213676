import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { containerStyle } from "../../styles/styles";
import { TableStyles } from "../../styles/table";
import VerticalTabs from "../../tabs";
import TabPanel from "../../common/TabPanel";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import ActiveBasicHealthCareRequest from "../../uis/basicHealthCare/activeTable";
import PendingBasicHealthCareRequest from "../../uis/basicHealthCare/pendingTable";

const tabLabels = ["Approved", "Pending"];

const BasicHealthCare = () => {
  const classes = containerStyle();
  const tabClasses = TableStyles();
  const history = useHistory();
  const { activetab } = useParams();

  const tabNameToIndex = {
    0: "approved",
    1: "pending",
  };

  const indexToTabName = {
    approved: 0,
    pending: 1,
  };

  const [tabIndex, setTabIndex] = useState(indexToTabName[activetab]);
  const onChangeTabIndex = (e, newValue) => {
    history.push(
      `/wellness-on-the-go/basic-health-care/${tabNameToIndex[newValue]}`
    );
    setTabIndex(newValue);
  };
  return (
    <>
      <div className={tabClasses.filterContainer}>
        <p>
          <LocalBarIcon /> Filter by:
        </p>
      </div>
      <div className={classes.main}>
        <div className={tabClasses.tableRoot}>
          <div className={tabClasses.tabsSection}>
            <VerticalTabs
              tabData={tabLabels}
              tabIndex={tabIndex}
              onTabChange={onChangeTabIndex}
            />
          </div>
          <div className={tabClasses.tabPanelContainer}>
            <TabPanel value={0} index={tabIndex}>
              <ActiveBasicHealthCareRequest />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <PendingBasicHealthCareRequest />
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicHealthCare;
