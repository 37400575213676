import React from "react";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Tabs from "../../common/Tabs";
import { AiOutlineFileJpg } from "react-icons/ai";
import Title from "../../common/Typography/title";
import { getEnrollees } from "../../../store/enrollee";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";
import styles from "./index.module.css";

const CardRequestDetails = ({ details }) => {
  const enrollees = useSelector(getEnrollees);
  const enrollee = enrollees.data ? enrollees.data.enrollee_details : {};

  if (!details) return null;
  return (
    <Paper className="mb-5">
      <Tabs
        activeTab={"Request Details"}
        tabData={["Request Details", "", "", "", ""]}
        tabIndex={0}
        onTabChange={(index) => console.log(index)}
      />

      <div className={styles["tab-panel-body"]}>
        <TabPanel value={0} index={0}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Enrollee ID</th>
                <th>Enrollee Name</th>
                <th>Email Address</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{details.enrollee_id}</td>
                <td>{enrollee.name}</td>
                <td style={{ textTransform: "none" }}>{enrollee.email}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Phone Number</th>
                <th>Collected Card Before?</th>
                <th>Payment Status</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{enrollee.phone_number}</td>
                <td>{details.card_collected ? "Yes" : "No"}</td>
                <td>{details.status}</td>
              </tr>
            </tbody>

            <thead>
              <tr className={styles["table-row"]}>
                <th>Date Requested</th>
                <th>Picture Uploaded</th>
              </tr>
            </thead>

            <tbody>
              <tr className={styles["table-row"]}>
                <td>{getFormatedDate(details.created_at)}</td>
                <td>
                  {details.passport_url ? (
                    <a href={`${details.passport_url}`} download={enrollee.name} className="flex items-center">
                      <AiOutlineFileJpg size="1.3em" />
                      <Title
                        style={{
                          marginLeft: ".5rem",
                          textDecoration: "underline",
                          textTransform: "lowercase",
                        }}
                      >
                        {enrollee.name}.jpg
                      </Title>
                    </a>
                  ) : (
                    "Nill"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </TabPanel>
      </div>
    </Paper>
  );
};

export default CardRequestDetails;
