import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import { fetchTransaction, getTelemedicine } from "../../../store/telemedicine";
import ActionModal from "../modals";
import ActionModalContent from "../modals/modalContent";
import Loader from "../../common/Loader";
import { getFormatedDate } from "../../../utils/constants";
import { acceptRequest } from "../../../store/enrollee";
import notification from "../../common/Alert";

export default function TelemedicineTrasanction() {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const transactionData = useSelector(getTelemedicine);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    const fetchTransactionData = async () => {
      dispatch(fetchTransaction(pageLists, pageNumber));
    };
    fetchTransactionData();
  }, [dispatch, pageNumber, pageLists]);

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchTransaction(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchTransaction(pageLists, pageNumber));
  };

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const accept_request = async (id, value) => {
    setModal(false);
    const acceptEnrollee = await dispatch(
      acceptRequest(id, { enrollee_id: value }, "enrollees")
    );
    notification.notication("success", acceptEnrollee.payload.message, "", {
      position: "top-right",
    });
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleOpenConfirmModal = (enrolleeName, id) => {
    setModal(true);
    setContent(
      <ActionModalContent
        approveTextTop={
          <p>
            You are about to approve payment for <b>{enrolleeName}</b> . Would
            you like to proceed.
          </p>
        }
        onApprove={(value) => accept_request(id, value)}
        onApproveSection
        onDecline={() => setModal(false)}
      />
    );
    setModalTitle("Confirm Enrollee");
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = transactionData.transactionList.filter(
    (searched) => {
      if (search === " ") {
        return searched;
      } else if (
        searched.firstname
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.lastname
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.status.includes(search && search.toLowerCase())
      ) {
        return searched;
      } else {
        return null;
      }
    }
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = transactionData.transactionList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}>
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={transactionData.loading} />
      <TableItem
        userType="Transaction"
        modal={
          <ActionModal
            modalTitle={modalTitle}
            content={content}
            open={modal}
            close={handleCloseModal}
          />
        }
        checkBoxes
        tabHeadLabel={[
          "Doctor ",
          "Total Earned",
          "Payment Due",
          "Last Payment Date",
          "Status",
        ]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={transactionData.transactionList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={transactionData.currentPage}
        toPage={transactionData.toPage}
        totalPage={transactionData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= transactionData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              image
              imgInitials={
                item?.firstname.slice(0, 1) + item?.lastname.slice(0, 1)
              }
              enroleeName={item.firstname + " " + item.lastname}
              enroleeId={item.enrollee_id}
              listItem={[
                item?.totalearning,
                item?.expected_payout,
                getFormatedDate(item.date, "YYYY/MMM/DD"),
                <span
                  style={{
                    backgroundColor:
                      item.status === "Paid" ? "#ECFDF3" : "#FFFAEB",
                    color: item.status === "Paid" ? "#027A48" : "#B54708",
                    padding: 8,
                    borderRadius: "16px",
                    fontWeight: 500,
                  }}>
                  &#x2022; {item.status}
                </span>,
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "Make Payment": () => {
                        handleOpenConfirmModal(
                          item.first_name + " " + item.last_name,
                          item.transaction_id
                        );
                      },
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
