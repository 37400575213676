import React from "react";
import PropTypes from "prop-types";
import { dashboardLayoutStyles } from "../../styles/dashboardStyle";

const DashboardLayout = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  middleLeft,
  middleRight,
}) => {
  const classes = dashboardLayoutStyles();
  return (
    <div className={classes.dashboard}>
      {/* top dashboard */}
      <div className={classes.topDashboard}>
        {topLeft && <div className={classes.topLeft}>{topLeft}</div>}

        {topRight && <div className={classes.topRight}>{topRight}</div>}
      </div>
      {/* middle dashboard */}
      <div className={classes.middleDashboard}>
        {middleLeft && (
          <div className={classes.middleLeftDashboard}>{middleLeft}</div>
        )}
        {middleRight && (
          <div className={classes.middleRightDashboard}>{middleRight}</div>
        )}
      </div>

      {/* bottom dashboard */}
      <div className={classes.bottomDashboard}>
        {bottomLeft && (
          <div className={classes.bottomLeftDashboard}>{bottomLeft}</div>
        )}

        {bottomRight && (
          <div className={classes.bottomRightDashboard}>{bottomRight}</div>
        )}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  topLeft: PropTypes.node,
  topRight: PropTypes.node,
  bottomLeft: PropTypes.node,
  bottomRight: PropTypes.node,
};

export default DashboardLayout;
