import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import { fetchDoctor, getTelemedicine } from "../../../store/telemedicine";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { getFormatedDate } from "../../../utils/constants";

export default function DeclinedTelemedicineDoctor() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const doctorData = useSelector(getTelemedicine);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);

  useEffect(() => {
    const fetchDoctorData = async () => {
      dispatch(fetchDoctor(pageLists, pageNumber));
    };
    fetchDoctorData();
  }, [dispatch, pageNumber, pageLists]);

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchDoctor(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchDoctor(pageLists, pageNumber));
  };
  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = doctorData.doctorList.filter((searched) => {
    if (search === " ") {
      return searched;
    } else if (
      searched.firstname
        .toLowerCase()
        .includes(search && search.toLowerCase()) ||
      searched.firstname
        .toLowerCase()
        .includes(search && search.toLowerCase()) ||
      searched.email.includes(search && search.toLowerCase())
    ) {
      return searched;
    } else {
      return null;
    }
  });

  const declinedDoctorList = handleSearchAndFilter.filter((element) => {
    if (element.status === "Declined") {
      let data = [];
      const approvedDoctor = {
        id: element.id,
        firstname: element.firstname,
        lastname: element.lastname,
        name: element.name,
        created_at: element.created_at,
      };
      data.push(approvedDoctor);
      return data;
    } else {
      return null;
    }
  });

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = doctorData.declinedCardList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}>
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={doctorData.loading} />
      <TableItem
        userType="Doctor Name"
        checkBoxes
        tabHeadLabel={[
          "Doctor Name",
          "Email Address",
          "Speciality",
          "Date Created",
        ]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={doctorData.doctorList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={doctorData.currentPage}
        toPage={doctorData.toPage}
        totalPage={doctorData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= doctorData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={declinedDoctorList.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              image
              imgInitials={
                item?.firstname.slice(0, 1) + item?.lastname.slice(0, 1)
              }
              enroleeName={item.firstname + " " + item.lastname}
              listItem={[
                item.email,
                item.name,
                getFormatedDate(item.date_created, "YYYY/MMM/DD"),
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "View Doctor": () =>
                        history.push(
                          `/wellness-doctor/doctors/decline/${item.doctor_id}`
                        ),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
