import React from "react";
import { useSelector } from "react-redux";
import { getUsers } from "../../../store/user";
import { getFormatedDate } from "../../../utils/constants.js";
import styles from "./index.module.css";

const UserDetails = () => {
  const users = useSelector(getUsers);
  const user = users.data ? users.data.user_data : {};

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>User Name</th>
          <th>Email Address</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{`${user?.first_name} ${user?.last_name}`}</td>
          <td style={{ textTransform: "none" }}>{user?.email}</td>
        </tr>
      </tbody>
      <thead>
        <tr className={styles["table-row"]}>
          <th>Phone Number</th>
          <th>Date Created</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{user?.phone_number}</td>
          <td>{getFormatedDate(user?.created_at, "L")}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default UserDetails;
