import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "drugRefill",
  initialState: {
    drugRefillList: [],
    pendingDrugFill: [],
    declinedDrugFill: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedEnrolleeDrugFillSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.drugRefillList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedPendingEnrolleeDrugFillSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.pendingDrugFill = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedDeclinedEnrolleeDrugFillSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.declinedDrugFill = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedEnrolleeDrugFillStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    fetchedEnrolleeDrugFillFailed: (enrollee, action) => {
      enrollee.loading = false;
    },
  },
});

const {
  fetchedEnrolleeDrugFillSuccess,
  fetchedPendingEnrolleeDrugFillSuccess,
  fetchedDeclinedEnrolleeDrugFillSuccess,
  fetchedEnrolleeDrugFillStart,
  fetchedEnrolleeDrugFillFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v1/admin/drug-refills";

export const fetchEnrolleesDrugFill = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedEnrolleeDrugFillSuccess.type,
    onStart: fetchedEnrolleeDrugFillStart.type,
    onError: fetchedEnrolleeDrugFillFailed.type,
  });

export const fetchEnrolleesPendingDrugFill = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingEnrolleeDrugFillSuccess.type,
    onStart: fetchedEnrolleeDrugFillStart.type,
    onError: fetchedEnrolleeDrugFillFailed.type,
  });

export const fetchEnrolleesDeclinedDrugFill = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/declined?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedDeclinedEnrolleeDrugFillSuccess.type,
    onStart: fetchedEnrolleeDrugFillStart.type,
    onError: fetchedEnrolleeDrugFillFailed.type,
  });

export const getEnrolleesDrugFill = (state) => state.entities.enrolleesDrugFill;

export const getPendingDrugRefillById = (state) => (id) =>
  state.entities.enrolleesDrugFill.pendingDrugFill.find(
    (drug) => drug.id === id
  );
