import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import { fetchPatient, getTelemedicine } from "../../../store/telemedicine";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { getFormatedDate } from "../../../utils/constants";

export default function TelemedicinePatient() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const patientData = useSelector(getTelemedicine);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);

  useEffect(() => {
    const fetchPatientData = async () => {
      dispatch(fetchPatient(pageLists, pageNumber));
    };
    fetchPatientData();
  }, [dispatch, pageNumber, pageLists]);

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchPatient(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchPatient(pageLists, pageNumber));
  };

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = patientData.patientList.filter((searched) => {
    if (search === " ") {
      return searched;
    } else if (
      searched.email.toLowerCase().includes(search && search.toLowerCase()) ||
      searched.name.toLowerCase().includes(search && search.toLowerCase()) ||
      searched.phone.includes(search && search.toLowerCase())
    ) {
      return searched;
    } else {
      return null;
    }
  });

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = patientData.patientList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}>
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={patientData.loading} />
      <TableItem
        userType="Enrollee"
        checkBoxes
        tabHeadLabel={[
          "Patient ",
          "Total Appointments",
          "Email Address",
          "Date Created",
        ]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={patientData.patientList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={patientData.currentPage}
        toPage={patientData.toPage}
        totalPage={patientData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= patientData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              image
              imgInitials={item?.name.slice(0, 1) + item?.name.slice(0, 1)}
              enroleeName={item.name}
              enroleeId={item.id}
              listItem={[
                item?.total_appointment,
                item.email,
                getFormatedDate(item.created_at, "YYYY/MMM/DD"),
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "View More": () =>
                        history.push(
                          `/wellness-doctor/patients/${item.patient_id}`
                        ),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
