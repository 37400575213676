import React from "react";
import { useHistory } from "react-router-dom";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";
import { getDashboardData } from "../../../store/dashboard";

const NewRequestTable = () => {
  const history = useHistory();
  let enrolleesData = useSelector(getDashboardData);
  enrolleesData = enrolleesData?.dashboardData?.recent_pending_appointments;

  return (
    <TableItem
      tabHeadLabel={["User", "Amount", "Request Date"]}
      action
      tabBodyData={
        enrolleesData?.length === 0 ? (
          <div>No New Requests</div>
        ) : (
          enrolleesData?.map((item, i) => {
            return (
              <TablesData
                key={i + item?.id}
                enroleeName={`${item?.first_name} ${item?.last_name}`}
                listItem={[
                  item?.amount_paid,
                  getFormatedDate(item?.appointment_date),
                ]}
                action={
                  <AppMenu
                    menu_name_or_icon="View Details"
                    menuBtnStyle1
                    item_names={[
                      {
                        "View Details": () =>
                          history.push(
                            `/wellness-on-the-go/user/${
                              item?.service_name === "cancer (female)"
                                ? "cancer-care"
                                : item?.service_name === "cancer (male)"
                                ? "cancer-care"
                                : item?.service_name === "optical"
                                ? "optical-care"
                                : item?.service_name === "dental"
                                ? "dental"
                                : item?.service_name === "pre-employment"
                                ? "pre-employment"
                                : item?.service_name === "comprehensive"
                                ? "comprehensive"
                                : "basic-health-care"
                            }/accept/${item?.user_id}`
                          ),
                      },
                    ]}
                  />
                }
              />
            );
          })
        )
      }
    />
  );
};

export default NewRequestTable;
