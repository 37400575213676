import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Title from "../../common/Typography/title";
import { BiArrowBack } from "react-icons/bi";
import Tabs from "../../common/Tabs";
import InfoPanel from "../../common/Panel";
import NonEnrolleeDetails from "../widget/nonEnrolleeDetails";
import CardRequest from "../widget/cardRequest";
import DrugRefill from "../widget/drugRefill";
import Appointments from "../widget/appointments";
import AuthorizationCode from "../widget/authorizationCode";
import { fetchNonEnrollee, getNonEnrollees } from "../../../store/nonEnrollee";

import Loader from "../../common/Loader";
import styles from "./index.module.css";

const tabData = [
  "Enrollee Details",
  "Card Request",
  "Authorization Code",
  "Drug Refill",
  "Appointments",
  "Dependents",
];

const NonEnrollee = () => {
  const [currentEnrollee, setCurrentEnrollee] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTab] = useState("Enrollee Details");
  const [, setShowRequestPanel] = useState(false);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const nonEnrollees = useSelector(getNonEnrollees);

  useEffect(() => {
    const getEnrollee = async () => {
      await dispatch(fetchNonEnrollee(params.id));
    };
    getEnrollee();
  }, [dispatch, params]);

  useEffect(() => {
    if (nonEnrollees.nonEnrollee) setCurrentEnrollee(nonEnrollees.nonEnrollee);
  }, [nonEnrollees]);

  const handleShowDetails = (status, id, url) => {
    setShowRequestPanel(status);
    history.push(`/hmo/dashboard/enrollee/${url}/accept/${params.id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.container}>
      <Loader visible={nonEnrollees.loading} />
      <div className="flex items-center  justify-between mb-5">
        <div className="flex items-center">
          <button className="btn-round-small" onClick={() => history.goBack()}>
            <BiArrowBack size="1.2em" />
          </button>
          <Title style={{ fontSize: "16px" }}>Back</Title>
        </div>
      </div>

      <InfoPanel user={currentEnrollee} />

      <div style={{ margin: "2rem 0" }}>
        <Paper>
          <Tabs
            activeTab={currentTab}
            tabData={tabData}
            tabIndex={tabIndex}
            onTabChange={(index) => setTabIndex(index)}
            disableTabs={true}
          />

          <div className={styles["tab-panel-body"]}>
            <TabPanel value={0} index={tabIndex}>
              <NonEnrolleeDetails enrollee={currentEnrollee} />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <CardRequest onShowCardDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={2} index={tabIndex}>
              <AuthorizationCode onShowAuthDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={3} index={tabIndex}>
              <DrugRefill onShowAuthDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={4} index={tabIndex}>
              <Appointments onShowAppointmentDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={4} index={tabIndex}>
              <Appointments onShowAppointmentDetails={handleShowDetails} />
            </TabPanel>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default NonEnrollee;
