import { makeStyles } from "@material-ui/core/styles";

export const containerStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        fontFamily: `'Nunito Sans', sans-serif`,
    },
    toolbar: {
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
    },
    main: {
        padding: theme.spacing(3),
        backgroundColor: "#FCF9FA",
    },
}));