import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "appointment",
  initialState: {
    appointmentList: [],
    pendingAppointment: [],
    declinedAppointment: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedEnrolleeAppointmentSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.appointmentList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedPendingEnrolleeAppointmentSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.pendingAppointment = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedDeclinedEnrolleeAppointmentSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.declinedAppointment = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedEnrolleeAppointmentStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    fetchedEnrolleeAppointmentFailed: (enrollee, action) => {
      enrollee.loading = false;
    },
    appointmentStatusChanged: (enrollee) => {
      enrollee.loading = false;
    },
  },
});

const {
  fetchedEnrolleeAppointmentSuccess,
  fetchedPendingEnrolleeAppointmentSuccess,
  fetchedDeclinedEnrolleeAppointmentSuccess,
  fetchedEnrolleeAppointmentStart,
  fetchedEnrolleeAppointmentFailed,
  appointmentStatusChanged,
} = slice.actions;

export default slice.reducer;

const url = "/v1/admin/hospital-appointments";

export const changeAppointmentStatusById = (id, status) =>
  apiCallBegan({
    url: `${url}/${id}/${status}`,
    method: "PATCH",
    onSuccess: appointmentStatusChanged.type,
    onStart: fetchedEnrolleeAppointmentStart.type,
    onError: fetchedEnrolleeAppointmentFailed.type,
  });

export const fetchEnrolleesAppointment = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedEnrolleeAppointmentSuccess.type,
    onStart: fetchedEnrolleeAppointmentStart.type,
    onError: fetchedEnrolleeAppointmentFailed.type,
  });

export const fetchEnrolleesPendingAppointment = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingEnrolleeAppointmentSuccess.type,
    onStart: fetchedEnrolleeAppointmentStart.type,
    onError: fetchedEnrolleeAppointmentFailed.type,
  });

export const fetchEnrolleesDeclinedAppointment = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/declined?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedDeclinedEnrolleeAppointmentSuccess.type,
    onStart: fetchedEnrolleeAppointmentStart.type,
    onError: fetchedEnrolleeAppointmentFailed.type,
  });

export const getEnrolleesAppointment = (state) =>
  state.entities.enrolleesAppointment;

export const getPendingAppointmentById = (state) => (id) =>
  state.entities.enrolleesAppointment.pendingAppointment.find(
    (appointment) => appointment.id === id
  );
