import React from "react";
import styles from "../index.module.css";

const Button = ({ variant = "solid", children, className = "", ...rest }) => {
  return (
    <button className={`${styles[`button-${variant}`]} ${className}`} {...rest}>
      {children}
    </button>
  );
};

export default Button;
