import React, { useState, useEffect } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import {
  fetchServiceProvider,
  getServiceProvider,
} from "../../../store/serviceProvider";
import ActionModal from "../modals";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import ProviderModal from "../../uis/modals/addProvider/providerModal";
import DeleteServiceModal from "../../uis/modals/addProvider/deleteServiceModal";

export default function ActiveProviders() {
  const [search, setSearch] = useState("");
  const [content, setContent] = useState("");
  const [deleteContent, setDeleteContent] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const serviceProviderData = useSelector(getServiceProvider);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [deleteShowModal, setDeleteShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setContent("");
  };

  const handleDeleteClose = () => {
    setDeleteShowModal(false);
    setDeleteContent("");
  };

  useEffect(() => {
    handleOpenHospitalModal();
    const fetchServiceProviders = async () => {
      await dispatch(fetchServiceProvider());
    };
    fetchServiceProviders();
  }, [dispatch]);

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchServiceProvider(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchServiceProvider(pageLists, pageNumber));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = serviceProviderData.serviceProviderList.filter(
    (searched) => {
      if (search === " ") {
        return searched;
      } else if (
        searched.location
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.name.toLowerCase().includes(search && search.toLowerCase()) ||
        searched.address.includes(search && search.toLowerCase()) ||
        searched.service_name.includes(search && search.toLowerCase())
      ) {
        return searched;
      } else {
        return null;
      }
    }
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = serviceProviderData.serviceProviderList.map(
      (n) => n.id
    );
    setSelected(newSelecteds);
    return;
  };
  const handleOpenHospitalModal = (
    id,
    name,
    location,
    address,
    service_name
  ) => {
    setShowModal(true);
    setContent(
      <ProviderModal
        editId={id}
        editName={name}
        editLocation={location}
        editAddress={address}
        editService={service_name}
        title="Edit Service Provider"
        open={showModal}
        close={handleClose}
        btnText="Save Changes"
        modal={<ActionModal content={content} open={showModal} />}
      />
    );
  };

  const handleDeleteHospitalModal = (id, name) => {
    setDeleteShowModal(true);
    setDeleteContent(
      <DeleteServiceModal
        title={
          <p>
            You are about to delete <b>{name}</b> as a Service Provider. Would
            you like to proceed
          </p>
        }
        open={deleteShowModal}
        close={handleDeleteClose}
        btnText1="Yes, Proceed"
        btnText2="No, Cancel"
        deleteId={id}
      />
    );
  };

  return (
    <>
      {/* {content} */}
      {deleteContent}
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
      >
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={serviceProviderData.loading} />
      <TableItem
        userType="Service Provider"
        modal={<ActionModal content={content} open={showModal} />}
        checkBoxes
        tabHeadLabel={["Service Provider", "Location", "Address", "Service"]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={serviceProviderData.serviceProviderList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={serviceProviderData.currentPage}
        toPage={serviceProviderData.toPage}
        totalPage={serviceProviderData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= serviceProviderData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              enroleeName={item.name}
              listItem={[
                item.location,
                item.address,
                item.service_name.toUpperCase(),
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "Edit Service Provider": () =>
                        handleOpenHospitalModal(
                          item.id,
                          item.name,
                          item.location,
                          item.address,
                          item.service_name
                        ),
                    },
                    {
                      "Delete Hospital": () =>
                        handleDeleteHospitalModal(item.id, item.name),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
