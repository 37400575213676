import { combineReducers } from "redux";
import enrolleeReducer from "../enrollee";
import hospitalReducer from "../hospital";
import serviceReducer from "../services";
import serviceProviderReducer from "../serviceProvider";
import cardRequestReducer from "../cardRequest";
import drugFillReducer from "../drugRefill";
import appointmentReducer from "../appointment";
import authorizationCodeReducer from "../authorizationcode";
import nonEnrolleeReducer from "../nonEnrollee";
import dashboardReducer from "../dashboard";
import usersReducer from "../user";
import usersDentalCareReducer from "../dentalCare";
import usersOpticalCareReducer from "../opticalCare";
import usersHealthCareReducer from "../healthCare";
import cancerCareReducer from "../cancerCare";
import basicHealthCareReducer from "../basicHealthCare";
import employmentCheckReducer from "../employmentCheck";
import telemedicineReducer from "../telemedicine";

// entites contains all the reducers for the application entities such as hmo, enrollee, etc.
export default combineReducers({
  enrollees: enrolleeReducer,
  hospital: hospitalReducer,
  service: serviceReducer,
  serviceProvider: serviceProviderReducer,
  enrolleesCardRequest: cardRequestReducer,
  enrolleesDrugFill: drugFillReducer,
  enrolleesAppointment: appointmentReducer,
  enrolleesAuthorizationCode: authorizationCodeReducer,
  dashboard: dashboardReducer,
  nonEnrollees: nonEnrolleeReducer,
  users: usersReducer,
  dentalCareUsers: usersDentalCareReducer,
  opticalCareUsers: usersOpticalCareReducer,
  healthCareUsers: usersHealthCareReducer,
  cancerCareUsers: cancerCareReducer,
  basicHealthCareUsers: basicHealthCareReducer,
  employmentCheck: employmentCheckReducer,
  telemedicine: telemedicineReducer,
});
