import React from "react";
import { Line } from "react-chartjs-2";
import { getDashboardData } from "../../store/dashboard";
import { useSelector } from "react-redux";

const options = {
  scales: {
    x: {
      beginAtZero: false,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        borderDash: [4, 2],
        color: "#D5D5D5",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  maintainAspectRatio: false,
};

const PlanTypeChart = () => {
  const dashboarddata = useSelector(getDashboardData);
  const dashboard = dashboarddata?.dashboardData?.plan_type;

  return (
    <Line
      data={{
        labels: ["custom", "exclusives", "guard", "premium", "shield", "special"],
        datasets: [
          {
            backgroundColor: "rgba(202, 28, 57, 0.144)",
            fill: true,
            borderColor: "#E6072C",
            data: [
              dashboard?.custom,
              dashboard?.exclusives,
              dashboard?.guard,
              dashboard?.premium,
              dashboard?.shield,
              dashboard?.special,
            ],
            borderWidth: 2,
          },
        ],
      }}
      options={options}
      width="100%"
      margin="auto"
    />
  );
};

export default PlanTypeChart;
