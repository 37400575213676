import React, { useState } from "react";
import { resetPasswordPageStyle } from "./resetStyle";
import SideBar from "../../sidebar-navbar";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAdmin, getAuth } from "../../../store/auth";
import Loader from "../../common/Loader";
import notification from "../../common/Alert";

const ResetPassword = () => {
  const classes = resetPasswordPageStyle();
  const dispatch = useDispatch();
  const [resetPassword, setResetPassword] = useState("");
  const admin = useSelector(getAuth);

  const sendResetPassCode = async (e) => {
    e.preventDefault();
    const resetCode = await dispatch(resetPasswordAdmin(resetPassword));
    if (resetCode.payload.status === true) {
      notification.notication("success", resetCode.payload.message, "", { position: "top-right" });
      setTimeout(() => {
        window.location.replace("reset-code");
      }, 5000);
    }
  };

  return (
    <div>
      <SideBar />
      <Loader visible={admin.loading} />
      <form className={classes.formContainer} onSubmit={sendResetPassCode}>
        <p className={classes.resetP}>Reset your password</p>
        <p className={classes.resetText}>
          Provide your email address and a reset verification code will be sent to you
        </p>
        <div className={classes.inputSection}>
          <label>
            <sup>*</sup>Email Address
          </label>{" "}
          <br />
          <input
            type="email"
            id="email"
            name="email"
            value={resetPassword}
            onChange={(e) => setResetPassword(e.target.value)}
            placeholder="Opeyemi@testcompany.com"
            className={classes.formInput}
          />
        </div>
        <br />
        <button className={classes.submitform}>Send Reset code</button>
      </form>
    </div>
  );
};

export default ResetPassword;
