import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useSelector } from "react-redux";
import { getUsers } from "../../../store/user";
import { getFormatedDate } from "../../../utils/constants";
import AppMenu from "../../menu";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    "& th": {
      color: "#E6072C",
      whiteSpace: "nowrap",
    },
  },

  pending: {
    color: "#FFA800",
    backgroundColor: "#FDF7EB",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
  approved: {
    color: "#25B273",
    backgroundColor: "#E3F9F1",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
  declined: {
    color: "#8B8888",
    backgroundColor: "rgba(139, 136, 136, 0.1)",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
});

const CancerCare = ({ onShowCancerCareDetails }) => {
  const classes = useStyles();
  const users = useSelector(getUsers);
  const cancerCareHistory = users?.data ? users?.data?.cancer_appointments : [];

  return cancerCareHistory?.length === 0 || !cancerCareHistory ? (
    <div className="text-center font-bold">No Cancer History</div>
  ) : (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell>Appointment Date</TableCell>
          <TableCell align="left">Hospital name</TableCell>
          <TableCell align="left">Amount Paid</TableCell>
          <TableCell align="left">Appointment Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {cancerCareHistory?.map((row) => (
          <TableRow key={row?.id}>
            <TableCell component="th" scope="row">
              {getFormatedDate(row?.appointment_date)}
            </TableCell>
            <TableCell align="left">{row?.hospital_name}</TableCell>
            <TableCell align="left">
              {row?.amount_paid ? row?.amount_paid : "Nil"}
            </TableCell>
            <TableCell align="left">
              <span className={classes[row?.status]}>{row?.status}</span>
            </TableCell>
            <TableCell align="center">
              <AppMenu
                menu_name_or_icon={
                  <button>
                    <FiMoreHorizontal size="1.3em" color="#A8A8A8" />
                  </button>
                }
                item_names={[
                  {
                    "View Details": () => {
                      localStorage.setItem("details_id", row?.id);
                      onShowCancerCareDetails(true, row?.id, "cancer-care");
                    },
                  },
                ]}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CancerCare;
