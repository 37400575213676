import React, { useState, useEffect } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import { fetchHospital, getHospital } from "../../../store/hospital";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import HospitalModal from "../../uis/modals/addHospitalModal/hospitalModal";
import DeleteHospitalModal from "../../uis/modals/addHospitalModal/deleteHospitalModal";

export default function ActiveHospitals() {
  const [search, setSearch] = useState("");
  const [content, setContent] = useState("");
  const [deleteContent, setDeleteContent] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const hospitalData = useSelector(getHospital);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [deleteShowModal, setDeleteShowModal] = useState(false);

  useEffect(() => {
    const fetchActiveHospital = async () => {
      await dispatch(fetchHospital());
    };
    fetchActiveHospital();
  }, [dispatch]);

  const handleClose = () => {
    setShowModal(false);
    setContent("");
  };

  const handleDeleteClose = () => {
    setDeleteShowModal(false);
    setDeleteContent("");
  };

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchHospital());
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchHospital());
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = hospitalData.activeHospitalList.filter(
    (searched) => {
      if (search === " ") {
        return searched;
      } else if (
        searched.name.toLowerCase().includes(search && search.toLowerCase()) ||
        searched.location
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.location.includes(search && search.toLowerCase())
      ) {
        return searched;
      } else {
        return null;
      }
    }
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = hospitalData.activeHospitalList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  const handleOpenHospitalModal = (id, name, location, address, plan) => {
    setShowModal(true);
    setContent(
      <HospitalModal
        editId={id}
        editName={name}
        editLocation={location}
        editAddress={address}
        editItem={plan}
        title="Edit Hospital"
        open={showModal}
        close={handleClose}
        btnText="Save Changes"
      />
    );
  };

  const handleDeleteHospitalModal = (id) => {
    setDeleteShowModal(true);
    setDeleteContent(
      <DeleteHospitalModal
        title="Type DELETE to delete Hospital"
        open={deleteShowModal}
        close={handleDeleteClose}
        btnText="Approve"
        deleteId={id}
      />
    );
  };

  return (
    <>
      {content}
      {deleteContent}
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
      >
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={hospitalData.loading} />
      <TableItem
        userType="Hospital"
        checkBoxes
        tabHeadLabel={["Hospital", "Location", "Address", "Plan Type"]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={hospitalData.activeHospitalList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={hospitalData.currentPage}
        toPage={hospitalData.toPage}
        totalPage={hospitalData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= hospitalData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              enroleeName={item.name}
              listItem={[item.location, item.address, item.plan]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "Edit Hospital Details": () =>
                        handleOpenHospitalModal(
                          item.id,
                          item.name,
                          item.location,
                          item.address,
                          item.plan
                        ),
                    },
                    {
                      "Delete Hospital": () =>
                        handleDeleteHospitalModal(item.id),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
