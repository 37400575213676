import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import {
  fetchTelemedicine,
  getTelemedicine,
} from "../../../store/telemedicine";

import ActionModal from "../modals";
import ActionViewDetails from "../modals/viewDetails";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { getFormatedDate } from "../../../utils/constants";

export default function Telemedicine() {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const telemedicineData = useSelector(getTelemedicine);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    const fetchTelemedicineData = async () => {
      dispatch(fetchTelemedicine(pageLists, pageNumber));
    };
    fetchTelemedicineData();
  }, [dispatch, pageNumber, pageLists]);

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchTelemedicine(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchTelemedicine(pageLists, pageNumber));
  };

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleOpenConfirmModal = (
    appointment_date,
    doctor_name,
    patient_name,
    start_time,
    end_time,
    appointment_duration,
    status,
    reason_for_rescheduling,
    new_date,
    new_time,
    patient_email,
    doctor_email
  ) => {
    setModal(true);
    setContent(
      <ActionViewDetails
        appointment_date={appointment_date}
        doctor_name={doctor_name}
        patient_name={patient_name}
        patient_email={patient_email}
        doctor_email={doctor_email}
        start_time={start_time}
        end_time={end_time}
        appointment_duration={appointment_duration}
        status={status}
        reason_for_rescheduling={reason_for_rescheduling}
        new_date={new_date}
        new_time={new_time}
        open={true}
        close={() => setModal(false)}
      />
    );
    setModalTitle("Confirm Enrollee");
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = telemedicineData.telemedicineList.filter(
    (searched) => {
      if (search === " ") {
        return searched;
      } else if (
        searched.name.toLowerCase().includes(search && search.toLowerCase()) ||
        searched.phone.toLowerCase().includes(search && search.toLowerCase()) ||
        searched.patient_email.includes(search && search.toLowerCase())
      ) {
        return searched;
      } else {
        return null;
      }
    }
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = telemedicineData.telemedicineList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "2rem",
          justifyContent: "space-between",
        }}>
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={telemedicineData.loading} />
      <TableItem
        userType="Telemedicine"
        modal={
          <ActionModal
            modalTitle={modalTitle}
            content={content}
            open={modal}
            close={handleCloseModal}
          />
        }
        tabHeadLabel={[
          "Appointment Date",
          "Doctor Name",
          "Patient Name",
          "Appointment Duration",
          "Amount Paid",
          "Status",
        ]}
        tableSize
        action
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={telemedicineData.telemedicineList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={telemedicineData.currentPage}
        toPage={telemedicineData.toPage}
        totalPage={telemedicineData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= telemedicineData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.telemedicine_id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) =>
                handleClick(event, item.telemedicine_id)
              }
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              listItem={[
                getFormatedDate(item.date, "YYYY/MMM/DD"),
                item.doctor_firstname + " " + item.doctor_lastname,
                item.name,
                item.duration,
                item.amount,
                <span
                  style={{
                    backgroundColor:
                      item.status === "Approved"
                        ? "#ECFDF3"
                        : item.status === "Pending"
                        ? "#FFFAEB"
                        : "#F2F4F7",
                    color:
                      item.status === "Approved"
                        ? "#027A48"
                        : item.status === "Pending"
                        ? "#B54708"
                        : "#344054",
                    padding: 8,
                    borderRadius: "16px",
                    fontWeight: 500,
                  }}>
                  &#x2022; {item.status}
                </span>,
              ]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "View Details": () => {
                        handleOpenConfirmModal(
                          item.date,
                          item.doctor_firstname + " " + item.doctor_lastname,
                          item.name,
                          item.start_time,
                          item.end_time,
                          item.duration,
                          item.status,
                          item.reschedule_comment,
                          item.new_patient_date,
                          item.new_patient_time,
                          item.patient_email,
                          item.user_email
                        );
                      },
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
