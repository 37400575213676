import React from "react";
import { Bar } from "react-chartjs-2";

const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        borderDash: [4, 2],
        color: "#D5D5D5",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

const data = {
  labels: [
    ["Opeyemi", "Olatogun"],
    ["Jubril", "Adams"],
    ["Bibi", "Ofuya"],
    ["Essien", "Emmanuel"],
    ["Kunle", "Ade"],
    "Others",
  ],
  datasets: [
    {
      backgroundColor: "#F1C443",
      borderColor: "transparent",
      hoverBackgroundColor: "#F1C443",
      hoverBorderColor: "#F1C443",
      data: [20000, 5000, 16500, 5000, 17500, 20000, 25000],
      borderWidth: 15,
    },
  ],
};

const TopEnroleeChart = () => {
  return <Bar data={data} options={options} width="100%" margin="auto" />;
};

export default TopEnroleeChart;
