import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";
import { getRandomColor } from "../../utils/constants";

let slice = createSlice({
  name: "enrollees",
  initialState: {
    activeEnrolleesList: [],
    pendingList: [],
    nonEnroleeList: [],
    declinedList: [],
    loading: false,
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    data: {},
  },
  reducers: {
    fetchedEnrolleesSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.activeEnrolleesList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedPendingEnrolleeSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.pendingList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedNonEnrolleeSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.nonEnroleeList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedDeclinedEnrolleeSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.declinedList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedEnrolleesStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    fetchedEnrolleesFailed: (enrollee, action) => {
      enrollee.loading = false;
    },
    fetchedEnrolleeSuccess: (enrollee, { payload }) => {
      enrollee.loading = false;
      if (!payload.data.enrollee_details.image_url) {
        payload.data.enrollee_details.image_color = getRandomColor();
      }
      enrollee.data = payload.data;
    },
    fetchedEnrolleeStart: (enrollee, { payload }) => {
      enrollee.loading = true;
    },
    fetchedEnrolleeFailed: (enrollee, { payload }) => {
      enrollee.loading = false;
    },
    enrolleeStatusChanged: (enrollee, { payload }) => {
      enrollee.loading = false;
    },
  },
});

const {
  fetchedEnrolleesSuccess,
  fetchedEnrolleesStart,
  fetchedEnrolleesFailed,
  fetchedEnrolleeSuccess,
  fetchedEnrolleeFailed,
  fetchedEnrolleeStart,
  fetchedPendingEnrolleeSuccess,
  fetchedNonEnrolleeSuccess,
  fetchedDeclinedEnrolleeSuccess,
  enrolleeStatusChanged,
} = slice.actions;

export default slice.reducer;
const url = "/v1/admin";
/**
 *
 * @param {number} id {id of the request}
 * @param {string} status {could be approved or declined only}
 * @param {string} requestUrl {the patch request url such as 'request-card', 'request-code' etc}
 * @returns {object} payload
 */
export const updateRequestStatusById = (id, status, requestUrl) =>
  apiCallBegan({
    url: `${url}/${requestUrl}/${id}/${status}`,
    method: "PATCH",
    onSuccess: enrolleeStatusChanged.type,
    onStart: fetchedEnrolleeStart.type,
    onError: fetchedEnrolleeFailed.type,
  });

/**
 *
 * @param {number} id {id of the request}
 * @param {string} value {the request body value}
 * @param {string} requestUrl {the patch request url such as 'enrollees', 'request-code' etc}
 * @param {string} actionUrl {/approved or /declined}
 * @returns {object} payload
 */
export const acceptRequest = (
  id,
  data = {},
  requestUrl = "enrollees",
  actionUrl = ""
) =>
  apiCallBegan({
    method: "PATCH",
    url: url + `/${requestUrl}/${id}${actionUrl}`,
    data,
    onSuccess: enrolleeStatusChanged.type,
    onStart: fetchedEnrolleeStart.type,
    onError: fetchedEnrolleeFailed.type,
  });

export const fetchEnrollee = (id) =>
  apiCallBegan({
    url: url + `/enrollees/${id}`,
    onSuccess: fetchedEnrolleeSuccess.type,
    onStart: fetchedEnrolleeStart.type,
    onError: fetchedEnrolleeFailed.type,
  });

export const fetchEnrollees = (pageItems, page) =>
  apiCallBegan({
    url:
      url +
      `/enrollees?page_items=${pageItems}&page=${page}&subscription_status=all`,
    method: "GET",
    onSuccess: fetchedEnrolleesSuccess.type,
    onStart: fetchedEnrolleesStart.type,
    onError: fetchedEnrolleesFailed.type,
  });

export const fetchPendingEnrollees = (pageItems, page) =>
  apiCallBegan({
    url:
      url +
      `/enrollees?page_items=${pageItems}&page=${page}&subscription_status=pending`,
    method: "GET",
    onSuccess: fetchedPendingEnrolleeSuccess.type,
    onStart: fetchedEnrolleeStart.type,
    onError: fetchedEnrolleeFailed.type,
  });

export const fetchDeclinedEnrollees = (pageItems, page) =>
  apiCallBegan({
    url:
      url +
      `/enrollees?page_items=${pageItems}&page=${page}&subscription_status=declined`,
    method: "GET",
    onSuccess: fetchedDeclinedEnrolleeSuccess.type,
    onStart: fetchedEnrolleeStart.type,
    onError: fetchedEnrolleeFailed.type,
  });

export const fetchNonEnrollees = (pageItems, page) =>
  apiCallBegan({
    url: url + `/non-enrollees?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedNonEnrolleeSuccess.type,
    onStart: fetchedEnrolleeStart.type,
    onError: fetchedEnrolleeFailed.type,
  });

export const getEnrollees = (state) => state.entities.enrollees;
