import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";
import { getRandomColor } from "../../utils/constants";

let slice = createSlice({
  name: "users",
  initialState: {
    activeUsersList: [],
    pendingList: [],
    loading: false,
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    data: {},
    serviceDetails: {},
  },
  reducers: {
    fetchedApprovedUsersSuccess: (user, action) => {
      user.loading = false;
      user.activeUsersList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedUsersStart: (user, action) => {
      user.loading = true;
    },
    fetchedUsersFailed: (user, action) => {
      user.loading = false;
    },
    fetchedUserSuccess: (user, { payload }) => {
      user.loading = false;
      if (!payload.data?.image_url) {
        payload.data.image_color = getRandomColor();
      }
      user.data = payload.data;
    },
    fetchedUserStart: (user, { payload }) => {
      user.loading = true;
    },
    fetchedUserFailed: (user, { payload }) => {
      user.loading = false;
    },
    userStatusChanged: (user, { payload }) => {
      user.loading = false;
    },
    fetchedUserSevicesSuccess: (user, { payload }) => {
      user.loading = false;
      user.serviceDetails = payload.data;
    },
  },
});

const {
  fetchedApprovedUsersSuccess,
  fetchedUsersFailed,
  fetchedUsersStart,
  fetchedUserSuccess,
  userStatusChanged,
  fetchedUserFailed,
  fetchedUserStart,
  fetchedUserSevicesSuccess,
} = slice.actions;

export default slice.reducer;
const url = "/v2/admin";

/**
 *
 * @param {number} id {id of the request}
 * @param {string} status {could be approved or declined only}
 * @param {string} requestUrl {the patch request url such as 'request-card', 'request-code' etc}
 * @returns {object} payload
 */
export const updateRequestStatusById = (id, status, requestUrl) =>
  apiCallBegan({
    url: `${url}/${requestUrl}/${id}/${status}`,
    method: "PATCH",
    onSuccess: userStatusChanged.type,
    onStart: fetchedUserStart.type,
    onError: fetchedUserFailed.type,
  });

export const fetchUserServiceDetails = (id) =>
  apiCallBegan({
    url: `${url}/appointment-details/${id}`,
    method: "GET",
    onSuccess: fetchedUserSevicesSuccess.type,
    onStart: fetchedUserStart.type,
    onError: fetchedUserFailed.type,
  });
/**
 *
 * @param {number} id {id of the request}
 * @param {string} value {the request body value}
 * @returns {object} payload
 */
export const acceptUserRequest = (id, data = {}) =>
  apiCallBegan({
    method: "PATCH",
    url: url + `/appointment/request-code/${id}/approved`,
    data,
    onSuccess: userStatusChanged.type,
    onStart: fetchedUserStart.type,
    onError: fetchedUserFailed.type,
  });

export const fetchUser = (id) =>
  apiCallBegan({
    url: url + `/users/${id}`,
    onSuccess: fetchedUserSuccess.type,
    onStart: fetchedUserStart.type,
    onError: fetchedUserFailed.type,
  });

export const fetchUsers = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/users?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedApprovedUsersSuccess.type,
    onStart: fetchedUsersStart.type,
    onError: fetchedUsersFailed.type,
  });

export const getUsers = (state) => state.entities.users;
// export const getUsersAppointmentDetails = (state) =>
//   state.entities.users.serviceDetails;
