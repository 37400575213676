import { makeStyles } from "@material-ui/core/styles";

export const dashboardLayoutStyles = makeStyles((theme) => ({
  dashboard: {
    display: "grid",
    gridTemplateColumns: "repeat(1, auto)",
    gap: "2.5rem",
  },

  topDashboard: {
    display: "grid",
    gridTemplateColumns:
      "[col1-start] 1fr [col1-end col2-start] 2.6fr [col2-end]",
    gap: "2rem",

    "@media (max-width:768px)": {
      gridTemplateColumns: "repeat(1, auto)",
      gap: "2rem",
    },
  },
  topLeftDashboard: {
    display: "grid",
    gridTemplateColumns: "repeat(1, auto)",
    gap: "1rem",
  },
  topRightDashboard: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 12px #F2F4F9",
    borderRadius: "10px",
    padding: "1rem",
  },

  middleDashboard: {
    display: "grid",
    gridTemplateColumns:
      "[col1-start] 2.5fr [col1-end col2-start] 1.2fr [col2-end]",
    gap: "2rem",

    "@media (max-width:768px)": {
      gridTemplateColumns: "repeat(1, auto)",
      gap: "2rem",
    },
  },
  middleLeftDashboard: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 12px #F2F4F9",
    borderRadius: "10px",
    padding: "1rem",
  },
  middleRightDashboard: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 12px #F2F4F9",
    borderRadius: "10px",
    padding: "1rem",
  },

  bottomDashboard: {
    display: "grid",
    gridTemplateColumns:
      "[col1-start] 2fr [col1-end col2-start] 2.5fr [col2-end]",
    gap: "2rem",

    "@media (max-width:768px)": {
      gridTemplateColumns: "repeat(1, auto)",
      gap: "2rem",
    },
  },
  bottomLeftDashboard: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 12px #F2F4F9",
    borderRadius: "10px",
    padding: "1rem",
  },
  bottomRightDashboard: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 12px #F2F4F9",
    borderRadius: "10px",
    padding: "1rem",
  },
}));
