import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "authorization",
  initialState: {
    authorizationList: [],
    pendingAuthorization: [],
    declinedAuthorization: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedEnrolleeAuthorizationSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.authorizationList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedPendingEnrolleeAuthorizationSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.pendingAuthorization = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedDeclinedEnrolleeAuthorizationSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.declinedAuthorization = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedEnrolleeAuthorizationStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    fetchedEnrolleeAuthorizationFailed: (enrollee, action) => {
      enrollee.loading = false;
    },
  },
});

const {
  fetchedEnrolleeAuthorizationSuccess,
  fetchedPendingEnrolleeAuthorizationSuccess,
  fetchedDeclinedEnrolleeAuthorizationSuccess,
  fetchedEnrolleeAuthorizationStart,
  fetchedEnrolleeAuthorizationFailed,
} = slice.actions;

export default slice.reducer;

const url = "/v1/admin/request-code";

export const fetchEnrolleesAuthorizationCode = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedEnrolleeAuthorizationSuccess.type,
    onStart: fetchedEnrolleeAuthorizationStart.type,
    onError: fetchedEnrolleeAuthorizationFailed.type,
  });

export const fetchEnrolleesPendingAuthorizationCode = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingEnrolleeAuthorizationSuccess.type,
    onStart: fetchedEnrolleeAuthorizationStart.type,
    onError: fetchedEnrolleeAuthorizationFailed.type,
  });

export const fetchEnrolleesDeclinedAuthorizationCode = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/declined?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedDeclinedEnrolleeAuthorizationSuccess.type,
    onStart: fetchedEnrolleeAuthorizationStart.type,
    onError: fetchedEnrolleeAuthorizationFailed.type,
  });

export const getEnrolleesAuthorizationCode = (state) =>
  state.entities.enrolleesAuthorizationCode;

export const getPendingAuthCodeById = (state) => (id) =>
  state.entities.enrolleesAuthorizationCode.pendingAuthorization.find(
    (item) => item.id === id
  );
