import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "cardRequest",
  initialState: {
    cardRequestList: [],
    pendingCardList: [],
    declinedCardList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedEnrolleeCardRequestSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.cardRequestList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedPendingEnrolleeCardRequestSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.pendingCardList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedDeclinedEnrolleeCardRequestSuccess: (enrollee, action) => {
      enrollee.loading = false;
      enrollee.declinedCardList = action.payload.data.data;
      enrollee.total = action.payload.data.total;
      enrollee.currentPage = action.payload.data.from;
      enrollee.toPage = action.payload.data.to;
      enrollee.lastPage = action.payload.data.last_page;
    },
    fetchedEnrolleeCardRequestStart: (enrollee, action) => {
      enrollee.loading = true;
    },
    fetchedEnrolleeCardRequestFailed: (enrollee, action) => {
      enrollee.loading = false;
    },
  },
});

const {
  fetchedEnrolleeCardRequestSuccess,
  fetchedPendingEnrolleeCardRequestSuccess,
  fetchedDeclinedEnrolleeCardRequestSuccess,
  fetchedEnrolleeCardRequestStart,
  fetchedEnrolleeCardRequestFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v1/admin/request-card";

export const fetchEnrolleesCardRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedEnrolleeCardRequestSuccess.type,
    onStart: fetchedEnrolleeCardRequestStart.type,
    onError: fetchedEnrolleeCardRequestFailed.type,
  });

export const fetchEnrolleesPendingCardRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingEnrolleeCardRequestSuccess.type,
    onStart: fetchedEnrolleeCardRequestStart.type,
    onError: fetchedEnrolleeCardRequestFailed.type,
  });

export const fetchEnrolleesDeclinedCardRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/declined?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedDeclinedEnrolleeCardRequestSuccess.type,
    onStart: fetchedEnrolleeCardRequestStart.type,
    onError: fetchedEnrolleeCardRequestFailed.type,
  });

export const getEnrolleesCardRequest = (state) =>
  state.entities.enrolleesCardRequest;

export const getPendingCardRequest = (state) =>
  state.entities.enrolleesCardRequest.pendingCardList;

export const getPendingCardRequstById = (state) => (id) =>
  state.entities.enrolleesCardRequest.pendingCardList.find((e) => e.id === id);
