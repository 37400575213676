import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "employement-check",
  initialState: {
    activeEmploymentCheckRequestList: [],
    pendingEmploymentCheckList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedEmploymentCheckRequestSuccess: (user, action) => {
      user.loading = false;
      user.activeEmploymentCheckRequestList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedPendingEmploymentCheckRequestSuccess: (user, action) => {
      user.loading = false;
      user.pendingEmploymentCheckList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedEmploymentCheckRequestStart: (user, action) => {
      user.loading = true;
    },
    fetchedEmploymentCheckRequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

const {
  fetchedEmploymentCheckRequestSuccess,
  fetchedPendingEmploymentCheckRequestSuccess,
  fetchedEmploymentCheckRequestStart,
  fetchedEmploymentCheckRequestFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v2/admin/appointments/pre-employment";

export const fetchEmploymentCheckUsersRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedEmploymentCheckRequestSuccess.type,
    onStart: fetchedEmploymentCheckRequestStart.type,
    onError: fetchedEmploymentCheckRequestFailed.type,
  });

export const fetchEmploymentCheckUserPendingRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingEmploymentCheckRequestSuccess.type,
    onStart: fetchedEmploymentCheckRequestStart.type,
    onError: fetchedEmploymentCheckRequestFailed.type,
  });

export const getEmploymentCheckUsers = (state) =>
  state.entities.employmentCheck;

export const getPendingEmploymentCheckUser = (state) =>
  state.entities.employmentCheck.pendingEmploymentCheckList;

export const getPendingEmploymentCheckUserById = (state) => (id) =>
  state.entities.employmentCheck.pendingEmploymentCheckList.find(
    (e) => e.id === id
  );
