import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import styles from "./index1.module.css";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Box from "@material-ui/core/Box";
import { deleteHospital } from "../../../../store/hospital";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "485px ",
  height: "258px",
  bgcolor: "#f9f9f9",
  borderRadius: "8px",
  border: "1px solid #fcf9fa",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1);",
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
    "&:hover": {
      background: "#e6072c",
    },
    marginBottom: 15,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function DeleteHospitalModal({
  open,
  close,
  title,
  btnText,
  deleteId,
}) {
  const classes = useStyles();
  const [input, setInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const doSubmit = async (id) => {
    await dispatch(deleteHospital(id));
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input !== "DELETE") {
      setErrorMessage("Please enter a corresponding value");
      return;
    } else {
      doSubmit(deleteId);
    }
  };

  if (!open) return null;

  return (
    <div className={classes.modal}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={classes.modalTop}>
              <div
                style={{
                  color: "#5A5454",
                }}
              ></div>
              <CloseSharpIcon
                color="disabled"
                onClick={close}
                className={classes.closeIcon}
              />
            </div>
            <div className={styles["title"]}>
              <h1>{title}</h1>
            </div>
            <form className={styles["form"]} onSubmit={handleSubmit}>
              <div className={styles["form-control"]}>
                <input
                  onChange={({ target }) => setInput(target.value)}
                  value={input}
                  type="text"
                  placeholder="Type DELETE"
                />
              </div>
              <div>
                <button type="submit" className={styles["button-lg"]}>
                  {btnText}
                </button>
                {errorMessage && (
                  <div className={styles["error"]}>{errorMessage}</div>
                )}
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
