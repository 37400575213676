import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Title from "../../common/Typography/title";
import { BiArrowBack } from "react-icons/bi";
import Button from "../../common/Button";
import Tabs from "../../common/Tabs";
import WellnessOnTheGoPanel from "../../common/Panel/wellnssOnTheGo";
import DentalCareRequestDetails from "../components/dentalCareDetails";
import OpticalCareRequestDetails from "../components/opticalCareDetails";
import CancerCareDetails from "../components/cancerCareDetails";
import HealthCareRequestDetails from "../components/healthCareDetails";
import BasicHealthCareDetails from "../components/basicHealthCareDetails";
import EmploymentCheckDetails from "../components/employmentCheckDetails";
import UserDetails from "../widget/userDetails";
import DentalCare from "../widget/dentalCare";
import BasicHealthCare from "../widget/basicHealthCare";
import CancerCare from "../widget/cancerCare";
import EmploymentCheck from "../widget/employmentCheck";
import HealthCare from "../widget/healthCare";
import OpticalCare from "../widget/opticalCare";
import {
  acceptUserRequest,
  fetchUser,
  getUsers,
  fetchUserServiceDetails,
  // updateRequestStatusById,
} from "../../../store/user";

import Alert from "../../common/Alert";

import Loader from "../../common/Loader";
import styles from "./index.module.css";
import ModalAction from "./components/modal";

const tabData = [
  "User Details",
  "Dental Care",
  "Optical Care",
  "Comprehensive",
  "Cancer Care",
  "Basic Health Care",
  "Pre-Employment",
];

const AcceptUserRequest = () => {
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState("User Details");
  const [DetailsComponent, setDetailsComponent] = useState(null);
  const [showRequestPanel, setShowRequestPanel] = useState(false);

  const fromUrlDetailID = location.state && location.state.detailId;

  const [detailId, setDetailId] = useState(fromUrlDetailID || null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [responseValue, setResponseValue] = useState("");
  const [currentDetail, setCurrentDetail] = useState({});

  const params = useParams();
  const history = useHistory();
  const users = useSelector(getUsers);
  const dispatch = useDispatch();

  const updateTab = (index, component = null, data) => {
    setCurrentDetail(data);
    setTabIndex(index);
    setCurrentTab(tabData[index]);
    setDetailsComponent(component);
  };

  useEffect(() => {
    const getUser = async () => {
      if (params.requestType === "details") setIsNewUser(true);
      await dispatch(fetchUser(params.id));
    };
    getUser();
  }, [dispatch, params]);

  useEffect(() => {
    if (!users.data) history.push("/wellness-on-the-go/users/approved");
    else setCurrentUser(users.data);
  }, [params, history, dispatch, users]);

  const getHistoryData = useCallback(
    (field) => {
      return (
        users?.data &&
        users?.data[field]?.find((history) => history.id === detailId)
      );
    },
    [detailId, users]
  );

  console.log(users, "man");

  useEffect(() => {
    switch (params.requestType) {
      case "details":
        updateTab(0, null, { status: "pending", id: params.id });
        break;
      case "dental-care":
        const dentalCareRequest = getHistoryData("dental_appointments");
        updateTab(
          1,
          <DentalCareRequestDetails details={dentalCareRequest} />,
          dentalCareRequest
        );
        break;
      case "optical-care":
        const currentOpticalCare = getHistoryData("optical_appointments");
        updateTab(
          2,
          <OpticalCareRequestDetails details={currentOpticalCare} />,
          currentOpticalCare
        );
        break;
      case "comprehensive":
        const currentHealthCare = getHistoryData("comprehensive_appointments");
        updateTab(
          3,
          <HealthCareRequestDetails details={currentHealthCare} />,
          currentHealthCare
        );
        break;
      case "cancer-care":
        const currentCancerCare = getHistoryData("cancer_appointments");
        updateTab(
          4,
          <CancerCareDetails details={currentCancerCare} />,
          currentCancerCare
        );
        break;
      case "basic-health-care":
        const currentBasicHealthCare = getHistoryData(
          "basic_health_check_appointments"
        );
        updateTab(
          5,
          <BasicHealthCareDetails details={currentBasicHealthCare} />,
          currentBasicHealthCare
        );
        break;
      case "pre-employment":
        const currentEmplymentCheck = getHistoryData(
          "pre_employment_appointments"
        );
        updateTab(
          6,
          <EmploymentCheckDetails details={currentEmplymentCheck} />,
          currentEmplymentCheck
        );
        break;
      default:
        updateTab(0);
        break;
    }
  }, [params, currentUser, detailId, getHistoryData]);

  const handleRequestType = async (e, type) => {
    e.preventDefault();
    setOpenModal(false);

    switch (params.requestType) {
      case "dental-care":
        if (!responseValue.trim() && type === "approved") return;
        const dentalCodeRes = await dispatch(
          acceptUserRequest(detailId, { request_code: responseValue })
        );
        handleSuccessNotification(
          dentalCodeRes?.payload?.status,
          `dental request code sucessfully ${type}`,
          `/wellness-on-the-go/users/view/dental-care/${params.id}`
        );
        break;
      case "optical-care":
        if (!responseValue.trim() && type === "approved") return;
        const opticalCodeRes = await dispatch(
          acceptUserRequest(detailId, { request_code: responseValue })
        );
        handleSuccessNotification(
          opticalCodeRes.payload.status,
          `optical request code sucessfully ${type}`,
          `/wellness-on-the-go/users/view/optical-care/${params.id}`
        );
        break;
      case "comprehensive":
        if (!responseValue.trim() && type === "approved") return;
        const comprehensiveCareCodeRes = await dispatch(
          acceptUserRequest(detailId, { request_code: responseValue })
        );
        handleSuccessNotification(
          comprehensiveCareCodeRes?.payload?.status,
          `comprehensive care request code sucessfully ${type}`,
          `/wellness-on-the-go/users/view/comprehensive/${params.id}`
        );
        break;
      case "cancer-care":
        if (!responseValue.trim() && type === "approved") return;
        const cancerCareCodeRes = await dispatch(
          acceptUserRequest(detailId, { request_code: responseValue })
        );
        handleSuccessNotification(
          cancerCareCodeRes?.payload?.status,
          `cancer care request code sucessfully ${type}`,
          `/wellness-on-the-go/users/view/cancer-care/${params.id}`
        );
        break;
      case "basic-health-care":
        if (!responseValue.trim() && type === "approved") return;
        const basicHealthCareCodeRes = await dispatch(
          acceptUserRequest(detailId, { request_code: responseValue })
        );
        handleSuccessNotification(
          basicHealthCareCodeRes?.payload?.status,
          `basic health care request code sucessfully ${type}`,
          `/wellness-on-the-go/users/view/basic-health-care/${params.id}`
        );
        break;
      case "pre-employment":
        if (!responseValue.trim() && type === "approved") return;
        const employmentCheckCodeRes = await dispatch(
          acceptUserRequest(detailId, { request_code: responseValue })
        );
        handleSuccessNotification(
          employmentCheckCodeRes?.payload?.status,
          `pre employment request code sucessfully ${type}`,
          `/wellness-on-the-go/users/view/pre-employment/${params.id}`
        );
        break;
      default:
        break;
    }
  };
  const handleShowDetails = (status, id, url) => {
    setShowRequestPanel(status);
    setDetailId(id);
    dispatch(fetchUserServiceDetails(localStorage.getItem("details_id")));
    history.push({
      pathname: `/wellness-on-the-go/user/${url}/accept/${params.id}`,
      state: { detailId: id },
    });
    window.scrollTo(0, 0);
  };

  const handleSuccessNotification = async (status, message, redirectUrl) => {
    if (status) await Alert.notication("success", message);
    history.push(redirectUrl);
  };
  const handleSetApprove = () => {
    setRequestType("approve");
    setOpenModal(true);
  };

  const requestBodyTypes = [
    "dental-care",
    "optical-care",
    "comprehensive",
    "cancer-care",
    "basic-health-care",
    "pre-employment",
  ];
  const labelsObj = {
    "dental-care": "Request Code",
    "optical-care": "Request Code",
    comprehensive: "Request Code",
    "cancer-care": "Request Code",
    "basic-health-care": "Request Code",
    "pre-employment": "Request Code",
  };
  return (
    <div className={styles.container}>
      <Loader visible={users.loading} />

      <ModalAction
        open={openModal}
        onClose={() => setOpenModal(false)}
        enrolleeName={`${currentUser?.user_data?.first_name} ${currentUser?.user_data?.last_name}`}
        onInputChange={(value) => setResponseValue(value.toUpperCase())}
        inputValue={responseValue}
        onApprove={(e) => handleRequestType(e, "approved")}
        onDecline={(e) => handleRequestType(e, "declined")}
        onCancel={() => setOpenModal(false)}
        request={requestType}
        requiresInput={requestBodyTypes.includes(params.requestType)}
        label={
          labelsObj[
            requestBodyTypes.find((type) => type === params.requestType)
          ]
        }
        requestName={params.requestType}
      />

      <div className="flex items-center  justify-between mb-5">
        <div className="flex items-center">
          <button className="btn-round-small" onClick={() => history.goBack()}>
            <BiArrowBack size="1.2em" />
          </button>
          <Title style={{ fontSize: "16px" }}>Back</Title>
        </div>
        {currentDetail && currentDetail?.status === "pending" && (
          <Button onClick={handleSetApprove}>Approve</Button>
        )}
      </div>

      {showRequestPanel && DetailsComponent}
      <WellnessOnTheGoPanel />

      <div style={{ margin: "2rem 0" }}>
        <Paper>
          <Tabs
            activeTab={currentTab}
            tabData={tabData}
            tabIndex={tabIndex}
            onTabChange={(index) => setTabIndex(index)}
            disableTabs={isNewUser}
          />

          <div className={styles["tab-panel-body"]}>
            <TabPanel value={0} index={tabIndex}>
              <UserDetails />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <DentalCare onShowDentalCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={2} index={tabIndex}>
              <OpticalCare onShowOpticalCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={3} index={tabIndex}>
              <HealthCare onShowHealthCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={4} index={tabIndex}>
              <CancerCare onShowCancerCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={5} index={tabIndex}>
              <BasicHealthCare
                onShowBasicHealthCareDetails={handleShowDetails}
              />
            </TabPanel>
            <TabPanel value={6} index={tabIndex}>
              <EmploymentCheck
                onShowEmploymentCheckDetails={handleShowDetails}
              />
            </TabPanel>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default AcceptUserRequest;
