import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const usePaginateStyles = makeStyles((theme) => ({
  root: {
    display:"flex", 
    alignItems:"center", 
    justifyContent:"space-between", 
    width:"70%", 
    margin:"auto", 
    padding: "1.25rem 0rem"
  },
  paginateRight:{
    display:"flex", 
    alignItems:"center", 
    justifyContent:"space-between"
  },
  showRowP:{
    fontWeight:"bold"
  },
  selectTag:{
    marginLeft: 10,
    padding: 5,
    border: "2px solid grey",
    borderRadius: "4px",
    outline: "none",
    backgroundColor: "transparent"
  }
}));


const EnhancedTablePagination = ({
  currentPage,
  totalPage,
  toPage,
  handleIncrement,
  handleDecrement,
  disabledIncrement,
  disabledDecrement,
  handleChange,
  value
}) => {
  const classes =  usePaginateStyles()
  return (
    <div className={classes.root}>
     <div className={classes.paginateRight}>
      <p className={classes.showRowP}>Show Rows Per Page</p>
      <select onChange={handleChange} value={value} className={classes.selectTag}>
        <option>10</option>
        <option>20</option>
        <option>30</option>
      </select>
     </div>
      <div className={classes.paginateRight}>
        <p>{currentPage}-{toPage}  of {totalPage}</p>
        <div style={{marginLeft: 30}}>
            <button onClick={handleDecrement} disabled={disabledDecrement} style={{opacity: disabledDecrement? "0.4": "1"}}> <ChevronLeftIcon/> </button>
            <button onClick={handleIncrement} disabled={disabledIncrement} style={{opacity: disabledIncrement? "0.4": "1"}}> <ChevronRightIcon/> </button>
        </div>
      </div>
    </div>
  );
};

export default EnhancedTablePagination;
