import moment from "moment";
const colors = ["#8a3ea7", "#2b84d2", "#b02621", "#de352f"];

export const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

export const getIntialsFromString = (str) => {
    if (!str || typeof str !== "string") return str;
    const names = str.split(" ");
    return names[0][0] + names[1][0];
};

export const getFormatedDate = (date, format = "MMM DD, YYYY") => moment(date).format(format);

// this function is not necessary because in getFormatedDate you can pass the format as argumnet
// export const getFormatedDate2 = (date, format = "YYYY/MMM/DD") => moment(date).format(format);

export const getFormattedTimeStamp = (date, format = " h:mm a") => moment(date).format(format);