import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppMenu from "../../menu";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#251A6B",
          padding: 0,
        }
      : {
          color: theme.palette.text.primary,
        },

  title: {
    color: theme.palette.text.primary,
  },
  toolLabel: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
  },
  check: {
    padding: 0,
  },
  spanBorder: {
    border: "1px solid #E5E5E5",
    padding: "5px 10px",
    borderRadius: "4px 0px 0px 4px",
    display: "flex",
    alignItems: "center",
    margin: 0,
  },
}));

export const EnhancedTableToolbar = ({ numSelected, rowCount, userType }) => {
  const classes = useToolbarStyles();

  return (
    <div className={classes.root}>
      <label className={classes.toolLabel}>
        <span className={classes.spanBorder}>
          <Checkbox
            className={numSelected > 0 ? classes.highlight : classes.check}
            indeterminate={true || numSelected > 0 || numSelected === rowCount}
            checked={false}
          />
          {numSelected > 0 ? ` ${numSelected} selected.` : `${0} selected`}
        </span>
        <span className={classes.spanBorder}>
          <AppMenu
            menu_name_or_icon={
              <p>
                More actions <ExpandMoreIcon color="disabled" />
              </p>
            }
            item_names={[
              {
                "Delete Selected": () => "",
              },
            ]}
          />
        </span>
      </label>

      <Typography
        className={classes.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {numSelected > 1
          ? `${numSelected} ${userType}s selected`
          : numSelected === 1
          ? `${numSelected} ${userType} selected`
          : `No ${userType} selected`}
      </Typography>
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
