import React from "react";
import styles from "../index.module.css";

const Avatar = ({ children, ...rest }) => {
  return (
    <div className={styles.avatar} {...rest}>
      {children}
    </div>
  );
};

export default Avatar;
