import { createSlice } from "@reduxjs/toolkit";
import { getRandomColor } from "../../utils/constants";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "nonEnrollees",
  initialState: {
    lists: [],
    nonEnrollee: {},
    loading: false,
  },
  reducers: {
    nonEnrolleeReceived: (state, { payload }) => {
      state.nonEnrollee = payload.data;
      if (!state.nonEnrollee.image_url) {
        state.nonEnrollee.image_color = getRandomColor();
      }

      state.loading = false;
    },
    nonEnrolleeFetchFailed: (state) => {
      state.loading = false;
    },
    nonEnrolleeFetchBegan: (state) => {
      state.loading = true;
    },
  },
});

const { nonEnrolleeReceived, nonEnrolleeFetchBegan, nonEnrolleeFetchFailed } =
  slice.actions;

export default slice.reducer;

const url = "/v1/admin";

export const fetchNonEnrollee = (id) =>
  apiCallBegan({
    url: url + `/non-enrollees/${id}`,
    onSuccess: nonEnrolleeReceived.type,
    onStart: nonEnrolleeFetchBegan.type,
    onError: nonEnrolleeFetchFailed.type,
  });

export const getNonEnrollees = (state) => state.entities.nonEnrollees;
