import React from "react";

const SubTitle = ({ children, style, ...rest }) => {
  return (
    <p
      {...rest}
      style={{
        color: "#525252",
        fontSize: "14px",
        textTransform: "capitalize",
        opacity: "0.5",
        ...style,
      }}
    >
      {children}
    </p>
  );
};

export default SubTitle;
