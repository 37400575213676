import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 250;

export const sideDrawerAppBarStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
        color: "#333333",
    },
    appbarTitle: {
        fontSize: "1.2rem",
        fontWeight: "bolder",
        marginLeft: 10,
    },
    toolbar: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appbarLeft: {
        display: "flex",
        alignItems: "center",
    },
    menuButton: {
        marginLeft: 1,
    },
    hide: {
        display: "none",
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    appBarRight: {
        display: "flex",
        alignItems: "center",
    },
    appBarTitle: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(6) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(8) + 1,
        },
    },
    drawerBar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 0rem",
    },
    drawerBarContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
    },
    drawerBarLogo: {
        marginLeft: 10,
        cursor: "pointer",
    },
    drawerBarLinks: {
        marginTop: 50,
    },
    navList: {
        marginBottom: 20,
    },
    logoutIcon: {
        color: "#E6072C",
    },
}));