import React from "react";
import PropTypes from "prop-types";
import { overviewCardStyles } from "../../styles/graphcardstyle";

const OverviewCard = ({ title, number, text }) => {
  const classes = overviewCardStyles();
  return (
    <div className={classes.root}>
      <section>
        <p className={classes.overviewTitle}>{title}</p>
        <h2 className={classes.overviewTotal}>
          <b>{number}</b>
        </h2>
      </section>
      <section>{text}</section>
    </div>
  );
};

OverviewCard.propTypes = {
  title: PropTypes.any,
  number: PropTypes.any,
  text: PropTypes.any,
};

export default OverviewCard;
