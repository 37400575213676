import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AcceptEnrolleeRequest from "../components/pages/acceptEnrollee";
import DashBoard from "../components/pages/dashboard";
import Enrolees from "../components/pages/enrolees";
import CardRequests from "../components/pages/cardRequest";
import AuthorizationCode from "../components/pages/authorizationCode";
import DrugRefill from "../components/pages/drugRefill";
import Appointment from "../components/pages/appointment";
import ViewEnrollee from "../components/pages/viewEnrollee";
import AdminPrivateRoute from "../components/AdminPrivateRoute";
import NotFound from "../components/NotFound";
import NonEnrollee from "../components/pages/nonEnrollee";

import Settings from "../components/pages/settings";
import Hospitals from "../components/pages/settings/hospital";
import ServiceProvider from "../components/pages/settings/provider";
import Benefits from "../components/pages/settings/benefit";
import Services from "../components/pages/settings/services";

const DashboardRoutes = () => {
  const baseUrl = "/hmo/dashboard";
  return (
    <Switch>
      <AdminPrivateRoute path={baseUrl} exact component={DashBoard} />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/enrollees/view/:tab/:enrollee_id`}
        component={ViewEnrollee}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/enrollees/:activetab`}
        component={Enrolees}
      />
      <AdminPrivateRoute
        path={`${baseUrl}/card-request/:activetab`}
        exact
        component={CardRequests}
      />

      <AdminPrivateRoute
        path={`${baseUrl}/authorization-code/:activetab`}
        exact
        component={AuthorizationCode}
      />

      <AdminPrivateRoute
        path={`${baseUrl}/drug-refill/:activetab`}
        exact
        component={DrugRefill}
      />
      <AdminPrivateRoute
        path={`${baseUrl}/appointment/:activetab`}
        exact
        component={Appointment}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/enrollee/:requestType/accept/:id`}
        component={AcceptEnrolleeRequest}
      />

      <AdminPrivateRoute
        exact
        path={`${baseUrl}/non-enrollees/:id`}
        component={NonEnrollee}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings`}
        component={Settings}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings/benefits`}
        component={Benefits}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings/services`}
        component={Services}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings/providers`}
        component={ServiceProvider}
      />
      <AdminPrivateRoute
        path={`${baseUrl}/settings/hospitals`}
        exact
        component={Hospitals}
      />

      <Redirect
        exact
        from={`${baseUrl}/card-request`}
        to={`${baseUrl}/card-request/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/authorization-code`}
        to={`${baseUrl}/authorization-code/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/appointment`}
        to={`${baseUrl}/appointment/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/drug-refill`}
        to={`${baseUrl}/drug-refill/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/enrollees`}
        to={`${baseUrl}/enrollees/approved`}
      />

      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardRoutes;
