import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "service",
  initialState: {
    servicesList: [],
    loading: false,
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    data: {},
  },
  reducers: {
    fetchedServiceSuccess: (service, action) => {
      service.loading = false;
      service.servicesList = action.payload.data.data;
      service.total = action.payload.data.total;
      service.currentPage = action.payload.data.from;
      service.toPage = action.payload.data.to;
      service.lastPage = action.payload.data.last_page;
    },
    fetchedServiceStart: (service) => {
      service.loading = true;
    },
    fetchedServiceFailed: (service) => {
      service.loading = false;
    },
    createServiceSuccess: (service) => {
      service.loading = false;
    },
    createServiceStart: (service) => {
      service.loading = true;
    },
    createServiceFailed: (service) => {
      service.loading = false;
    },
    deleteServiceSuccess: (service) => {
      service.loading = false;
    },
  },
});

const {
  fetchedServiceSuccess,
  fetchedServiceStart,
  fetchedServiceFailed,
  createServiceSuccess,
  createServiceStart,
  createServiceFailed,
  deleteServiceSuccess,
} = slice.actions;

export default slice.reducer;

const url = "/v2/admin";

export const fetchServices = () =>
  apiCallBegan({
    url: `${url}/services`,
    method: "GET",
    onSuccess: fetchedServiceSuccess.type,
    onStart: fetchedServiceStart.type,
    onError: fetchedServiceFailed.type,
  });
export const createService = (data) =>
  apiCallBegan({
    url: `${url}/services`,
    method: "POST",
    data,
    onSuccess: createServiceSuccess.type,
    onStart: createServiceStart.type,
    onError: createServiceFailed.type,
  });
export const deleteService = (id) =>
  apiCallBegan({
    url: `${url}/services/${id}`,
    method: "DELETE",
    onSuccess: deleteServiceSuccess.type,
    onStart: fetchedServiceStart.type,
    onError: fetchedServiceFailed.type,
  });
export const getService = (state) => state.entities.service;
