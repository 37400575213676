import React from "react";

const Title = ({ children, style, ...rest }) => {
  return (
    <h1
      {...rest}
      style={{
        color: "#525252",
        fontSize: "14px",
        fontWeight: "700",
        textTransform: "capitalize",
        ...style,
      }}
    >
      {children}
    </h1>
  );
};

export default Title;
