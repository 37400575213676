import React from "react";
import { useHistory } from "react-router-dom";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";
import { getDashboardData } from "../../../store/dashboard";

const NewEnroleeTable = () => {
  const history = useHistory();
  let enrolleesData = useSelector(getDashboardData);
  enrolleesData = enrolleesData?.dashboardData?.requests;

  return (
    <TableItem
      tabHeadLabel={["Enrollee Name", "Request Type", "Request Date"]}
      action
      tabBodyData={enrolleesData?.map((item, i) => {
        return (
          <TablesData
            key={i + item?.id}
            enroleeName={item?.name}
            listItem={[item?.request_type, getFormatedDate(item?.created_at)]}
            action={
              <AppMenu
                menu_name_or_icon="View More"
                menuBtnStyle1
                item_names={[
                  {
                    "View Details": () =>
                      history.push(
                        `/hmo/dashboard/enrollees/view/${
                          item?.request_type === "Card Request"
                            ? "card-request"
                            : item?.request_type === "Drug Refill"
                            ? "drug-refill"
                            : item?.request_type === "Appointment"
                            ? "appointments"
                            : "authorization-code"
                        }/${item?.user_id}`
                      ),
                  },
                ]}
              />
            }
          />
        );
      })}
    />
  );
};

export default NewEnroleeTable;
