import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import styles from "./index.module.css";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Box from "@material-ui/core/Box";
import {
  createServiceProvider,
  editServiceProvider,
} from "../../../../store/serviceProvider";
import { getService } from "../../../../store/services";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "330px ",
  height: "560px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  border: "1px solid #fcf9fa",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1);",
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
    "&:hover": {
      background: "#e6072c",
    },
    marginBottom: 15,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function HospitalModal({
  open,
  close,
  title,
  btnText,
  editId,
  editName,
  editLocation,
  editAddress,
  editService,
}) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [service, setService] = useState();
  const [editingName, setEditingName] = useState(editName);
  const [editingLocation, setEditingLocation] = useState(editLocation);
  const [editingAddress, setEditingAddress] = useState(editAddress);
  const [editingService, setEditingService] = useState(editService);
  const dispatch = useDispatch();
  const serviceData = useSelector(getService);
  const [errorMessage, setErrorMessage] = useState("");

  if (!open) return null;

  const serviceType = serviceData.servicesList.map((doc) => {
    return doc.name;
  });

  const doSubmit = async (values) => {
    await dispatch(createServiceProvider(values));
    window.location.reload();
  };

  const editDoSubmit = async (values) => {
    await dispatch(editServiceProvider(values));
    window.location.reload();
  };

  const editSubmit = (e) => {
    e.preventDefault();
    let serviceId = serviceData.servicesList.find((doc) => {
      return doc.name === editingService;
    });
    serviceId = serviceId.id;
    // proper error handling here
    if (
      editingName.trim() === "" ||
      editingLocation.trim() === "" ||
      editingAddress.trim() === ""
    ) {
      setErrorMessage("Please enter a value");
      return;
    }
    editDoSubmit({
      editId,
      name: editingName,
      address: editingAddress,
      location: editingLocation,
      service_name: editingService,
      service_id: serviceId,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let serviceId = serviceData.servicesList.find((doc) => {
      return doc.name === service;
    });
    serviceId = serviceId.id;
    // proper error handling here
    if (
      name.trim() === "" ||
      location.trim() === "" ||
      address.trim() === "" ||
      service.trim() === ""
    ) {
      setErrorMessage("Please enter a value");
      return;
    }
    doSubmit({
      name,
      address,
      location,
      service_name: service,
      service_id: serviceId,
    });
  };

  return (
    <div className={classes.modal}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={classes.modalTop}>
              <div
                style={{
                  color: "#5A5454",
                }}
              ></div>
              <CloseSharpIcon
                color="disabled"
                onClick={close}
                className={classes.closeIcon}
              />
            </div>
            <div className={styles["title"]}>
              <h1>{title}</h1>
            </div>
            <form
              className={styles["form"]}
              onSubmit={
                title === "Add Service Provider" ? handleSubmit : editSubmit
              }
            >
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Service Provider Name</label>
                {title === "Add Service Provider" && (
                  <input
                    onChange={({ target }) => setName(target.value)}
                    value={name}
                    type="text"
                    placeholder="Dolat Hospital"
                  />
                )}
                {editName && (
                  <input
                    onChange={({ target }) => setEditingName(target.value)}
                    value={editingName}
                    type="text"
                    placeholder="Dolat Hospital"
                  />
                )}
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Location</label>
                {title === "Add Service Provider" && (
                  <input
                    onChange={({ target }) => setLocation(target.value)}
                    value={location}
                    type="text"
                    placeholder="Lagos"
                  />
                )}
                {editLocation && (
                  <input
                    onChange={({ target }) => setEditingLocation(target.value)}
                    value={editingLocation}
                    type="text"
                    placeholder="Lagos"
                  />
                )}
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Address Name</label>
                {title === "Add Service Provider" && (
                  <input
                    onChange={({ target }) => setAddress(target.value)}
                    value={address}
                    type="text"
                    placeholder="10, Charleyboy Road"
                  />
                )}
                {editAddress && (
                  <input
                    onChange={({ target }) => setEditingAddress(target.value)}
                    value={editingAddress}
                    type="text"
                    placeholder="10, Charleyboy Road"
                  />
                )}
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Service</label>
                {title === "Add Service Provider" && (
                  <select
                    onChange={({ target }) => setService(target.value)}
                    value={service}
                  >
                    {serviceType.map((doc) => (
                      <option key={doc}>{doc}</option>
                    ))}
                  </select>
                )}
                {editService && (
                  <select
                    onChange={({ target }) => setEditingService(target.value)}
                    value={editingService}
                  >
                    {serviceType.map((doc) => (
                      <option key={doc}>{doc}</option>
                    ))}
                  </select>
                )}
              </div>
              <div>
                <button type="submit" className="button-lg">
                  {btnText}
                </button>
              </div>
              {errorMessage && (
                <div className={styles["error"]}>{errorMessage}</div>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
