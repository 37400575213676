import { Doughnut } from "react-chartjs-2";
import { getDashboardData } from "../../store/dashboard";
import { useSelector } from "react-redux";

// doughnut chart

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    labels: {
      render: "percentage",
      precision: 2,
    },
  },
  //  cutoutPercentage: 88
};

const CardRequestChart = () => {
  const dashboarddata = useSelector(getDashboardData);
  const dashboard = dashboarddata?.dashboardData?.card_requests_occurrence;
  return (
    <Doughnut
      data={{
        labels: ["New Enrollees", "Active Enrollees"],
        datasets: [
          {
            label: "My First Dataset",
            data: [dashboard?.first_time.total, dashboard?.repeated_request.total],
            backgroundColor: ["#46B3E6", "#76DEE4"],
            hoverOffset: 14,
            borderWidth: 4,
          },
        ],
      }}
      options={options}
      width="100%"
      margin="auto"
    />
  );
};

export default CardRequestChart;
