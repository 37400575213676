import React from "react";
import styles from "../index.module.css";

const Paper = ({ children, className, ...rest }) => {
  return (
    <div className={`${styles.paper} ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default Paper;
