import React, { useState, useEffect } from "react";
import TablesData from "../../uis/globalTable";
import TableItem from "../../uis/globalTable/tableItem";
import {
  fetchEnrolleesCardRequest,
  getEnrolleesCardRequest,
} from "../../../store/cardRequest";
import { useDispatch, useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants";

const DUMMY_DATA = [
  {
    appointment_id: 1,
    appointment_date: "4/4/18",
    doctor_name: "John Tunde",
    appointment_channel: "Offline",
    appointment_duration: "9:53:58",
  },
  {
    appointment_id: 2,
    appointment_date: "4/4/18",
    doctor_name: "John Tunde",
    appointment_channel: "Offline",
    appointment_duration: "9:53:58",
  },
];

export default function PatientDetails() {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const cardRequests = useSelector(getEnrolleesCardRequest);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);

  useEffect(() => {
    const fetchEnrollees = async () => {
      dispatch(fetchEnrolleesCardRequest(pageLists, pageNumber));
    };
    fetchEnrollees();
  }, [dispatch, pageNumber, pageLists]);

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchEnrolleesCardRequest(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchEnrolleesCardRequest(pageLists, pageNumber));
  };

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = cardRequests.cardRequestList.filter(
    (searched) => {
      if (search === " ") {
        return searched;
      } else if (
        searched.first_name
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.last_name
          .toLowerCase()
          .includes(search && search.toLowerCase()) ||
        searched.phone_number.includes(search && search.toLowerCase())
      ) {
        return searched;
      } else {
        return null;
      }
    }
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = cardRequests.cardRequestList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <TableItem  
        userType="Patient"
        tabHeadLabel={[
          "Appointment Date",
          "Doctor Name",
          "Appointment Channel",
          "Appointment Duration",
        ]}
        patientTable
        numSelectedItem={selected.length}
        rowCount={cardRequests.cardRequestList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={cardRequests.currentPage}
        toPage={cardRequests.toPage}
        totalPage={cardRequests.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= cardRequests.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={
          // handleSearchAndFilter
          DUMMY_DATA.map((item, i) => {
            const isItemSelected = isSelected(item.id);
            const labelId = `enhanced-table-checkbox-${i}`;
            return (
              <TablesData
                labelId={labelId}
                handleItemClick={(event) => handleClick(event, item.id)}
                aria-checked={isItemSelected}
                isItemSelected={isItemSelected}
                key={i}
                enroleeName={getFormatedDate(
                  item.appointment_date,
                  "YYYY/MMM/DD"
                )}
                listItem={[
                  item?.doctor_name,
                  item?.appointment_channel,
                  item?.appointment_duration,
                ]}
                action
              />
            );
          })
        }
        tablePagination
      />
    </>
  );
}
