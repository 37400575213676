import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import styles from "./index2.module.css";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Box from "@material-ui/core/Box";
import ConfirmDeleteServiceModal from "./confrimDeleteSevice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "547px",
  height: "224px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  border: "1px solid #fcf9fa",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1);",
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
    "&:hover": {
      background: "#e6072c",
    },
    marginBottom: 15,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function DeleteModal({
  open,
  close,
  title,
  btnText1,
  btnText2,
  deleteId,
}) {
  const classes = useStyles();
  const [comfirmDeleteShowModal, setComfirmDeleteShowModal] = useState(false);

  if (!open) return null;

  const confirmDeleteService = () => {
    close();
  };

  const handleUserSelect = () => {
    setComfirmDeleteShowModal(true);
  };

  return (
    <div className={classes.modal}>
      <ConfirmDeleteServiceModal
        title="Type DELETE to delete Service Provider"
        open={comfirmDeleteShowModal}
        close={confirmDeleteService}
        btnText="Delete Service Provider"
        deleteId={deleteId}
      />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={classes.modalTop}>
              <div
                style={{
                  color: "#5A5454",
                }}
              ></div>
              <CloseSharpIcon
                color="disabled"
                onClick={close}
                className={classes.closeIcon}
              />
            </div>
            <div className={styles["title"]}>
              <h1>{title}</h1>
            </div>
            <div className={styles["form"]}>
              <button
                type="submit"
                onClick={handleUserSelect}
                // onClick={() => {
                //   setComfirmDeleteShowModal(true);
                // }}
                className={styles["button-lg"]}
              >
                {btnText1}
              </button>
              <button
                type="submit"
                onClick={confirmDeleteService}
                className={styles["button-lg"]}
              >
                {btnText2}
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
