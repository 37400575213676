import React from "react";
import {
  Avatar,
  TableRow,
  TableCell,
  makeStyles,
  createStyles,
  ListItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { width: "90%" },
    avatar: {
      backgroundColor: "#e6072c",
      marginRight: 10,
      marginTop: 10,
      marginBottom: 10,
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: "#251A6B",
            padding: 0,
          }
        : {
            color: theme.palette.text.primary,
          },

    title: {
      color: theme.palette.text.primary,
    },
    toolLabel: {
      display: "flex",
      alignItems: "center",
      padding: "10px 0px",
    },
    check: {
      padding: 0,
    },
    spanBorder: {
      border: "1px solid #E5E5E5",
      padding: "5px 10px",
      borderRadius: "4px 0px 0px 4px",
      display: "flex",
      alignItems: "center",
      margin: 0,
    },
  })
);

const TablesData = ({
  checkBoxes,
  enroleeName,
  enroleeId,
  image,
  imageUrl,
  imgInitials,
  action,
  listItem,
  pat_status,
  pat_status_icon,
  handleItemClick,
  isItemSelected,
  labelId,
}) => {
  const classes = useStyles();
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={enroleeId}
      selected={isItemSelected}
    >
      {checkBoxes && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={handleItemClick}
          />
        </TableCell>
      )}

      {
        <TableCell padding="checkbox" style={{ whiteSpace: "nowrap" }}>
          <ListItem>
            {" "}
            {pat_status && <span className={pat_status_icon}></span>}
            {image && (
              <Avatar
                aria-label="avatar"
                className={classes.avatar}
                src={imageUrl && imageUrl}
                // alt="img"
              >
                {imgInitials && imgInitials}
              </Avatar>
            )}
            <div>
              {enroleeName && (
                <ListItemText primary={`${enroleeName && enroleeName}`} />
              )}
              {enroleeId && (
                <ListItemText
                  secondary={`${enroleeId && enroleeId}`}
                  style={{ color: "#808080" }}
                />
              )}
            </div>
          </ListItem>
        </TableCell>
      }

      {listItem.map((item, i) => {
        return (
          <TableCell
            key={i}
            align="left"
            style={{
              whiteSpace: "nowrap",
              padding: "0px 1.5px",
            }}
          >
            {item ? item : "Not Provided"}
          </TableCell>
        );
      })}

      {action && <TableCell align="right">{action}</TableCell>}
    </TableRow>
  );
};

export default TablesData;
