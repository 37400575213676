import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import { fetchUsers, getUsers } from "../../../store/user";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";

export default function UsersTable() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const usersData = useSelector(getUsers);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);

  useEffect(() => {
    const fetchActiveUsers = async () => {
      await dispatch(fetchUsers(pageLists, pageNumber));
    };
    fetchActiveUsers();
  }, [dispatch, pageNumber, pageLists]);

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchUsers(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchUsers(pageLists, pageNumber));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = usersData.activeUsersList.filter((searched) => {
    if (search === " ") {
      return searched;
    } else if (
      searched.first_name
        .toLowerCase()
        .includes(search && search.toLowerCase()) ||
      searched.last_name
        .toLowerCase()
        .includes(search && search.toLowerCase()) ||
      searched.phone_number.includes(search && search.toLowerCase())
    ) {
      return searched;
    } else {
      return null;
    }
  });

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = usersData.activeUsersList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
      >
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={usersData.loading} />
      <TableItem
        userType="User"
        checkBoxes
        tabHeadLabel={["User", "Email Address", "Phone Number"]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={usersData.activeUsersList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={usersData.currentPage}
        toPage={usersData.toPage}
        totalPage={usersData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= usersData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              image
              imageUrl={item?.enrolee_photo}
              imgInitials={
                item?.first_name.slice(0, 1) + item?.last_name.slice(0, 1)
              }
              enroleeName={item.first_name + " " + item.last_name}
              listItem={[item.email, item.phone_number]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "View More": () =>
                        history.push(
                          `/wellness-on-the-go/users/view/user-details/${item.id}`
                        ),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
