import React from "react";
import { Link } from "react-router-dom";

const settingsCardData = [
  {
    icon: "/assets/settings-services.jpg",
    header: "Services",
    text: "View , Add and Delete Services on the system",
    settings_url: "/hmo/dashboard/settings/services",
  },
  {
    icon: "/assets/settings-provider.jpg",
    header: "Service Providers",
    text: "View , Add and Delete Service providers on the system",
    settings_url: "/hmo/dashboard/settings/providers",
  },
  {
    icon: "/assets/settings-benefit.jpg",
    header: "Benefit",
    text: "View , Add and Delete Benefits on the system",
    settings_url: "/hmo/dashboard/settings/benefits",
  },
  {
    icon: "/assets/settings-hospital.jpg",
    header: "Hospitals",
    text: "View , Add and Delete Hospitals on the system",
    settings_url: "/hmo/dashboard/settings/hospitals",
  },
];

const SettingsCard = ({ icon, header, text, settings_url }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        background: "#FFFFFF",
        padding: "2.375rem 1.25rem",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
      }}
    >
      <img
        src={icon}
        alt={icon}
        style={{ marginRight: "1rem", objectFit: "contain" }}
      />
      <div>
        <h3 style={{ fontWeight: "bold" }}>{header}</h3>
        <p
          style={{
            marginTop: "10px",
          }}
        >
          {text}
        </p>
        <div
          style={{
            marginTop: "18px",
          }}
        >
          <Link
            to={settings_url}
            style={{
              color: "#525252",
              background: "#F5E9EB",
              padding: "6px 12px",
              borderRadius: "7px",
            }}
          >
            View Details
          </Link>
        </div>
      </div>
    </div>
  );
};

const Settings = () => {
  return (
    <div style={{ padding: "2.625rem" }}>
      <h3 style={{ fontSize: "1.5rem", fontWeight: 800 }}>Settings</h3>
      <div
        style={{
          marginTop: "3.25rem",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
          columnGap: "3.25rem",
          rowGap: "2.25rem",
        }}
      >
        {settingsCardData.map((item, index) => {
          return (
            <SettingsCard
              key={index}
              icon={item.icon}
              header={item.header}
              text={item.text}
              settings_url={item.settings_url}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Settings;
