import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { EnhancedTableToolbar } from "./tableToolBar";
import EnhancedTableHead from "./tableHead";
import EnhancedTablePagination from "./tablePaginate";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    "& th": {
      backgroundColor: "#F5E9EB",
      color: "#E6072C",
      paddingTop: "15px",
      paddingBottom: "15px",
      fontWeight: "bolder",
      whiteSpace: "nowrap",
    },
  },
  patientTable: {
    "& th": {
      color: "#E6072C",
      paddingTop: "15px",
      paddingBottom: "15px",
      fontWeight: "bolder",
      whiteSpace: "nowrap",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function TableItem({
  tableSize,
  tabBodyData,
  tabToolBar,
  userType,
  tabHeadLabel,
  tabHeadCheck,
  tablePagination,
  modal,
  numSelectedItem,
  onSelectAllClick,
  numSelected,
  rowCount,
  currentPage,
  totalPage,
  toPage,
  handleIncrement,
  handleDecrement,
  disabledIncrement,
  disabledDecrement,
  pageSelectValue,
  handleSelectPageNumber,
  patientTable,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {modal && modal}
      <TableContainer className={classes.paper}>
        <Table
          className={!patientTable ? classes.table : classes.patientTable}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            numSelected={numSelected}
            onSelectAllClick={onSelectAllClick}
            rowCount={rowCount}
            headCells={tabHeadLabel.map((tab) => tab)}
            headCheck={tabHeadCheck && tabHeadCheck}
          />
          <TableBody>
            {tabToolBar && (
              <TableRow>
                <TableCell colSpan={20}>
                  <EnhancedTableToolbar
                    numSelected={numSelectedItem}
                    userType={userType}
                  />
                </TableCell>
              </TableRow>
            )}
            {tabBodyData?.length === 0 ? (
              <TableRow
                rowSpan={10}
                style={{
                  borderTop: "2px solid transparent",
                  borderBottom: "2px solid transparent",
                  height: "50vh",
                }}>
                <TableCell colSpan={10}>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItem: "center",
                    }}>
                    {/* <img src="/assets/images/notfound.png" alt="not found" style={{ width: "100%" }} /> */}
                    <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
                      You can see a list of all the Active Patients in your
                      organisation here
                    </h2>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              tabBodyData
            )}
          </TableBody>
        </Table>
        {tabBodyData?.length === 0
          ? ""
          : tablePagination && (
              <EnhancedTablePagination
                currentPage={currentPage}
                totalPage={totalPage}
                toPage={toPage}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                disabledIncrement={disabledIncrement}
                disabledDecrement={disabledDecrement}
                handleChange={handleSelectPageNumber}
                value={pageSelectValue}
              />
            )}
      </TableContainer>
    </div>
  );
}
