import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getAuth, loginAdmin } from "../../../store/auth";
import Loader from "../../common/Loader";
import styles from "./index.module.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector(getAuth);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history]);

  const doSubmit = async (values) => {
    await dispatch(loginAdmin(values));
    window.location.reload();
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // proper error handling here
    if (email.trim() === "" || password.trim() === "") {
      setErrorMessage("Please enter a valid email and password");
      return;
    }
    /**
     * this is a basic setup
     * not tested with a real backend
     */
    doSubmit({ email, password });
  };

  return (
    <div className={styles["login-screen"]}>
      <Loader visible={loading} />
      <div className="mb-5">
        <img src="/assets/Wellness-logo.png" alt="wellness" />
      </div>
      <div className={styles["login-container"]}>
        <div className={styles["title"]}>
          <h1>Log into your account</h1>
          <p>
            Input your Email address and password to <br />
            Login into your account
          </p>
        </div>

        <form className={styles["form"]} onSubmit={handleLogin}>
          <div className={styles["form-control"]}>
            <label htmlFor="email">Email Address</label>
            <input
              onChange={({ target }) => setEmail(target.value)}
              value={email}
              type="email"
              id="email"
              name="email"
              placeholder="admin@mail.com"
            />
          </div>
          <div className={styles["form-control"]}>
            <label htmlFor="password">Password</label>
            <input
              onChange={({ target }) => setPassword(target.value)}
              value={password}
              type="password"
              id="password"
              name="password"
              placeholder="password"
            />
          </div>
          <div>
            <button type="submit" className="button-lg">
              Login
            </button>
          </div>
          {errorMessage && (
            <div className="mt-5 text-red-dark">{errorMessage}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
