import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FiMoreHorizontal } from "react-icons/fi";
import AppMenu from "../../menu";
import { useSelector } from "react-redux";
import { getEnrollees } from "../../../store/enrollee";
import { getFormatedDate } from "../../../utils/constants";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    "& th": {
      color: "#E6072C",
    },
  },

  pending: {
    color: "#FFA800",
    backgroundColor: "#FDF7EB",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
  approved: {
    color: "#25B273",
    backgroundColor: "#E3F9F1",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
  declined: {
    color: "#8B8888",
    backgroundColor: "rgba(139, 136, 136, 0.1)",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
});

const CardRequest = ({ onShowCardDetails }) => {
  const classes = useStyles();

  const enrollees = useSelector(getEnrollees);
  const cardRequestHistory = enrollees.data
    ? enrollees?.data?.card_requests_history
    : [];

  return cardRequestHistory?.length === 0 ? (
    <div className="text-center font-bold">No Card History</div>
  ) : (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell>Request Date</TableCell>
          <TableCell align="center">Card Collected</TableCell>
          <TableCell align="center">Request Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {cardRequestHistory?.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {getFormatedDate(row.created_at)}
            </TableCell>
            <TableCell align="center">
              {row.card_collected ? "Yes" : "No"}
            </TableCell>
            <TableCell align="center">
              <span className={classes[row.status]}>{row.status}</span>
            </TableCell>
            <TableCell align="center">
              <AppMenu
                menu_name_or_icon={
                  <button>
                    <FiMoreHorizontal size="1.3em" color="#A8A8A8" />
                  </button>
                }
                item_names={[
                  {
                    "View Details": () =>
                      onShowCardDetails(true, row.id, "card-request"),
                  },
                ]}
              />
              {/* <button>
              </button> */}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CardRequest;
