import React from "react";
import { Bar } from "react-chartjs-2";

const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        borderDash: [4, 2],
        color: "#D5D5D5",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

const HighestPayingCategory = ({ dataFigs }) => {
  return (
    <Bar
      data={{
        labels: [
          ["Omodayo", "Olufunke"],
          ["Kunle", "Afolabi"],
          ["Ayo", "Balogun"],
          ["Obi", "Chioma"],
          ["Chigozie ", "Ndife"],
        ],
        datasets: [
          {
            backgroundColor: "#F1C443",
            boxShadow: "0px 0px 12px #F2F4F9",
            borderColor: "transparent",
            hoverBackgroundColor: "#F1C443",
            hoverBorderColor: "#F1C443",
            data: dataFigs,
            borderWidth: 15,
          },
        ],
      }}
      options={options}
      width="100%"
      margin="auto"
    />
  );
};

export default HighestPayingCategory;
