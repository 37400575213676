import { Doughnut } from "react-chartjs-2";
import { getDashboardData } from "../../store/dashboard";
import { useSelector } from "react-redux";

// doughnut chart
const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    labels: {
      render: "percentage",
      precision: 2,
    },
  },
};

const TopRequests = () => {
  const dashboarddata = useSelector(getDashboardData);
  const dashboard = dashboarddata?.dashboardData?.top_appointments;
  return (
    <Doughnut
      data={{
        labels: ["Telemedicine", "Doctor Requests"],
        datasets: [
          {
            label: "My First Dataset",
            data: dashboard?.map((d) => d?.top_appointments),
            backgroundColor: ["#ACC9F7", "#46B3E6", "#76DEE4", "#4D80E4"],
            hoverOffset: 14,
            borderWidth: 4,
          },
        ],
      }}
      options={options}
      width="100%"
      margin="auto"
    />
  );
};

export default TopRequests;
