import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "basic-health-care",
  initialState: {
    activeBasicHealthCareRequestList: [],
    pendingBasicHealthCareRequestList: [],
    total: null,
    currentPage: null,
    toPage: null,
    lastPage: null,
    loading: false,
  },
  reducers: {
    fetchedBasicHealthCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.activeBasicHealthCareRequestList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedPendingBasicHealthCareRequestSuccess: (user, action) => {
      user.loading = false;
      user.pendingBasicHealthCareRequestList = action.payload.data.data;
      user.total = action.payload.data.total;
      user.currentPage = action.payload.data.from;
      user.toPage = action.payload.data.to;
      user.lastPage = action.payload.data.last_page;
    },
    fetchedBasicHealthCareRequestStart: (user, action) => {
      user.loading = true;
    },
    fetchedBasicHealthCareRequestFailed: (user, action) => {
      user.loading = false;
    },
  },
});

const {
  fetchedBasicHealthCareRequestSuccess,
  fetchedPendingBasicHealthCareRequestSuccess,
  fetchedBasicHealthCareRequestStart,
  fetchedBasicHealthCareRequestFailed,
} = slice.actions;

export default slice.reducer;
const url = "/v2/admin/appointments/basic";

export const fetchBasicHealthCareUsersRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/approved?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedBasicHealthCareRequestSuccess.type,
    onStart: fetchedBasicHealthCareRequestStart.type,
    onError: fetchedBasicHealthCareRequestFailed.type,
  });

export const fetchBasicHealthCareUserPendingRequest = (pageItems, page) =>
  apiCallBegan({
    url: `${url}/pending?page_items=${pageItems}&page=${page}`,
    method: "GET",
    onSuccess: fetchedPendingBasicHealthCareRequestSuccess.type,
    onStart: fetchedBasicHealthCareRequestStart.type,
    onError: fetchedBasicHealthCareRequestFailed.type,
  });

export const getBasicHealthCareUsers = (state) =>
  state.entities.basicHealthCareUsers;

export const getPendingBasicHealthCareUsers = (state) =>
  state.entities.basicHealthCareUsers.pendingBasicHealthCareUsersList;

export const getPendingBasicHealthCareUserById = (state) => (id) =>
  state.entities.basicHealthCareUsers.pendingBasicHealthCareUsersList.find(
    (e) => e.id === id
  );
