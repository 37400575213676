import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@material-ui/core";
// icons
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AppMenu from "../menu";
import { sideDrawerAppBarStyles } from "../styles/side-nav-bar";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth";

export const SideBarLinks = ({ url, name, icon, onClick, activeColor }) => {
  const classes = sideDrawerAppBarStyles();
  const location = useLocation();

  const getActive = (currentLocation) => {
    if (
      location.pathname === currentLocation ||
      location.pathname === `${currentLocation}/approved` ||
      location.pathname === `${currentLocation}/pending` ||
      location.pathname === `${currentLocation}/declined` ||
      location.pathname === `${currentLocation}/non-enrollee` ||
      location.pathname === `${currentLocation}/declined-enrollee` ||
      location.pathname === `${currentLocation}/active` ||
      location.pathname === `${currentLocation}/deleted`
    ) {
      return {
        backgroundColor: "#E6072C",
        color: "#fff",
      };
    }
  };
  return (
    <ul>
      <li className={classes.navList} onClick={onClick} style={getActive(url)}>
        <Link to={url}>
          <ListItem button>
            <ListItemIcon style={getActive(url)}>{icon}</ListItemIcon>
            <ListItemText> {name}</ListItemText>
          </ListItem>
        </Link>
      </li>
    </ul>
  );
};

SideBarLinks.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
};

const SideBar = ({
  appBarTitle,
  iconButton,
  drawerSection,
  noFullAppBar,
  logoOnClose,
  sideBarOptions,
  settings,
  settings_url,
}) => {
  const classes = sideDrawerAppBarStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(true);

  const adminLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: noFullAppBar && open,
        })}>
        <Toolbar className={classes.toolbar}>
          {/* app bar left */}
          <div className={classes.appbarLeft}>
            {iconButton && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}>
                <MenuIcon color="disabled" />
              </IconButton>
            )}

            <Avatar
              src="https://res.cloudinary.com/dycyotaya/image/upload/v1629978378/Wellness-Logo_jpdtgr.png"
              alt="WS"
              className={clsx(classes.small, {
                [classes.hide]: logoOnClose && open,
              })}
            />
          </div>

          {/* app bar center */}
          <label className={classes.appBarTitle}>
            <h2 className={classes.appbarTitle}>{appBarTitle}</h2>
          </label>
          {/* app bar right */}
          <div className={classes.appBarRight}>
            <NotificationsNoneIcon
              color="disabled"
              style={{ marginRight: "1rem" }}
            />
            {settings && (
              <img
                style={{ marginRight: "1rem" }}
                src="/assets/settings.png"
                alt="settings"
                onClick={() => history.push(settings_url)}
              />
            )}
            <Avatar
              className={classes.small}
              src="https://res.cloudinary.com/dycyotaya/image/upload/v1629366830/IMG_20210705_144144_255_pbxn8l.jpg"
              alt="SA"
            />
            <AppMenu
              menu_name_or_icon={<ArrowDropDownIcon color="disabled" />}
              item_names={[
                {
                  Logout: () => adminLogout(),
                },
              ]}
            />
          </div>
        </Toolbar>
      </AppBar>
      {drawerSection && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}>
          <div className={classes.drawerBar}>
            <Avatar
              onClick={() => history.push("/")}
              src="https://res.cloudinary.com/dycyotaya/image/upload/v1629978378/Wellness-Logo_jpdtgr.png"
              alt="WS"
              className={classes.drawerBarLogo}
            />
            <IconButton onClick={() => setOpen(false)}>
              <MenuOpenIcon color="disabled" />
            </IconButton>
          </div>

          <div className={classes.drawerBarContainer}>
            <div className={classes.drawerBarLinks}>{sideBarOptions}</div>

            {/* logout */}
            <div>
              <SideBarLinks
                name="Logout"
                url=""
                icon={<ExitToAppIcon className={classes.logoutIcon} />}
                onClick={() => adminLogout()}
              />
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default SideBar;
