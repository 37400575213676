import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: "45vh",
  },
  indicator: {
    left: 0,
    backgroundColor: "#F1C443",
    borderRadius: "50px",
    width: "4px",
  },
  tabPanel: {
    width: "100%",
    backgroundColor: "red",
  },
  tabText: {
    textTransform: "capitalize",
    marginLeft: "0px",
    textAlign: "left",
  },
  tabTextActive: {
    color: "#2re51A6B",
  },
  rightAlign: {
    marginLeft: "auto",
  },
}));

const VerticalTabs = ({ tabIndex, onTabChange, tabData }) => {
  const classes = useStyles();
  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={tabIndex}
      onChange={onTabChange}
      className={classes.tabs}
      classes={{
        indicator: classes.indicator,
      }}
      centered={false}
    >
      {tabData.map((tab, index) => (
        <Tab
          className={classes.tabText}
          classes={{
            selected: classes.tabTextActive,
          }}
          key={index}
          label={<p>{tab}</p>}
        />
      ))}
    </Tabs>
  );
};

export default VerticalTabs;
