import React from "react";
import { containerStyle } from "../../styles/styles";
import { TableStyles } from "../../styles/table";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import UsersTable from "../../uis/usersTables";

const Users = () => {
  const classes = containerStyle();
  const tabClasses = TableStyles();

  return (
    <>
      <div className={tabClasses.filterContainer}>
        <p>
          <LocalBarIcon /> Filter by:
        </p>
      </div>
      <div className={classes.main}>
        <UsersTable />
      </div>
    </>
  );
};

export default Users;
