import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/api";

let slice = createSlice({
  name: "admin",
  initialState: {
    admin: null,
    loading: false,
    isAuthenticated: !!localStorage.token,
  },
  reducers: {
    adminLoginSuccess: (adminData, { payload }) => {
      adminData.loading = false;
      adminData.admin = payload.payload;
      adminData.isAuthenticated = true;
      // set local storage here
      localStorage.setItem("token", payload.token);
    },

    adminLoginStart: (adminData, action) => {
      adminData.loading = true;
    },
    adminLoginFailed: (adminData, action) => {
      adminData.loading = false;
    },
    adminLogout: (adminData, action) => {
      adminData.admin = null;
      adminData.isAuthenticated = false;
      localStorage.removeItem("token");
    },

    forgotPassword: (adminData, action) => {
      adminData.loading = false;
    },

    resetCodeSuccess: (adminData, action) => {
      adminData.loading = false;
    },

    updatePasswordSuccess: (adminData, action) => {
      adminData.loading = false;
    },

    adminResetPasswordStart: (adminData, action) => {
      adminData.loading = true;
    },

    adminResetPasswordFailed: (adminData, action) => {
      adminData.loading = false;
    },
  },
});

const {
  adminLoginSuccess,
  adminLoginStart,
  adminLoginFailed,
  adminLogout,
  forgotPassword,
  resetCodeSuccess,
  updatePasswordSuccess,
  adminResetPasswordStart,
  adminResetPasswordFailed,
} = slice.actions;

export default slice.reducer;

export const logout = () => (dispatch) => dispatch({ type: adminLogout.type });

export const loginAdmin = (data) =>
  apiCallBegan({
    url: "/v1/admin/login",
    method: "POST",
    data,
    onSuccess: adminLoginSuccess.type,
    onStart: adminLoginStart.type,
    onError: adminLoginFailed.type,
  });

export const resetPasswordAdmin = (email) =>
  apiCallBegan(
    {
      url: `/v1/forgot-pwd?email=${email}`,
      method: "POST",
      onSuccess: forgotPassword.type,
      onStart: adminResetPasswordStart.type,
      onError: adminResetPasswordFailed.type,
    },
    localStorage.setItem("admin_email", email)
  );

export const resetPasswordCode = (reset_code) =>
  apiCallBegan({
    url: `/v1/verify-reset-pwd-code`,
    method: "POST",
    params: {
      reset_code,
      email: localStorage.getItem("admin_email"),
    },
    onSuccess: resetCodeSuccess.type,
    onStart: adminResetPasswordStart.type,
    onError: adminResetPasswordFailed.type,
  });

export const updatePassword = (password) =>
  apiCallBegan({
    url: `/v1/update-pwd`,
    method: "POST",
    params: {
      password,
      email: localStorage.getItem("admin_email"),
    },
    onSuccess: updatePasswordSuccess.type,
    onStart: adminResetPasswordStart.type,
    onError: adminResetPasswordFailed.type,
  });

export const getAdmin = (state) => state.auth.admin;

export const getAuth = (state) => state.auth;
