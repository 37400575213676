import React from "react";
import PropTypes from "prop-types";
import { TableHead, TableCell, TableRow, Checkbox } from "@material-ui/core";

export default function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount, headCells, headCheck }) {
  return (
    <TableHead>
      <TableRow>
        {headCheck && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}

        {headCells.map((headCell, i) => (
          <TableCell key={i} align={"left"} padding={"normal"}>
            {headCell}
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  rowCount: PropTypes.number,
};
