import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { TableStyles } from "../../styles/table";

export const SearchOnly = ({ handleSearch, searchWord }) => {
  const classes = TableStyles();

  return (
    <label className={classes.searchBar}>
      <SearchIcon color="disabled" />

      <input
        type="text"
        placeholder="Search Enrollees"
        className={classes.searchInput}
        onChange={handleSearch}
        name="search"
        value={searchWord}
      />
    </label>
  );
};

const filterOptions = [
  { name: "All", value: "all" },
  { name: "Active", value: "active" },
  { name: "Inactive", value: "inactive" },
];

export const FilterOnly = ({ handleFilter, filterValue }) => {
  const classes = TableStyles();

  return (
    <select className={classes.filter} value={filterValue} onChange={handleFilter}>
      {filterOptions.map((item, i) => {
        return <option key={i}>{item.name}</option>;
      })}
    </select>
  );
};
