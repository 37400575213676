import { Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import SubTitle from "../Typography/subTitle";
import styles from "../index.module.css";

const StyledTabs = withStyles((theme) => ({
  root: {
    padding: "0 40px",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 80,
      width: "100%",
      backgroundColor: "#251A6B",
    },
  },
}))((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className /> }} />
));

const CustomTabs = ({
  tabIndex = 0,
  onTabChange,
  tabData,
  activeTab,
  disableTabs,
}) => {
  const allProps = (index) => ({
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  });
  return (
    <StyledTabs
      value={tabIndex}
      onChange={(tab, index) => onTabChange(index)}
      variant="fullWidth"
    >
      {tabData.map((tab, index) => (
        <Tab
          disabled={tab !== activeTab ? disableTabs : false}
          className={styles.tab}
          key={tab + index}
          label={<SubTitle>{tab}</SubTitle>}
          {...allProps(index)}
        />
      ))}
    </StyledTabs>
  );
};

export default CustomTabs;
