import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Title from "../../common/Typography/title";
import { BiArrowBack } from "react-icons/bi";
import Tabs from "../../common/Tabs";
import WellnessOnTheGoPanel from "../../common/Panel/wellnssOnTheGo";
import UserDetails from "../widget/userDetails";
import DentalCare from "../widget/dentalCare";
import OpticalCare from "../widget/opticalCare";
import HealthCare from "../widget/healthCare";
import EmploymentCheck from "../widget/employmentCheck";
import CancerCare from "../widget/cancerCare";
import BasicHealthCare from "../widget/basicHealthCare";

import DentalCareRequestDetails from "../components/dentalCareDetails";
import OpticalCareRequestDetails from "../components/opticalCareDetails";
import HealthCareRequestDetails from "../components/healthCareDetails";
import CancerCareDetails from "../components/cancerCareDetails";
import BasicHealthCareDetails from "../components/basicHealthCareDetails";
import EmploymentCheckDetails from "../components/employmentCheckDetails";

import {
  fetchUser,
  getUsers,
  fetchUserServiceDetails,
} from "../../../store/user";
import Loader from "../../common/Loader";
import styles from "./index.module.css";

const tabData = [
  "User Details",
  "Dental Care",
  "Optical Care",
  "Comprehensive",
  "Cancer Care",
  "Basic Health Care",
  "Pre-Employment",
];

const ViewDetails = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState("User Details");
  const [DetailsComponent, setDetailsComponent] = useState(null);
  const [showRequestPanel, setShowRequestPanel] = useState(false);
  const [detailId, setDetailId] = useState(null);
  const [currentDetail, setCurrentDetail] = useState({});

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector(getUsers);

  const updateTab = (index, component = null, data) => {
    setTabIndex(index);
    setCurrentTab(tabData[index]);
    setDetailsComponent(component);
    setCurrentDetail(data);
  };

  const getHistoryData = useCallback(
    (field) => {
      return (
        users?.data &&
        users?.data[field]?.find((history) => history?.id === detailId)
      );
    },
    [detailId, users]
  );

  useEffect(() => {
    const getUser = async () => {
      await dispatch(fetchUser(params?.user_id));
    };
    getUser();
  }, [dispatch, params]);

  useEffect(() => {
    if (!users?.data) history.push("/wellness-on-the-go/users");
    setCurrentUser(users?.data);
  }, [params, history, dispatch, users]);

  useEffect(() => {
    switch (params.tab) {
      case "user-details":
        updateTab(0, null, {});
        break;
      case "dental-care":
        const currentDentalCareRequest = getHistoryData("dental_appointments");
        updateTab(
          1,
          <DentalCareRequestDetails details={currentDentalCareRequest} />,
          currentDentalCareRequest
        );
        break;
      case "optical-care":
        const currentOpticalCareDetails = getHistoryData(
          "optical_appointments"
        );
        updateTab(
          2,
          <OpticalCareRequestDetails details={currentOpticalCareDetails} />,
          currentOpticalCareDetails
        );
        break;
      case "comprehensive":
        const currentComprehensiveHealthCare = getHistoryData(
          "comprehensive_appointments"
        );
        updateTab(
          3,
          <HealthCareRequestDetails details={currentComprehensiveHealthCare} />,
          currentComprehensiveHealthCare
        );
        break;
      case "cancer-care":
        const currentCancerCare = getHistoryData("cancer_appointments");
        updateTab(
          4,
          <CancerCareDetails details={currentCancerCare} />,
          currentCancerCare
        );
        break;
      case "basic-health-care":
        const currentBasicHealthCare = getHistoryData(
          "basic_health_check_appointments"
        );
        updateTab(
          5,
          <BasicHealthCareDetails details={currentBasicHealthCare} />,
          currentBasicHealthCare
        );
        break;
      case "pre-employment":
        const currentEmploymentCheck = getHistoryData(
          "pre_employment_appointments"
        );
        updateTab(
          6,
          <EmploymentCheckDetails details={currentEmploymentCheck} />,
          currentEmploymentCheck
        );
        break;
      default:
        updateTab(0, null, {});
        break;
    }
  }, [params, currentUser, detailId, getHistoryData]);

  useEffect(() => {
    if (currentDetail && currentDetail?.status === "pending") {
      history.push({
        pathname: `/wellness-on-the-go/user/${params.tab}/accept/${params.id}`,
        state: { detailId: detailId },
      });
    }
  }, [currentDetail, history, params, detailId]);

  const handleShowDetails = (status, id, url) => {
    setShowRequestPanel(status);
    setDetailId(id);
    dispatch(fetchUserServiceDetails(localStorage.getItem("details_id")));
    history.push({
      pathname: `/wellness-on-the-go/users/view/${url}/${params.user_id}`,
      state: { detailId: id },
    });
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.container}>
      <Loader visible={users?.loading} />
      <div className="flex items-center  justify-between mb-5">
        <div className="flex items-center">
          <button className="btn-round-small" onClick={() => history.goBack()}>
            <BiArrowBack size="1.2em" />
          </button>
          <Title style={{ fontSize: "16px" }}>Back</Title>
        </div>
        <div></div>
      </div>

      {showRequestPanel && DetailsComponent}
      <WellnessOnTheGoPanel />

      <div style={{ margin: "2rem 0" }}>
        <Paper>
          <Tabs
            activeTab={currentTab}
            tabData={tabData}
            tabIndex={tabIndex}
            onTabChange={(index) => setTabIndex(index)}
          />

          <div className={styles["tab-panel-body"]}>
            <TabPanel value={0} index={tabIndex}>
              <UserDetails />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <DentalCare onShowDentalCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={2} index={tabIndex}>
              <OpticalCare onShowOpticalCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={3} index={tabIndex}>
              <HealthCare onShowHealthCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={4} index={tabIndex}>
              <CancerCare onShowCancerCareDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={5} index={tabIndex}>
              <BasicHealthCare
                onShowBasicHealthCareDetails={handleShowDetails}
              />
            </TabPanel>
            <TabPanel value={6} index={tabIndex}>
              <EmploymentCheck
                onShowEmploymentCheckDetails={handleShowDetails}
              />
            </TabPanel>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ViewDetails;
