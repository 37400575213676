import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminPrivateRoute from "../components/AdminPrivateRoute";
import TelemedicinePatient from "../components/pages/telemedicinePatient";
import TelemedicineDoctor from "../components/pages/telemedicineDoctor";
import TelemedicineTransaction from "../components/pages/telemedicineTransaction";
import TelemedicineTelemedicine from "../components/pages/telemedicineTelemedicine";
import ViewPatients from "../components/pages/viewPatient";
import ViewDoctor from "../components/pages/viewDoctor";
import NotFound from "../components/NotFound";
import WellnessDoctorDashBoard from "../components/pages/wellnessDoctorDashboard";

const DashboardDoctorRoutes = () => {
  const baseUrl = "/wellness-doctor";
  return (
    <Switch>
      <AdminPrivateRoute
        path={baseUrl}
        exact
        component={WellnessDoctorDashBoard}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/patients`}
        component={TelemedicinePatient}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/patients/:patient_id`}
        component={ViewPatients}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/doctors/:activetab`}
        component={TelemedicineDoctor}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/doctors/:activetab/:doctor_id`}
        component={ViewDoctor}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/telemedicine`}
        component={TelemedicineTelemedicine}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/transactions`}
        component={TelemedicineTransaction}
      />
      <Redirect
        exact
        from={`${baseUrl}/doctors`}
        to={`${baseUrl}/doctors/approved`}
      />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardDoctorRoutes;
