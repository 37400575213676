import React from "react";
import PropTypes from "prop-types";
import { graphCardStyles } from "../../styles/graphcardstyle";

const GraphCard = ({ title, graph, menu, menuBtnStyle }) => {
  const classes = graphCardStyles();
  return (
    <div className={classes.graphCard}>
      <div className={classes.graphCardHeader}>
        <p className={classes.graphTitle}>{title}</p>
        <div>
          {menu?.map((men, index) => {
            return (
              <button key={index} className={menuBtnStyle}>
                {men}
              </button>
            );
          })}
        </div>
      </div>
      <div className={classes.graphContainer}>{graph}</div>
    </div>
  );
};

GraphCard.propTypes = {
  title: PropTypes.any.isRequired,
  graph: PropTypes.node,
  menu: PropTypes.any,
  menuBtnStyle: PropTypes.any,
};

export default GraphCard;
