import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Paper from "../../common/Paper";
import Title from "../../common/Typography/title";
import { BiArrowBack } from "react-icons/bi";
import WellnessDoctorPanel from "../../common/Panel/wellnessDoctorPanel";
import DoctorDetails from "../widget/doctorDetails";
import Loader from "../../common/Loader";
import styles from "./index.module.css";
import { getEnrollees } from "../../../store/enrollee";

const ViewDoctor = () => {
  const history = useHistory();
  const enrollees = useSelector(getEnrollees);

  return (
    <div className={styles.container}>
      <Loader visible={enrollees.loading} />
      <div className="flex items-center  justify-between mb-5">
        <div className="flex items-center">
          <button className="btn-round-small" onClick={() => history.goBack()}>
            <BiArrowBack size="1.2em" />
          </button>
          <Title style={{ fontSize: "16px" }}>Back</Title>
        </div>
        <div></div>
      </div>
      <WellnessDoctorPanel />
      <div style={{ margin: "2rem 0" }}>
        <Paper>
          <DoctorDetails />
        </Paper>
      </div>
    </div>
  );
};

export default ViewDoctor;
