import React, { useEffect } from "react";
import { containerStyle } from "../../styles/styles";
import { graphCardStyles } from "../../styles/graphcardstyle";
import GraphCard from "../../uis/graphscard";
import OverviewCard from "../../uis/graphscard/overviewCard";
import AppMenu from "../../menu";
import HighestPayingCategory from "../../graphs/highestPayingCategoryChart";
import TopRequests from "../../graphs/telemedicineTopRequest";
import UserRequests from "../../uis/enroleesTable/userRequests";
import DashboardLayout from "../../uis/dashboardLayout";
// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  fetchWellnessOnTheGoDashboardData,
  getDashboardData,
} from "../../../store/dashboard";
import { useDispatch, useSelector } from "react-redux";

const TopLeftDashboard = ({
  totalUsers,
  totalPendingAppointment,
  totalAppointment,
}) => {
  return (
    <>
      <OverviewCard
        title="Total Requests"
        number={"31, 240"}
        text="YTD"
        // number={totalUsers}
      />
      <OverviewCard
        title="Total Doctors"
        // number={totalPendingAppointment}
        text="YTD"
        number={748}
      />
      <OverviewCard
        title="Total Users"
        number={"2, 221"}
        // number={totalAppointment}
        text="YTD"
      />
    </>
  );
};

const TopRightDashboard = ({ data }) => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Top Earning Doctors"
      menu={[
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              This Week <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "Last Week": () => console.log(""),
            },
            {
              "Last 2 weeks": () => console.log(""),
            },
            {
              "Custom Date": () => console.log(""),
            },
          ]}
        />,
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              Top 5 <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "Top 10": () => console.log(""),
            },
            {
              "Top 15": () => console.log(""),
            },
          ]}
        />,
      ]}
      graph={<HighestPayingCategory dataFigs={data} />}
      menuBtnStyle={classes.menuBtn2}
    />
  );
};

const MiddleLeftDashboard = () => {
  return <GraphCard title="User Requests" graph={<UserRequests />} />;
};

const MiddleRightDashboard = () => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Request Type"
      menu={[
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              This week <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "This Month": () => console.log(""),
            },
            {
              "This Year": () => console.log(""),
            },
            {
              "Custom Date": () => console.log(""),
            },
          ]}
        />,
      ]}
      graph={<TopRequests />}
      menuBtnStyle={classes.menuBtn2}
    />
  );
};

const WellnessDoctorDashBoard = () => {
  const classes = containerStyle();
  const dispatch = useDispatch();
  const dashboarddata = useSelector(getDashboardData);
  const dashboard = dashboarddata.dashboardData;

  useEffect(() => {
    const fetchDashboard = async () => {
      await dispatch(fetchWellnessOnTheGoDashboardData());
    };
    fetchDashboard();
  }, [dispatch]);

  return (
    <div className={classes.main}>
      <DashboardLayout
        topLeft={
          <TopLeftDashboard
            totalPendingAppointment={dashboard?.total_pending_appointments}
            totalUsers={dashboard?.total_users}
            totalAppointment={dashboard?.total_appointments}
          />
        }
        topRight={
          <TopRightDashboard
            data={dashboard?.highest_paid_appointments?.map(
              (d) => d?.total_amount_paid
            )}
          />
        }
        middleLeft={<MiddleLeftDashboard />}
        middleRight={<MiddleRightDashboard />}
      />
    </div>
  );
};

export default WellnessDoctorDashBoard;
