import { makeStyles } from "@material-ui/core/styles";

export const TableStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(1, 3),
    color: "#666666",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  addBtn: {
    width: "172px",
    height: "48px",
    background: "#E6072C",
    borderRadius: "5px",
    padding: "12px 40px",
    color: "#fff",
    cursor: "pointer",
  },
  addBtnlg: {
    width: "200px",
    height: "48px",
    background: "#E6072C",
    borderRadius: "5px",
    padding: "12px 20",
    color: "#fff",
    cursor: "pointer",
  },
  tableRoot: {
    display: "flex",
    flexGrow: 1,
  },
  tabPanelContainer: {
    width: "100%",
  },
  searchBar: {
    backgroundColor: "transparent",
    border: "1px solid #CCCCCC",
    borderRadius: "5px",
    marginRight: 5,
    marginTop: 8,
    padding: "5px 14px",
    outline: "none",
    color: "#999999",
  },
  searchInput: {
    backgroundColor: "transparent",
    marginLeft: 10,
    outline: "none",
  },
  tableBody: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
  },
  tableHead: {
    backgroundColor: "#F5E9EB",
    color: "#E6072C",
    width: "100%",
  },
  tablePaginate: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  statusIconActive: {
    backgroundColor: "#25B273",
    height: 8,
    width: 8,
    borderRadius: "50%",
    marginRight: "8px",
  },
  statusIconInactive: {
    backgroundColor: "#C4C4C4",
    height: 8,
    width: 8,
    borderRadius: "50%",
    marginRight: "8px",
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: "#251A6B",
          padding: 0,
        }
      : {
          color: theme.palette.text.primary,
        },
  title: {
    color: theme.palette.text.primary,
  },
  toolLabel: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
  },
  check: {
    padding: 0,
  },
  spanBorder: {
    border: "1px solid #E5E5E5",
    padding: "5px 10px",
    borderRadius: "4px 0px 0px 4px",
    display: "flex",
    alignItems: "center",
    margin: 0,
  },
}));
