import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40%",
    margin: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    borderRadius: "8px",
  },
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ActionModal({ open, close, content, modalTitle }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.modalTop}>
            <div
              style={{
                color: "#5A5454",
              }}
            >
              {modalTitle}
            </div>
            <CloseSharpIcon
              color="disabled"
              onClick={close}
              className={classes.closeIcon}
            />
          </div>
          <div className={classes.paper}>
            <p>{content}</p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
