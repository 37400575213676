import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useSelector } from "react-redux";
import { getEnrollees } from "../../../store/enrollee";
import { getFormatedDate } from "../../../utils/constants";
import AppMenu from "../../menu";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    "& th": {
      color: "#E6072C",
      whiteSpace: "nowrap",
    },
  },

  pending: {
    color: "#FFA800",
    backgroundColor: "#FDF7EB",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
  approved: {
    color: "#25B273",
    backgroundColor: "#E3F9F1",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
  inactive: {
    color: "#8B8888",
    backgroundColor: "rgba(139, 136, 136, 0.1)",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
  declined: {
    color: "#8B8888",
    backgroundColor: "rgba(139, 136, 136, 0.1)",
    borderRadius: "10px",
    padding: "5px 15px",
    textTransform: "capitalize",
  },
});

const AuthorizationCode = ({ onShowAuthDetails }) => {
  const classes = useStyles();
  const enrollees = useSelector(getEnrollees);
  const authCodeRequestHistory = enrollees.data
    ? enrollees?.data?.auth_code_requests_history
    : [];

  return authCodeRequestHistory?.length === 0 ? (
    <div className="text-center font-bold">No Authorization Code History</div>
  ) : (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell>Request Date</TableCell>
          <TableCell align="left">Request</TableCell>
          <TableCell align="left">Authorization Code</TableCell>
          <TableCell align="left">Request Status</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {authCodeRequestHistory?.map((row) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {getFormatedDate(row.created_at)}
            </TableCell>
            <TableCell align="left">{row.request_message}</TableCell>
            <TableCell align="left">{row.request_code || "Nill"}</TableCell>
            <TableCell align="left">
              <span className={classes[row.status]}>{row.status}</span>
            </TableCell>
            <TableCell align="left">
              <AppMenu
                menu_name_or_icon={
                  <button>
                    <FiMoreHorizontal size="1.3em" color="#A8A8A8" />
                  </button>
                }
                item_names={[
                  {
                    "View Details": () =>
                      onShowAuthDetails(true, row.id, "authorization-code"),
                  },
                ]}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AuthorizationCode;
