import React, { useEffect } from "react";
import { containerStyle } from "../../styles/styles";
import { graphCardStyles } from "../../styles/graphcardstyle";
import GraphCard from "../../uis/graphscard";
import OverviewCard from "../../uis/graphscard/overviewCard";
import AppMenu from "../../menu";
import TopEnroleeChart from "../../graphs/barchart";
import HospitalsChart from "../../graphs/piechart";
import PlanTypeChart from "../../graphs/linechart";
import CardRequestChart from "../../graphs/cardrequest";
import NewEnroleeTable from "../../uis/enroleesTable/newEnroleeTable";
import DashboardLayout from "../../uis/dashboardLayout";
// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { fetchDashboardData, getDashboardData } from "../../../store/dashboard";
import { useDispatch, useSelector } from "react-redux";

const TopLeftDashboard = ({
  totalRequest,
  totalEnrollees,
  totalAppointment,
}) => {
  return (
    <>
      <OverviewCard title="Total Requests" number={totalRequest} text="YTD" />
      <OverviewCard
        title="Total Enrollees"
        number={totalEnrollees}
        text="YTD"
      />
      <OverviewCard
        title="Total Appointments"
        number={totalAppointment}
        text="YTD"
      />
    </>
  );
};

const TopRightDashboard = ({ data }) => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Plan Type"
      graph={<PlanTypeChart />}
      menuBtnStyle={classes.menuBtn1}
    />
  );
};

const MiddleLeftDashboard = () => {
  return <GraphCard title="Enrollee Requests" graph={<NewEnroleeTable />} />;
};

const MiddleRightDashboard = () => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Card Requests"
      menu={[
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              This week <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "This Month": () => console.log(""),
            },
            {
              "This Year": () => console.log(""),
            },
            {
              "Custom Date": () => console.log(""),
            },
          ]}
        />,
      ]}
      graph={<CardRequestChart />}
      menuBtnStyle={classes.menuBtn2}
    />
  );
};

const BottomLeftDashboard = () => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Enrollee Status"
      menu={[
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              This Week <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "Last week": () => console.log(""),
            },
            {
              "Last two weeks": () => console.log(""),
            },
          ]}
        />,
      ]}
      graph={<HospitalsChart />}
      menuBtnStyle={classes.menuBtn2}
    />
  );
};

const BottomRightDashboard = () => {
  const classes = graphCardStyles();
  return (
    <GraphCard
      title="Highest Paying Enrollees"
      menu={[
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              This Week <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "Last Week": () => console.log(""),
            },
            {
              "Last 2 weeks": () => console.log(""),
            },
            {
              "Custom Date": () => console.log(""),
            },
          ]}
        />,
        <AppMenu
          menu_name_or_icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              Top 5 <ExpandMoreIcon />
            </div>
          }
          item_names={[
            {
              "Top 10": () => console.log(""),
            },
            {
              "Top 15": () => console.log(""),
            },
          ]}
        />,
      ]}
      graph={<TopEnroleeChart />}
      menuBtnStyle={classes.menuBtn2}
    />
  );
};

const DashBoard = () => {
  const classes = containerStyle();
  const dispatch = useDispatch();
  const dashboarddata = useSelector(getDashboardData);
  const dashboard = dashboarddata.dashboardData;

  useEffect(() => {
    const fetchDashboard = async () => {
      await dispatch(fetchDashboardData());
    };
    fetchDashboard();
  }, [dispatch]);

  return (
    <div className={classes.main}>
      <DashboardLayout
        topLeft={
          <TopLeftDashboard
            totalRequest={dashboard.total_requests}
            totalEnrollees={dashboard.total_enrollees}
            totalAppointment={dashboard.total_appointments}
          />
        }
        topRight={<TopRightDashboard />}
        middleLeft={<MiddleLeftDashboard />}
        middleRight={<MiddleRightDashboard />}
        bottomLeft={<BottomLeftDashboard />}
        bottomRight={<BottomRightDashboard />}
      />
    </div>
  );
};

export default DashBoard;
