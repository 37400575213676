import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import styles from "./index.module.css";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Box from "@material-ui/core/Box";
import { createService } from "../../../../store/services";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "330px ",
  height: "300px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  border: "1px solid #fcf9fa",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1);",
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
    "&:hover": {
      background: "#e6072c",
    },
    marginBottom: 15,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function ServiceModal({ open, close, title, btnText }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (!open) return null;

  const doSubmit = async (values) => {
    await dispatch(createService(values));
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // proper error handling here
    if (serviceName.trim() === "") {
      setErrorMessage("Please enter a value");
      return;
    }

    let name = serviceName;
    doSubmit({ name });
  };

  return (
    <div className={classes.modal}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={classes.modalTop}>
              <div
                style={{
                  color: "#5A5454",
                }}
              ></div>
              <CloseSharpIcon
                color="disabled"
                onClick={close}
                className={classes.closeIcon}
              />
            </div>
            <div className={styles["title"]}>
              <h1>{title}</h1>
            </div>
            <form className={styles["form"]} onSubmit={handleSubmit}>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Service Name</label>
                <input
                  onChange={({ target }) => setServiceName(target.value)}
                  value={serviceName}
                  className={styles["name-input"]}
                  type="text"
                  placeholder="Dental Care"
                />
              </div>
              <div>
                <button type="submit" className="button-lg">
                  {btnText}
                </button>
              </div>
              {errorMessage && (
                <div className="mt-5 text-red-dark">{errorMessage}</div>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
