import React from "react";
import SideBar from "../components/sidebar-navbar";
import { containerStyle } from "../components/styles/styles";
import DashboardRoutes from "./dashboardRoute";
import { SideBarLinks } from "../components/sidebar-navbar";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import NoteAddIcon from "@material-ui/icons/NoteAdd";

const sideBarOptionsContent = [
  {
    name: "Dashboard",
    url: "/hmo/dashboard",
    icon: <DashboardIcon />,
  },
  {
    name: "Enrollees",
    url: "/hmo/dashboard/enrollees",
    icon: <GroupAddIcon />,
  },
  {
    name: "Card Requests",
    url: "/hmo/dashboard/card-request",
    icon: <CreditCardIcon />,
  },
  {
    name: "Authorization Codes",
    url: "/hmo/dashboard/authorization-code",
    icon: <NoteAddIcon />,
  },
  {
    name: "Drug Refill",
    url: "/hmo/dashboard/drug-refill",
    icon: <InboxIcon />,
  },
  {
    name: "Appointments",
    url: "/hmo/dashboard/appointment",
    icon: <EventAvailableIcon />,
  },
  {
    name: "Switch Category",
    url: "/home",
    icon: <img src="/assets/switch-icon.png" alt="icon" />,
  },
  // {
  //   name: "Hospitals",
  //   url: "/hmo/dashboard/hospitals",
  //   icon: <EventAvailableIcon />,
  // },
];

const Dashboard = () => {
  const classes = containerStyle();
  return (
    <div className={classes.root}>
      <SideBar
        iconButton
        drawerSection
        noFullAppBar
        logoOnClose
        appBarTitle="Dashboard"
        settings
        settings_url="/hmo/dashboard/settings"
        sideBarOptions={sideBarOptionsContent.map((item, index) => {
          return (
            <SideBarLinks
              key={index}
              name={item.name}
              icon={item.icon}
              url={item.url}
            />
          );
        })}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <DashboardRoutes />
      </main>
    </div>
  );
};

export default Dashboard;
