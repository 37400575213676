import React, { useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import styles from "./index.module.css";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp";
import KeyboardArrowUpSharp from "@material-ui/icons/KeyboardArrowUpSharp";
import AddCircle from "@material-ui/icons/AddCircle";
import RemoveCircleRounded from "@material-ui/icons/RemoveCircleRounded";

import Box from "@material-ui/core/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "330px ",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "8px",
  border: "1px solid #fcf9fa",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1);",
  p: 3,
};

const useStyles = makeStyles((theme) => ({
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
    "&:hover": {
      background: "#e6072c",
    },
    marginBottom: 15,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function BenefitModal({ open, close, title, btnText }) {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [valuePoint, setValuePoint] = useState(false);
  const [value, setValue] = useState({
    input1: "",
    point1: "",
    input2: "",
    point2: "",
  });
  const [benefitName, setBenefitName] = useState("");
  const [addMoreBenefits, setAddMoreBenefits] = useState([]);

  const handleChange = (evt) => {
    const inputedValue = evt.target.value;
    setValue({
      ...value,
      [evt.target.name]: inputedValue,
    });
  };

  const handleAddMoreBenefits = () => {
    setAddMoreBenefits([...addMoreBenefits, { id: uuidv4() }]);
  };

  if (!open) return null;

  return (
    <div className={classes.modal}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={classes.modalTop}>
              <div
                style={{
                  color: "#5A5454",
                }}
              ></div>
              <CloseSharpIcon
                color="disabled"
                onClick={close}
                className={classes.closeIcon}
              />
            </div>
            <div className={styles["title"]}>
              <h1>{title}</h1>
            </div>
            <form className={styles["form"]}>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Benefit Name</label>
                <input
                  onChange={({ target }) => setBenefitName(target.value)}
                  value={benefitName}
                  className={styles["name-input"]}
                  type="text"
                  placeholder="Antenatal"
                />
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Status</label>
                <select>
                  <option value="guard1">Single</option>
                  <option value="guard2">Married</option>
                </select>
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Gender</label>
                <select>
                  <option value="guard1">Female </option>
                  <option value="guard2">Male</option>
                </select>
              </div>
              <>
                <div className={styles["form-control-row"]}>
                  <label htmlFor="text">*Value</label>
                  <input
                    value={count || value.input1}
                    onChange={handleChange}
                    className={styles["value-input1"]}
                    type="number"
                    name="input1"
                    placeholder="No"
                  />
                </div>
                <div className={styles["form-control-row-2"]}>
                  <label htmlFor="text">Point</label>
                  <input
                    className={styles["value-input2"]}
                    type="number"
                    value={value.point1}
                    name="point1"
                    onChange={handleChange}
                    placeholder={count}
                  />
                  <div className={styles["input-up-down"]}>
                    <button
                      id="input1-down"
                      type="button"
                      className={styles["btn1"]}
                      onClick={() => setCount(count + 1)}
                    >
                      <KeyboardArrowUpSharp fontSize="small" />
                    </button>
                    <button
                      name="newClick"
                      value="newClick1"
                      id="123"
                      type="button"
                      disabled={count === 0}
                      className={styles["btn2"]}
                      onClick={() => {
                        setCount(count - 1);
                      }}
                    >
                      <KeyboardArrowDownSharp fontSize="small" />
                    </button>
                  </div>
                </div>
              </>
              {addMoreBenefits.map((doc) => {
                return (
                  <div key={doc.id}>
                    <div className={styles["form-control-row"]}>
                      <label htmlFor="text">*Value</label>
                      <input
                        value={count1 || value.input2}
                        onChange={handleChange}
                        className={styles["value-input1"]}
                        type="number"
                        id=""
                        name="input2"
                        placeholder="No"
                      />
                    </div>
                    {/* <RemoveCircleRounded
                      fontSize="small"
                      className={styles["form-control-row-1"]}
                    /> */}
                    <div className={styles["form-control-row-2"]}>
                      <label htmlFor="text"> Point</label>
                      <input
                        className={styles["value-input2"]}
                        type="number"
                        value={value.point2}
                        name="point2"
                        onChange={handleChange}
                        placeholder={count1}
                      />
                      <div className={styles["input-up-down"]}>
                        <button
                          id="1"
                          type="button"
                          className={styles["btn1"]}
                          onClick={() => {
                            setCount1(count1 + 1);
                          }}
                        >
                          <KeyboardArrowUpSharp fontSize="small" />
                        </button>
                        <button
                          type="button"
                          name="newClick"
                          value="newClick1"
                          id="123"
                          disabled={count1 === 0}
                          className={styles["btn2"]}
                          onClick={() => {
                            setCount1(count1 - 1);
                          }}
                        >
                          <KeyboardArrowDownSharp fontSize="small" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className={styles["btn3"]}
                onClick={() => handleAddMoreBenefits()}
              >
                <AddCircle fontSize="small" className={styles["addCircle"]} />{" "}
                Add Value
              </button>
              <div>
                <button type="submit" className={styles["btn"]}>
                  {btnText}
                </button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
