import { Doughnut } from "react-chartjs-2";
import { getDashboardData } from "../../store/dashboard";
import { useSelector } from "react-redux";

// doughnut chart
const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
    },
    labels: {
      render: "percentage",
      precision: 2,
    },
  },
};

const HospitalsChart = () => {
  const dashboarddata = useSelector(getDashboardData);
  const dashboard = dashboarddata?.dashboardData?.enrollee_status;
  return (
    <Doughnut
      data={{
        labels: ["active_enrollees", "declined_enrollees", "non_enrollees", "pending_enrollees"],
        datasets: [
          {
            label: "My First Dataset",
            data: [
              dashboard?.active_enrollees.total,
              dashboard?.declined_enrollees.total,
              dashboard?.non_enrollees.total,
              dashboard?.pending_enrollees.total,
            ],
            backgroundColor: ["#ACC9F7", "#46B3E6", "#76DEE4", "#4D80E4"],
            hoverOffset: 14,
            borderWidth: 4,
          },
        ],
      }}
      options={options}
      width="100%"
      margin="auto"
    />
  );
};

export default HospitalsChart;
