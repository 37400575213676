import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import styles from "./index.module.css";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Loader from "../../../common/Loader";
import Box from "@material-ui/core/Box";
import {
  createHospital,
  getHospital,
  fetchHospitalLevel,
  editHospital,
} from "../../../../store/hospital";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "330px ",
  height: "560px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  border: "1px solid #fcf9fa",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1);",
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: "rgba(255, 255, 255, 0.5)",
    height: 22,
    width: 22,
    padding: 3,
    backgroundColor: "#CCCCCC",
    borderRadius: "50%",
    "&:hover": {
      background: "#e6072c",
    },
    marginBottom: 15,
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  return (
    <div ref={ref} {...other}>
      {children}
    </div>
  );
});

Fade.propTypes = {
  children: PropTypes.any,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function HospitalModal({
  open,
  close,
  title,
  btnText,
  editId,
  editName,
  editLocation,
  editAddress,
  editItem,
}) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [getLocation, setGetLocation] = useState("");
  const [address, setAddress] = useState("");
  const [plan, setPlan] = useState();
  const [editingName, setEditingName] = useState(editName);
  const [editingLocation, setEditingLocation] = useState(editLocation);
  const [editingAddress, setEditingAddress] = useState(editAddress);
  const [editingItem, setEditingItem] = useState(editItem);
  const dispatch = useDispatch();
  const { loading } = useSelector(getHospital);
  const hospitalData = useSelector(getHospital);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchHospitalLevels = async () => {
      await dispatch(fetchHospitalLevel());
    };
    fetchHospitalLevels();
  }, [dispatch]);

  if (!open) return null;

  const planType = hospitalData.hospitalLevelList.map((doc) => {
    return doc.name;
  });

  const doSubmit = async (values) => {
    await dispatch(createHospital(values));
    window.location.reload();
  };

  const editDoSubmit = async (values) => {
    await dispatch(editHospital(values));
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let level = hospitalData.hospitalLevelList.find((doc) => {
      return doc.name === plan;
    });
    level = level.level;
    // proper error handling here
    if (
      name.trim() === "" ||
      getLocation.trim() === "" ||
      address.trim() === ""
    ) {
      setErrorMessage("Please enter a value");
      return;
    }
    let location = getLocation.toUpperCase();

    doSubmit({ name, address, location, plan, level });
  };

  const editSubmit = (e) => {
    e.preventDefault();
    let level = hospitalData.hospitalLevelList.find((doc) => {
      return doc.name === editingItem;
    });
    level = level.level;
    // proper error handling here
    if (
      editingName.trim() === "" ||
      editingLocation.trim() === "" ||
      editingAddress.trim() === ""
    ) {
      setErrorMessage("Please enter a value");
      return;
    }
    editDoSubmit({
      editId,
      editingName,
      editingAddress,
      editingLocation,
      editingItem,
      level,
    });
  };

  return (
    <div className={classes.modal}>
      <Loader visible={loading} />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={classes.modalTop}>
              <div
                style={{
                  color: "#5A5454",
                }}
              ></div>
              <CloseSharpIcon
                color="disabled"
                onClick={close}
                className={classes.closeIcon}
              />
            </div>
            <div className={styles["title"]}>
              <h1>{title}</h1>
            </div>
            <form
              className={styles["form"]}
              onSubmit={
                title === "Add New Hospital" ? handleSubmit : editSubmit
              }
            >
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Hospital Name</label>
                {title === "Add New Hospital" && (
                  <input
                    onChange={({ target }) => setName(target.value)}
                    value={name}
                    type="text"
                    placeholder="Gbagada General Hospital"
                  />
                )}
                {editName && (
                  <input
                    onChange={({ target }) => setEditingName(target.value)}
                    value={editingName}
                    type="text"
                    placeholder="Gbagada General Hospital"
                  />
                )}
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Location</label>
                {title === "Add New Hospital" && (
                  <input
                    onChange={({ target }) => setGetLocation(target.value)}
                    value={getLocation}
                    type="text"
                    placeholder="Lagos"
                  />
                )}
                {editLocation && (
                  <input
                    onChange={({ target }) => setEditingLocation(target.value)}
                    value={editingLocation}
                    type="text"
                    placeholder="Lagos"
                  />
                )}
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Address Name</label>
                {title === "Add New Hospital" && (
                  <input
                    onChange={({ target }) => setAddress(target.value)}
                    value={address}
                    type="text"
                    placeholder="10, Charleyboy Road"
                  />
                )}
                {editAddress && (
                  <input
                    onChange={({ target }) => setEditingAddress(target.value)}
                    value={editingAddress}
                    type="text"
                    placeholder="10, Charleyboy Road"
                  />
                )}
              </div>
              <div className={styles["form-control"]}>
                <label htmlFor="text">*Plan Type</label>
                {title === "Add New Hospital" && (
                  <select
                    onChange={({ target }) => setPlan(target.value)}
                    value={plan}
                  >
                    {planType.map((doc) => (
                      <option key={doc}>{doc}</option>
                    ))}
                  </select>
                )}
                {editItem && (
                  <select
                    onChange={({ target }) => setEditingItem(target.value)}
                    value={editingItem}
                  >
                    {planType.map((doc) => (
                      <option key={doc}>{doc}</option>
                    ))}
                  </select>
                )}
              </div>
              <div>
                <button type="submit" className="button-lg">
                  {btnText}
                </button>
              </div>
              {errorMessage && (
                <div className="mt-5 text-red-dark">{errorMessage}</div>
              )}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
