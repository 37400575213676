import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getEnrollees } from "../../../store/enrollee";
import styles from "./index.module.css";

const DoctorDetails = () => {
  const enrollees = useSelector(getEnrollees);
  const enrollee = enrollees.data.enrollee_details
    ? enrollees.data.enrollee_details
    : {};
  const [showMore, setShowMore] = useState(false);
  const benefits = showMore
    ? enrollee.benefits
    : enrollee.benefits
    ? enrollee.benefits.slice(0, 6)
    : [];

  console.log(enrollees);
  return (
    <>
      <table className={styles.table}>
        <thead>
          <h3 className={styles["title"]}>Medical Profiling</h3>
          <div className={styles["underline"]} />
        </thead>

        <thead>
          <tr>
            <th>Name of University</th>
            <th>Year of Graduation</th>
            <th>Cerificate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name of University</td>
            <td>2021</td>
            <td>University.certification.pdf</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>University.certification.Doc</td>
          </tr>
        </tbody>
        <div style={{ marginTop: "3rem" }} />
        <thead>
          <h3 className={styles["title"]}> Medical License</h3>
          <div className={styles["underline"]} />
        </thead>

        <thead>
          <tr>
            <th>Year of Collection</th>
            <th>License 1</th>
            <th>License 2</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2018</td>
            <td>University.certification.pdf</td>
            <td>Professional.license.pdf</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DoctorDetails;
