import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Paper from "../../common/Paper";
import TabPanel from "../../common/TabPanel";
import Title from "../../common/Typography/title";
import { BiArrowBack } from "react-icons/bi";
import Tabs from "../../common/Tabs";
import InfoPanel from "../../common/Panel";

import EnrolleeDetails from "../widget/enrolleeDetails";
import AuthorizationCode from "../widget/authorizationCode";
import EnrolleeDependentDetails from "../widget/enrolleeDependentDetails";
import CardRequest from "../widget/cardRequest";
import DrugRefill from "../widget/drugRefill";
import Appointments from "../widget/appointments";

import CardRequestDetails from "../components/cardRequestDetails";
import AppointmentsDetails from "../components/appointmentsDetails";
import AuthorizationCodeDetails from "../components/authorizationCode";
import DrugRefillRequestDetails from "../components/drugRefillDetails";

import { fetchEnrollee, getEnrollees } from "../../../store/enrollee";
import Loader from "../../common/Loader";
import styles from "./index.module.css";

const tabData = [
  "Enrollee Details",
  "Card Request",
  "Authorization Code",
  "Drug Refill",
  // "Dependents",
  "Appointments",
];

const ViewEnrollee = () => {
  const [currentEnrollee, setCurrentEnrollee] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState("Enrollee Details");
  const [DetailsComponent, setDetailsComponent] = useState(null);
  const [showRequestPanel, setShowRequestPanel] = useState(false);
  const [detailId, setDetailId] = useState(null);
  const [currentDetail, setCurrentDetail] = useState({});

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const enrollees = useSelector(getEnrollees);

  const updateTab = (index, component = null, data) => {
    setTabIndex(index);
    setCurrentTab(tabData[index]);
    setDetailsComponent(component);
    setCurrentDetail(data);
  };

  const getHistoryData = useCallback(
    (field) => {
      return (
        enrollees?.data &&
        enrollees?.data[field]?.find((history) => history.id === detailId)
      );
    },
    [(detailId, enrollees)]
  );

  useEffect(() => {
    const getEnrollee = async () => {
      await dispatch(fetchEnrollee(params.enrollee_id));
    };
    getEnrollee();
  }, [dispatch, params]);

  useEffect(() => {
    if (!enrollees.data) history.push("/hmo/dashboard/enrollees/approved");
    setCurrentEnrollee(enrollees.data);
  }, [params, history, dispatch, enrollees]);

  useEffect(() => {
    switch (params.tab) {
      case "enrollee-details":
        updateTab(0, null, {});
        break;
      case "card-request":
        const currentCardRequest = getHistoryData("card_requests_history");
        updateTab(
          1,
          <CardRequestDetails details={currentCardRequest} />,
          currentCardRequest
        );
        break;
      case "authorization-code":
        const currentAuthDetails = getHistoryData("auth_code_requests_history");
        updateTab(
          2,
          <AuthorizationCodeDetails details={currentAuthDetails} />,
          currentAuthDetails
        );
        break;
      case "drug-refill":
        const currentDrugRefill = getHistoryData(
          "drug_refill_requests_history"
        );
        updateTab(
          3,
          <DrugRefillRequestDetails details={currentDrugRefill} />,
          currentDrugRefill
        );
        break;
      case "appointments":
        const currentAppointment = getHistoryData(
          "appointment_requests_history"
        );
        updateTab(
          4,
          <AppointmentsDetails details={currentAppointment} />,
          currentAppointment
        );
        break;
      case "dependents":
        const dependentDetails = getHistoryData("enrollee_details");
        updateTab(
          5,
          <AuthorizationCode details={dependentDetails} />,
          dependentDetails
        );
        break;
      default:
        updateTab(0, null, {});
        break;
    }
  }, [params, currentEnrollee, detailId, getHistoryData]);

  useEffect(() => {
    if (currentDetail && currentDetail.status === "pending") {
      history.push({
        pathname: `/hmo/dashboard/enrollee/${params?.tab}/accept/${params.enrollee_id}`,
        state: { detailId },
      });
    }
  }, [currentDetail, history, params, detailId]);

  const handleShowDetails = (status, id, url) => {
    setShowRequestPanel(status);
    setDetailId(id);
    history.push({
      pathname: `/hmo/dashboard/enrollees/view/${url}/${params.enrollee_id}`,
      state: { detailId: id },
    });
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.container}>
      <Loader visible={enrollees.loading} />
      <div className="flex items-center  justify-between mb-5">
        <div className="flex items-center">
          <button className="btn-round-small" onClick={() => history.goBack()}>
            <BiArrowBack size="1.2em" />
          </button>
          <Title style={{ fontSize: "16px" }}>Back</Title>
        </div>
        <div></div>
      </div>

      {showRequestPanel && DetailsComponent}
      <InfoPanel />

      <div style={{ margin: "2rem 0" }}>
        <Paper>
          <Tabs
            activeTab={currentTab}
            tabData={tabData}
            tabIndex={tabIndex}
            onTabChange={(index) => setTabIndex(index)}
          />

          <div className={styles["tab-panel-body"]}>
            <TabPanel value={0} index={tabIndex}>
              {/* <EnrolleeDetails /> */}
              <EnrolleeDetails />
            </TabPanel>
            <TabPanel value={1} index={tabIndex}>
              <CardRequest onShowCardDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={2} index={tabIndex}>
              <AuthorizationCode onShowAuthDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={3} index={tabIndex}>
              <DrugRefill onShowDrugRefillDetails={handleShowDetails} />
            </TabPanel>
            <TabPanel value={4} index={tabIndex}>
            {/* <EnrolleeDependentDetails /> */}
            </TabPanel>
            <TabPanel value={5} index={tabIndex}>
            <Appointments onShowAppointmentDetails={handleShowDetails} />
              
            </TabPanel>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ViewEnrollee;
