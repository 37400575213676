import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "../components/pages/Login";
import Dashboard from "./dashboard";
import store from "../store";
import { Provider } from "react-redux";
import NotFound from "../components/NotFound";
import Home from "../components/pages/home";
import ResetPassword from "../components/pages/resetPassword";
import ResetCode from "../components/pages/resetPassword/getResetCode";
import NewPassword from "../components/pages/resetPassword/newPassword";
import AdminPrivateRoute from "../components/AdminPrivateRoute";
import WellnessOnTheGoDashboard from "./wellnessOnTheGoDashboard";
import WellnessDoctorDashboard from "./wellnessDoctorDashboard";

const Routes = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <AdminPrivateRoute exact path="/home" component={Home} />
          <Route exact path="/hmo/dashboard*" component={Dashboard} />
          <Route
            exact
            path="/wellness-on-the-go*"
            component={WellnessOnTheGoDashboard}
          />
          <Route
            exact
            path="/wellness-doctor*"
            component={WellnessDoctorDashboard}
          />
          <Route exact path="/login" component={Login} />
          <Redirect exact from="/" to="/home" />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/reset-code" component={ResetCode} />
          <Route exact path="/new-password" component={NewPassword} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default Routes;
