import React, { useState, useEffect } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AppMenu from "../../menu";
import TablesData from "../globalTable";
import TableItem from "../globalTable/tableItem";
import { SearchOnly } from "../globalTable/searchandfilter";
import { getFormatedDate } from "../../../utils/constants";
import { fetchServices, getService } from "../../../store/services";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import DeleteServiceModal from "../../uis/modals/addService/deleteServiceModal";

export default function ActiveServices() {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const serviceData = useSelector(getService);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLists, setPageLists] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [deleteContent, setDeleteContent] = useState("");

  useEffect(() => {
    const fetchService = async () => {
      await dispatch(fetchServices());
    };
    fetchService();
  }, [dispatch]);

  const handleSelectNumber = ({ target }) => {
    if (target.value === "30") {
      setPageLists(30);
    } else if (target.value === "20") {
      setPageLists(20);
    } else {
      setPageLists(10);
    }
  };

  const incrementPageNumber = async () => {
    setPageNumber((pageNumber += 1));
    await dispatch(fetchServices(pageLists, pageNumber));
  };

  const decrementPageNumber = async () => {
    setPageNumber((pageNumber -= 1));
    await dispatch(fetchServices(pageLists, pageNumber));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleSearchAndFilter = serviceData.servicesList.filter((searched) => {
    if (search === " ") {
      return searched;
    } else if (
      searched.name.toLowerCase().includes(search && search.toLowerCase()) ||
      searched.created_at.toLowerCase().includes(search && search.toLowerCase())
    ) {
      return searched;
    } else {
      return null;
    }
  });

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = () => {
    const newSelecteds = serviceData.servicesList.map((n) => n.id);
    setSelected(newSelecteds);
    return;
  };

  const handleClose = () => {
    setShowModal(false);
    setDeleteContent("");
  };

  const handleDeleteHospitalModal = (id) => {
    setShowModal(true);
    setDeleteContent(
      <DeleteServiceModal
        title="You are about to delete  ‘Optical Care’ as a  Wellness Service. Would you like to proceed"
        open={showModal}
        close={handleClose}
        btnText1="Yes, Proceed"
        btnText2="No, Cancel"
        deleteId={id}
      />
    );
  };

  return (
    <>
      {deleteContent}
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
      >
        <SearchOnly handleSearch={handleChange} searchWord={search} />
      </div>
      <Loader visible={serviceData.loading} />
      <TableItem
        userType="User"
        checkBoxes
        tabHeadLabel={["Service", "Date Created"]}
        action
        tableSize
        tabToolBar
        tabHeadCheck
        numSelectedItem={selected.length}
        rowCount={serviceData.servicesList.length}
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        currentPage={serviceData.currentPage}
        toPage={serviceData.toPage}
        totalPage={serviceData.total}
        handleIncrement={() => incrementPageNumber()}
        handleDecrement={() => decrementPageNumber()}
        disabledIncrement={pageNumber >= serviceData.lastPage}
        disabledDecrement={pageNumber <= 1}
        handleSelectPageNumber={handleSelectNumber}
        pageSelectValue={pageLists}
        tabBodyData={handleSearchAndFilter.map((item, i) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${i}`;
          return (
            <TablesData
              labelId={labelId}
              handleItemClick={(event) => handleClick(event, item.id)}
              aria-checked={isItemSelected}
              isItemSelected={isItemSelected}
              key={i}
              checkBoxes
              enroleeName={item.name.toUpperCase()}
              listItem={[getFormatedDate(item.created_at, "DD-MMM-YYYY")]}
              action={
                <AppMenu
                  menu_name_or_icon={<MoreHorizIcon />}
                  item_names={[
                    {
                      "Delete Service": () =>
                        handleDeleteHospitalModal(item.id),
                    },
                  ]}
                />
              }
            />
          );
        })}
        tablePagination
      />
    </>
  );
}
