import React from "react";
import SideBar from "../components/sidebar-navbar";
import { containerStyle } from "../components/styles/styles";
import DashboardOnTheGoRoutes from "./wellnessOnTheGoRoute";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { SideBarLinks } from "../components/sidebar-navbar";

const sideBarOptionsContent = [
  {
    name: "Dashboard",
    url: "/wellness-on-the-go",
    icon: <DashboardIcon />,
  },
  {
    name: "Users",
    url: "/wellness-on-the-go/users",
    icon: <GroupAddIcon />,
  },
  {
    name: "Dental Care",
    url: "/wellness-on-the-go/dental-care",
    icon: <CreditCardIcon />,
  },
  {
    name: "Optical Care",
    url: "/wellness-on-the-go/optical-care",
    icon: <NoteAddIcon />,
  },
  {
    name: "Comprehensive",
    url: "/wellness-on-the-go/comprehensive",
    icon: <NoteAddIcon />,
  },
  {
    name: "Cancer",
    url: "/wellness-on-the-go/cancer-care",
    icon: <NoteAddIcon />,
  },
  {
    name: "Basic Health Care",
    url: "/wellness-on-the-go/basic-health-care",
    icon: <NoteAddIcon />,
  },
  {
    name: "Pre-Employement",
    url: "/wellness-on-the-go/pre-employment",
    icon: <NoteAddIcon />,
  },
  {
    name: "Switch Category",
    url: "/home",
    icon: <img src="/assets/switch-icon.png" alt="icon" />,
  },
];

const WellnessOnTheGoDashboard = () => {
  const classes = containerStyle();
  return (
    <div className={classes.root}>
      <SideBar
        iconButton
        drawerSection
        noFullAppBar
        logoOnClose
        appBarTitle="Wellness On The Go"
        // settings
        // settings_url="/hmo/dashboard/settings"
        sideBarOptions={sideBarOptionsContent.map((item, index) => {
          return (
            <SideBarLinks
              key={index}
              name={item.name}
              icon={item.icon}
              url={item.url}
            />
          );
        })}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <DashboardOnTheGoRoutes />
      </main>
    </div>
  );
};

export default WellnessOnTheGoDashboard;
