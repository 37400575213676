import React from "react";
import Avatar from "../Avatar";
import Paper from "../Paper";
import SubTitle from "../Typography/subTitle";
import Title from "../Typography/title";
import styles from "../index.module.css";
import { getUsers } from "../../../store/user";
import { useSelector } from "react-redux";
import {
  getFormatedDate,
  getIntialsFromString,
} from "../../../utils/constants";

const WellnessOnTheGoPanel = React.memo(({ user_details }) => {
  const users = useSelector(getUsers);
  const user = user_details
    ? user_details
    : users.data
    ? users?.data?.user_data
    : {};
  return (
    <Paper className={styles["paper-container"]}>
      <div className={styles.row}>
        <Avatar>
          {user?.image_color ? (
            <div
              style={{
                backgroundColor: user?.image_color,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {getIntialsFromString(
                user?.name || `${user?.first_name} ${user?.last_name}`
              )}
            </div>
          ) : (
            <img src={user?.user_photo} alt={user?.name || user?.first_name} />
          )}
        </Avatar>
        <div>
          <h2>{user?.name || `${user?.first_name} ${user?.last_name}`}</h2>
          <p>{user?.email}</p>
        </div>
      </div>
      <ul className={styles.rows}>
        <li className={styles.row}>
          <div>
            <SubTitle>Phone Number</SubTitle>
            <Title>{user?.phone_number || "Nill"}</Title>
          </div>
        </li>
        <li className={styles.row}>
          <div>
            <SubTitle>Date Created</SubTitle>
            <Title>{getFormatedDate(user?.created_at, "L")}</Title>
          </div>
        </li>
      </ul>
    </Paper>
  );
});

export default WellnessOnTheGoPanel;
