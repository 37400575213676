import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminPrivateRoute from "../components/AdminPrivateRoute";
import AcceptUserRequest from "../components/pages/acceptUser";
import NotFound from "../components/NotFound";
import WellnessOnTheGoDashBoard from "../components/pages/wellnessOnTheGoDashboard";
import Users from "../components/pages/users";
import DentalCare from "../components/pages/dentalCare";
import HealthCare from "../components/pages/healthCare";
import OpticalCare from "../components/pages/opticalCare";
import ViewUserAppointmentDetails from "../components/pages/viewUserAppointmentDetails";
import CancerCare from "../components/pages/cancerCare";
import BasicHealthCare from "../components/pages/basicHealthCare";
import EmploymentCheck from "../components/pages/employmentCheck";
import Settings from "../components/pages/settings";
import Hospitals from "../components/pages/settings/hospital";
import ServiceProvider from "../components/pages/settings/provider";
import Benefits from "../components/pages/settings/benefit";
import Services from "../components/pages/settings/services";

const DashboardOnTheGoRoutes = () => {
  const baseUrl = "/wellness-on-the-go";
  return (
    <Switch>
      <AdminPrivateRoute
        path={baseUrl}
        exact
        component={WellnessOnTheGoDashBoard}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/users/view/:tab/:user_id`}
        component={ViewUserAppointmentDetails}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/user/:requestType/accept/:id`}
        component={AcceptUserRequest}
      />

      <AdminPrivateRoute exact path={`${baseUrl}/users`} component={Users} />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/dental-care/:activetab`}
        component={DentalCare}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/optical-care/:activetab`}
        component={OpticalCare}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/comprehensive/:activetab`}
        component={HealthCare}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/cancer-care/:activetab`}
        component={CancerCare}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/basic-health-care/:activetab`}
        component={BasicHealthCare}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/pre-employment/:activetab`}
        component={EmploymentCheck}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings`}
        component={Settings}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings/benefits`}
        component={Benefits}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings/services`}
        component={Services}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings/providers`}
        component={ServiceProvider}
      />
      <AdminPrivateRoute
        exact
        path={`${baseUrl}/settings/hospitals`}
        component={Hospitals}
      />
      <Redirect
        exact
        from={`${baseUrl}/dental-care`}
        to={`${baseUrl}/dental-care/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/optical-care`}
        to={`${baseUrl}/optical-care/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/comprehensive`}
        to={`${baseUrl}/comprehensive/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/cancer-care`}
        to={`${baseUrl}/cancer-care/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/basic-health-care`}
        to={`${baseUrl}/basic-health-care/approved`}
      />
      <Redirect
        exact
        from={`${baseUrl}/pre-employment`}
        to={`${baseUrl}/pre-employment/approved`}
      />
      <Route exact path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardOnTheGoRoutes;
