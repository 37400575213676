import React from "react";
import { useSelector } from "react-redux";
import { getFormatedDate } from "../../../utils/constants.js";
import { getNonEnrollees } from "../../../store/nonEnrollee";
import styles from "./index.module.css";

const NonEnrolleeDetails = () => {
  const data = useSelector(getNonEnrollees);
  const enrollee = data.nonEnrollee;

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Enrollee Name</th>
          <th>Enrollee ID</th>
          <th>Email Address</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {enrollee.first_name} {enrollee.last_name}
          </td>
          <td>Pending</td>
          <td style={{ textTransform: "none" }}>{enrollee.email}</td>
        </tr>
      </tbody>
      <thead>
        <tr className={styles["table-row"]}>
          <th>Phone Number</th>
          <th>Plan Type</th>
          <th>Hospital</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{enrollee.phone_number}</td>
          <td>{enrollee.plan_name || "Nill"}</td>
          <td>{enrollee.hospital || "nill"}</td>
        </tr>
      </tbody>
      <thead>
        <tr className={styles["table-row"]}>
          <th>Benefits</th>
          <th>Date Created</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr className={styles["table-row"]}>
          <td className={styles["benefits-container"]}>Nill</td>
          <td>{getFormatedDate(enrollee.date_created, "L")}</td>
          <td>{enrollee.status || "pending"}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default NonEnrolleeDetails;
